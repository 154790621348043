import React from 'react'


import btnArrow from '../assests/inputbtnarrow.png'
import LOGO from '../assests/b2bjob logo 1.png'
import ProLogo from '../assests/prologo.png'
import People4 from '../assests/4people.png'
import World from '../assests/World.png'
import Screw from '../assests/screw.png'
import Alarm from '../assests/Alarm.png'
import Search from '../assests/search.png'
import IdCard from '../assests/id card.png'
import LessColourStar from '../assests/Star 10.png'
import { useNavigate } from 'react-router-dom'

const Developers = () => {
  const navigate = useNavigate();
  const NavigateToLogin = () => {
    navigate("/professionaluser");
  };
  return (
    <div className='ml-5 mt-2 bg-[#FFF]'>
      {/* <div>
        <div className="  developer-seaction" >
           <h2  ClassName="    developer-heading">Développez votre entreprise avec</h2>
        </div>

      </div> */}

      <div className="  developer-content">
        <div className='translatable-text'>
           <h3  ClassName="    developer-heading-center text-xl sm:text-3xl font-semibold">Trouver vos
            <br />
            <span className="  developer-span"> Clients </span>
          </h3>
          <div className='mt-5 flex flex-col gap-4 ml-4'>
            {/* <div className='flex gap-2 items-center'> <img src={People4} width={16} />
              <span className="  developer-listing translatable-text">4 millions de membres </span>
            </div> */}
            <div className='flex gap-2 items-center'><img src={Screw} width={16} />
              <span className="  developer-listing translatable-text">Des milliers de demandes chaque jour </span></div>

            <div className='flex gap-2 items-center'> <img src={World} width={16} />
              <span className="  developer-listing translatable-text">Partout en France </span></div>
            <div className='flex gap-2 items-center'><img src={Alarm} width={16} />
              <span className="  developer-listing translatable-text">Connexion instantanée </span></div>


          </div>






        </div>
        <div className="  hidden md:flex  flex-col justify-items-center items-center  gap-y-2 ">
        <div className='translatable-text'>
           <h2  ClassName="    developer-heading ">Développez votre entreprise avec</h2>
           </div>
          <div className="  developer-logo">
            <img src={LOGO} width={180} />
          </div>

          <button onClick={NavigateToLogin} className="  h-10 bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center"> Se connecter
            <img className="  inputbtn-Arrow" src={btnArrow} width={17} />
          </button>
        </div>
        <div>
          <div className="translatable-text">
           <h3  ClassName="    developer-heading-center text-xl sm:text-3xl font-semibold ">Développer
            <br />
            <span className="translatable-text  developer-span">Votre visibilité</span>
          </h3></div>
          <div className='mt-5 flex flex-col gap-4 ml-10'>
            <div className='flex gap-2 items-center'>
              <img src={Search} width={16} />
              <span className="translatable-text  developer-listing">Référencez votre profil sur les moteurs de  recherche </span>

            </div>
            <div className='flex gap-2 items-center'>
              <img src={LessColourStar} width={16} />
              <span className=" translatable-text developer-listing">Collecte de vos avis clients </span>
            </div>
            <div className='flex gap-2 items-center'>
              <img src={IdCard} width={16} />
              <span className=" translatable-text developer-listing">Cartes de visite et flyers personnalisés </span>
            </div>






          </div>

        </div>



      </div>

      <div className="  flex mt-10 md:hidden flex-col items-center  gap-y-2 ">
<div className='translatable-text'>
         <h2  ClassName="    developer-heading">Développez votre entreprise avec</h2>   </div>
        <div className="  developer-logo">
          <img src={LOGO} width={180} />
        </div>

        <button onClick={NavigateToLogin} className="  h-10 bg-gradient-to-b from-lime-500 to-lime-800 text-white py-2 px-4 rounded-md flex items-center translatable-text">
        Se connecter
          <img className=" translatable-text inputbtn-Arrow" src={btnArrow} width={17} />
        </button>
      </div>


    </div>
  )
}

export default Developers
