import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./subscription.css";
import api from '../services/api';

const subscriptionData = [
    {
        title: 'Professionnel',
        price: '20',
        period: '/Mois',
        features: [
            'Déposer ses annonces',
            'Déposer ses annonces location/vente',
            'Consulter les annonces',
            'Déposer son CV dans notre rubrique emplois',
            'Rechercher un stagiaire/ apprentis/salarié dans',
            'Faire sa publicité avec notre rubrique « Les pros ',
            'Faire sa communication avec notre carte de visite et notre flyer gratuits',
            'Apprendre aux autres pros son activité avec notre',
            "fil d'actualité",
            'Échanger via notre messagerie',
            'Se renseigner et poser des questions avec notre',
            'assistant IA JOBBY',
        ],
        buttonText: 'Souscrire à mon forfait',
    },
    {
        title: 'prime professionnelle',
        price: '220',
        period: '/TTC/annuelle (un mois offert)',
        features: [
            'Déposer ses annonces',
            'Déposer ses annonces location/vente',
            'Consulter les annonces',
            'Déposer son CV dans notre rubrique emplois',
            'Rechercher un stagiaire/ apprentis/salarié dans',
            'Faire sa publicité avec notre rubrique « Les pros ',
            'Faire sa communication avec notre carte de visite et notre flyer gratuits',
            'Apprendre aux autres pros son activité avec notre',
            "fil d'actualité",
            'Échanger via notre messagerie',
            'Se renseigner et poser des questions avec notre',
            'assistant IA JOBBY',
        ],
        buttonText: 'Souscrire à mon forfait',
    },
];

const SubscriptionCard = ({ title, price, period, features, buttonText, userId, isProfessional, isSubscribed }) => {
    const handleButtonClick = () => {
        if (isSubscribed) {
            const unsubscribeUrl = `https://btobjob.com/unsub.php?userId=${userId}`;
            window.location.href = unsubscribeUrl;
        } else {
            const subscribeUrl = `https://btobjob.com/payment.php?title=${encodeURIComponent(title)}&price=${encodeURIComponent(price)}&userId=${userId}&professional=${isProfessional}`;
            window.location.href = subscribeUrl;
        }
    };

    
    return (
        <div className="sm:w-[350px] w-full mx-auto bg-white shadow-md rounded-lg p-6 py-7 mb-6 relative">
            {isSubscribed && <h2 className="text-2xl font-semibold text-center text-red-500">Votre abonnement</h2>}
            <h2 className="text-xl font-semibold">{title}</h2>
            <div className="my-4">
                <span className="text-4xl font-bold">{price}€</span>
                <span className="text-lg text-gray-500">{period}</span>
                <span className="text-sm text-gray-500 ms-3">TTC</span>
            </div>
            <p className="text-gray-600">Accès illimité pour :</p>
            <ul className="mt-4 mb-7 space-y-2 text-gray-700">
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
            <button
                className={`sm:w-[250px] w-4/5 py-2 px-4 mb-2 text-white font-semibold rounded-lg absolute bottom-1 sm:left-12 ${
                    isSubscribed ? 'bg-red-500 hover:bg-red-600' : 'bg-teal-500 hover:bg-teal-600'
                }`}
                onClick={handleButtonClick}
            >
                {isSubscribed ? "Annuler l'abonnement" : buttonText}
            </button>
            <p className="mt-4 text-sm text-gray-500 text-center">
                Renouvelable à date anniversaire automatiquement
            </p>
        </div>
    );
};
const SubscriptionList = () => {
    const [userData, setUserData] = useState({ id: null, professional: false });
    const [currentSubscription, setCurrentSubscription] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const sessionData = await api.getSession();
                setUserData({ id: sessionData.user.id, professional: sessionData.user.professional });
            } catch (error) {
                console.error('Error fetching session:', error);
            }
        };

        const fetchSubscription = async () => {
            try {
                const response = await axios.get('https://btobjob.com/get-subscription.php', { withCredentials: true });
                console.log('Subscription data:', response.data); // Log subscription data
                if (response.data && response.data.subscription_exists) {
                    setCurrentSubscription(response.data.amount);
                }
            } catch (error) {
                console.error('Error fetching user subscription:', error);
                navigate('/professionaluser');
            }
        };

        fetchSession();
        fetchSubscription();
    }, [navigate]);

    return (
        <div className="  flex flex-wrap justify-center gap-6 p-6 bg-gray-100">
            {subscriptionData
                .filter(subscription => {
                    const priceInCents = parseFloat(subscription.price) * 100;
                    if (currentSubscription === 20 && priceInCents !== 2000) return false;
                    if (currentSubscription === 220 && priceInCents !== 22000) return false;
                    return true;
                })
                .map((subscription, index) => {
                    const isSubscribed = 
                        (currentSubscription === 20 && subscription.price === '20') ||
                        (currentSubscription === 220 && subscription.price === '220');

                    console.log(`Subscription ${index}: ${subscription.title}, isSubscribed: ${isSubscribed}`); // Log subscription filtering

                    return (
                        <SubscriptionCard
                            key={index}
                            title={subscription.title}
                            price={subscription.price}
                            period={subscription.period}
                            features={subscription.features}
                            buttonText={subscription.buttonText}
                            userId={userData.id}
                            isProfessional={userData.professional}
                            isSubscribed={isSubscribed}
                        />
                    );
                })}
        </div>
    );
};

const App = () => {
    return (
        <div className="  min-h-screen flex flex-col items-center mt-12">
            <p className='text-4xl font-bold text-[#65A406]'>Nos plans tarifaires</p>
            <p>Choisissez un forfait qui vous convient</p>
            <SubscriptionList />
        </div>
    );
};

export default App;
