import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'; // Ensure the path is correct

const UpdatePicturePage = () => {
    const [profileImage, setProfileImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [coverImagePreview, setCoverImagePreview] = useState(null);
    const navigate = useNavigate();

    const handleImageChange = (setImage, setPreview) => (e) => {
        const file = e.target.files[0];
        setImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setPreview(reader.result);
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.updateImages(profileImage, coverImage);

            if (response.success) {
                alert('Images mises à jour avec succès');
                navigate(-1); // Redirect to the previous page
            } else {
                alert(response.message || 'Failed to update images');
            }
        } catch (error) {
            console.error('Error uploading images:', error);
            alert('An error occurred');
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-6">Mettre à jour les images</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Profile Image Section */}
                <div className="flex flex-col items-center">
                    <label className="text-lg font-medium mb-2">Image de profil :</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange(setProfileImage, setProfileImagePreview)}
                        className="mb-4"
                    />
                    {profileImagePreview && (
                        <img
                            src={profileImagePreview}
                            alt="Profile Preview"
                            className="w-32 h-32 object-cover rounded-full border border-gray-300"
                        />
                    )}
                </div>

                {/* Cover Image Section */}
                <div className="flex flex-col items-center">
                    <label className="text-lg font-medium mb-2">Image de couverture :</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange(setCoverImage, setCoverImagePreview)}
                        className="mb-4"
                    />
                    {coverImagePreview && (
                        <img
                            src={coverImagePreview}
                            alt="Cover Preview"
                            className="w-full h-40 object-cover rounded-md border border-gray-300"
                        />
                    )}
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full bg-lime-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
                >
                    Mettre à jour les images
                </button>
            </form>
        </div>
    );
};

export default UpdatePicturePage;
