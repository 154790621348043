import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CiCalendar, CiStopwatch, CiWallet } from "react-icons/ci";
import { FaArrowLeft } from "react-icons/fa";
import { IoLayersOutline, IoBagOutline } from "react-icons/io5";
import api from '../../services/api';
import MessageModal from '../OfferPage/MessageModal';
import Share from '../Share';
const JobDescription = () => {
  const { jobId } = useParams();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [job, setJob] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobData = await api.fetchJobById(jobId);
        setJob(jobData);
      } catch (error) {
        console.error('Erreur lors de la récupération des détails du poste:', error);
      }
    };
    if (jobId) {
      fetchJob();
    }
  }, [jobId]);

  const handleSendMessage = async (message) => {
    if (!job || !job.user_id) {
      alert("Impossible d'envoyer le message : identifiant utilisateur du poste manquant.");
      return;
    }

    const defaultMessage = `Bonjour, je suis intéressé par le poste : "${job.expertise || 'Titre du poste non spécifié'}".\n\n`;
    const fullMessage = `${defaultMessage}${message}`;

    if (!fullMessage.trim()) {
      alert("Veuillez entrer un message avant d'envoyer.");
      return;
    }

    try {
      const response = await api.sendMessagee({
        receiverId: job.user_id,
        message: fullMessage,
      });

      if (response && response.success) {
        alert("Message envoyé avec succès !");
        setIsMessageModalOpen(false);
      } else {
        alert("Échec de l'envoi du message.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
      alert("Une erreur s'est produite lors de l'envoi du message.");
    }
  };

  const viewProfile = () => {
    if (job && job.user_id) {
      navigate(`/profile/specificUser?professional_id=${job.user_id}`);
    }
  };

  if (!job) {
    return <div>Chargement...</div>;
  }

  const jobDetails = [
    { icon: <CiCalendar size={24} color='#65A406' />, label: "Publié le", value: job.created_at || "Non disponible" },
    { icon: <CiStopwatch size={24} color='#65A406' />, label: "Expire le", value: job.job_expires_on || "Non disponible" },
    { icon: <IoLayersOutline size={24} color='#65A406' />, label: "Niveau", value: job.experience || "Non disponible" },
    { icon: <CiWallet size={24} color='#65A406' />, label: "Salaire", value: job.expected_salary ? `${job.expected_salary} €` : "Non disponible" },
    // { icon: <IoBagOutline size={24} color='#65A406' />, label: "Formation requise", value: job.experience_education || "Non disponible" },
    { icon: <IoBagOutline size={24} color='#65A406' />, label: "Catégorie", value: job.category || "Non disponible" },
    { icon: <IoBagOutline size={24} color='#65A406' />, label: "Ville", value: job.city || "Non disponible" },

  ];

  return (
    <div className="max-w-7xl mx-auto p-4 mt-10">
           <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>
      <a
        href={`https://btobjob.com/job_report.php?job_id=${jobId}`}
        className="float-right mb-6 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Signaler ce poste
      </a>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 flex justify-between">
          <div className="flex items-center">
            <img className="w-16 h-16 object-cover rounded-full" src={job.ProfilePicture || "https://via.placeholder.com/150"} alt="Profil" />
            <div className="ml-4">
              <h2 className="text-2xl font-bold">{job.expertise || "Aucun titre"}</h2>
              <p className="text-gray-600">à {job.location || "Non spécifié"}</p>
            </div>
          </div>
          <div className="space-x-2">
            <button
              onClick={viewProfile}
              className="mt-2 px-2 py-1 bg-lime-500 text-sm text-white rounded hover:bg-lime-600 mb-3"
            >
              Voir le profil
            </button>
            <button className="bg-blue-500 hover:bg-blue-600 text-white text-xs px-4 py-2 rounded" onClick={() => setIsMessageModalOpen(true)}>Envoyer un message</button>
            <button className="bg-[#65A406] text-white md:p-2 p-1 rounded text-xs md:text-sm" onClick={() => setOpenShareModal(true)}>Partager</button>
            <a href={job.cv_file} download target="_blank" rel="noopener noreferrer">
              <button className="bg-green-500 hover:bg-green-600 text-xs text-white px-4 py-2 rounded">Télécharger le CV</button>
            </a>
          </div>
        </div>
        <div className="p-6">
          <h3 className="text-xl font-semibold mb-4">Description de l'emploi H/F/X</h3>
          <p>{job.cover_letter || "Aucune description fournie."}</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {jobDetails.map((detail, index) => (
              <div key={index} className="flex items-center space-x-2">
                {detail.icon}
                <div>
                  <p className="text-sm text-gray-500">{detail.label}</p>
                  <p className="font-medium">{detail.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {openShareModal && (
        <Share
          profileUrl={window.location.href}
          closeModal={() => setOpenShareModal(false)}
        />
      )}
      {isMessageModalOpen && (
        <MessageModal
          isOpen={isMessageModalOpen}
          onClose={() => setIsMessageModalOpen(false)}
          onSend={handleSendMessage}
        />
      )}
    </div>
  );
};

export default JobDescription;

