import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Circle, useMap } from 'react-leaflet';
import L from 'leaflet';
import ProfileContent from "../../assests/profileContent.png";
import UserProfile from "../../assests/profileuser.png";
import { MdOutlineEmail , MdVerified } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import Star from "../../assests/Star.png";
import { IoMdContacts } from "react-icons/io";
import { BiMessageRoundedDots } from "react-icons/bi";
import { FaShareAlt } from "react-icons/fa";
import { IoBriefcaseOutline } from "react-icons/io5";
import Photo1 from "../../assests/photos1.png";
import Photo2 from "../../assests/photos2.png";
import { FaEnvelope,FaRegCommentDots } from "react-icons/fa";
import { FaHammer } from "react-icons/fa6";
import { RiFlag2Fill } from "react-icons/ri";
import PostingPicture from "../../assests/postingpicture.png";
import Toggler from "../Toggler";
import { useNavigate } from "react-router-dom";
import PostShare from "../RequestPage/SharePost";
import ProfileShare from "./ProfileShare";
import { MdCancel, MdCurrencyFranc } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Rate2 from "./Rate2";
import axios from "axios";
import api from "../../services/api";
import PostModal from './PostModal';
import { AiOutlineShake } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa6";


// Import Leaflet CSS


import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});


const MainProfile = () => {
  // Share open/Close
  const [openShare, setOpenShare] = useState(false);
  const toggleShare = () => {
    setOpenShare((prevState) => !prevState);
  };

  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rate, setRate] = useState({ minPrice: 0, maxPrice: 0 });
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [liked, setLiked] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const initialZoomLevel = 7;
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState({});
    const [selectedPostTitle, setSelectedPostTitle] = useState(null);

    const renderDocument = (docUrl) => {
      if (!docUrl) {
        return (
          <div className="w-full h-48 flex items-center justify-center bg-gray-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-12 h-12 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 2.25c-5.376 0-9.75 4.373-9.75 9.75 0 5.377 4.374 9.75 9.75 9.75s9.75-4.373 9.75-9.75c0-5.377-4.374-9.75-9.75-9.75zM9 9.75h6m-3-3v6"
              />
            </svg>
          </div>
        );
      } else {
        return (
          <div className="w-full h-48 flex items-center justify-center bg-gray-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-12 h-12 text-red-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 2.25c-5.376 0-9.75 4.373-9.75 9.75 0 5.377 4.374 9.75 9.75 9.75s9.75-4.373 9.75-9.75c0-5.377-4.374-9.75-9.75-9.75zM9 9.75h6m-3-3v6"
              />
            </svg>
            <p className="mt-2 text-xs">PDF</p>
          </div>
        );
      }
    };
    
  
    // Function to toggle menu for a specific post
    const toggleMenu = (postId) => {
      setIsMenuOpen((prev) => ({ ...prev, [postId]: !prev[postId] }));
    };
  // Rate Open/Close
  const [openRate, setOpenRate] = useState(false);
  const toggleRate = () => {
    setOpenRate((prevState) => !prevState);
    setTimeout(() => {
      setOpenRate(false);
    }, 20000); // Adjust the delay as needed (5000ms = 5 seconds)
  };
  const navigateToReviews = () => {
    window.location.href = 'https://btobjob.com/all-reviews.php';
};

const openLightbox = (imageSrc) => {
  setSelectedImage(imageSrc);
  setLightboxOpen(true);
};

const closeLightbox = () => {
  setLightboxOpen(false);
  setSelectedImage(null);
};  

  // User data state
  const [user, setUser] = useState(null);
  
  const [position, setPosition] = useState([51.505, -0.09]);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [posts, setPosts] = useState([]);
  const [likesStatus, setLikesStatus] = useState({});
  const [ratingData, setRatingData] = useState({ avg_rating: 0, total_reviews: 0 });
  const [isProfessional, setIsProfessional] = useState(false);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [isMapLocked, setIsMapLocked] = useState(true);
  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState('');

  const [selectedPostComments, setSelectedPostComments] = useState([]);  // State to hold selected post's comments
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);   
  const [likeCounts, setLikeCounts] = useState({});
const [commentCounts, setCommentCounts] = useState({});
const [radius, setRadius] = useState(1000);
const [shareUrl, setShareUrl] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
 
    // Define functions
    const openCommentsModal = (postId, postTitle) => {
      setSelectedPostId(postId);
      setSelectedPostTitle(postTitle); // Set the selected post's title
      fetchComments(postId); // Fetch comments when modal opens
      setIsCommentsModalOpen(true);
    };
    
  
    // Close Comments Modal
    const closeCommentsModal = () => {
      setIsCommentsModalOpen(false);
      setSelectedPostId(null);
      setSelectedPostComments([]);
    };
  const toggleMapLock = () => {
    setIsMapLocked((prevState) => !prevState);
  };
  const fetchSession = async () => {
    try {
      // Fetch session data to get user
      const sessionData = await api.getSession();
      setUser(sessionData.user);

      if (sessionData.user) {
        // Fetch the user's profile (if needed)
        const profileData = await api.fetchProfileById(sessionData.user.id);

        if (profileData.latitude && profileData.longitude) {
          // Do something with location if needed
          setPosition([profileData.latitude, profileData.longitude]);
        }

        // After getting the session and user, fetch posts
       
      }
    } catch (error) {
      console.error('Error fetching session data:', error);
    }
  };
  const navigateToOpinions = () => {

        window.location.href = `https://btobjob.com/all-opinions.php`;
   
};
   // Trigger PHP reminder script
   const triggerReminderScript = async () => {
    try {
      const response = await axios.get(`https://btobjob.com/sub-reminder.php`, { withCredentials: true });
      console.log("Reminder script response:", response.data);
    } catch (error) {
      console.error("Error triggering reminder script:", error);
    }
  };
useEffect(() => {
  const fetchProfileData = async () => {
    try {
      // Fetch profile data, including latitude and longitude
      const response = await api.fetchProfile();
      setProfileData(response);

      // Check for latitude and longitude in response and update position
      if (response.latitude && response.longitude) {
        setPosition([response.latitude, response.longitude]);
      }
      setRadius(response.radius);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  triggerReminderScript();
  fetchProfileData(); // Call the function once when component mounts
}, []); // Empty dependency array ensures it only runs once

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await axios.get('https://btobjob.com/get-user-reviews.php', { withCredentials: true });
        const data = response.data;

        // Ensure avg_rating is a number and handle possible undefined/null
        const avgRating = data.avg_rating !== null && data.avg_rating !== undefined
          ? parseFloat(data.avg_rating)
          : 0;

        setRatingData({
          avg_rating: avgRating,
          total_reviews: data.total_reviews || 0
        });
      } catch (error) {
        console.error('Error fetching ratings:', error);
        setError('Error fetching ratings');
      } finally {
        setLoading(false);
      }
    };

    fetchRatings();
  }, []);
  const avgRating = ratingData.avg_rating ? ratingData.avg_rating.toFixed(1) : '0.0';
  useEffect(() => {
    const fetchPhotos = async () => {
        try {
            const response = await axios.get('https://btobjob.com/get_photos.php', { withCredentials: true });
            if (Array.isArray(response.data)) {
                setPhotos(response.data.slice(0, 4)); // Limit to 4 photos
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (error) {
            console.error("Error fetching photos:", error);
            setError("Error fetching photos");
        } finally {
            setLoading(false);
        }
    };

    fetchPhotos();
}, []);
const isImageFile = (url) => {
  return /\.(jpg|jpeg|png|gif)$/i.test(url);
};
useEffect(() => {
  const getSubscription = async () => {
    try {
      const data = await api.getSubscription();
      setSubscriptionExists(data.subscription_exists);
      setIsProfessional(data.subscription_exists);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };

  getSubscription();
}, []);


  useEffect(() => {
    const fetchImages = async () => {
      try {
        const profileData = await api.fetchProfileImage();
        const coverData = await api.fetchCoverImage();

        setProfileImagePreview(profileData.imageUrl);
        setCoverImagePreview(coverData.imageUrl);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);
  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get('https://btobjob.com/fetchRates.php', { withCredentials: true });
        setRate(response.data);
      } catch (error) {
        console.error('Error fetching rates:', error);
      }
    };

    fetchRates();
  }, []);
  const checkLikeStatus = async (postId) => {
    try {
        const response = await axios.post('https://btobjob.com/check-like.php', { postId }, { withCredentials: true });
        return {
            liked: response.data.liked,
            totalLikes: response.data.totalLikes
        };
    } catch (error) {
        console.error('Error checking like status:', error);
        return { liked: false, totalLikes: 0 };
    }
};

const fetchComments = async (postId) => {
  try {
    const response = await axios.post(
      'https://btobjob.com/fetch-post-comments.php',
      { postId },
      { withCredentials: true }
    );
    if (response.status === 200) {
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: response.data.comments,
      }));
      setSelectedPostComments(response.data.comments); // Set comments for selected post

      // Return the number of comments for the post
      return response.data.comments.length;
    } else {
      console.error('Error fetching comments:', response.data.error);
      return 0;
    }
  } catch (error) {
    console.error('Error fetching comments:', error);
    return 0;
  }
};
const handleDeletePost = async (postId) => {
  try {
    const response = await axios.get(`https://btobjob.com/delete-post.php?postId=${postId}`, { withCredentials: true });

    if (response.data.success) {
      console.log("Post deleted successfully");
      window.location.reload(); // Refresh the page after successful deletion
    } else {
      console.error("Error deleting post:", response.data.error);
    }
  } catch (error) {
    console.error("Error deleting post:", error);
  }
};


const handleAddComment = async () => {
  // Ensure there's no empty or invalid input
  if (!newComment.trim()) return;

  try {
    // Send only the postId and commentText, no event or other complex object
    const response = await axios.post(
      'https://btobjob.com/add-post-comment.php',
      { postId: selectedPostId, commentText: newComment.trim() },
      { withCredentials: true }
    );

    if (response.data.success) {
      setNewComment('');  // Reset comment input
      fetchComments(selectedPostId);  // Fetch updated comments
    } else {
      console.error('Error adding comment:', response.data.error);
    }
  } catch (error) {
    console.error('Error adding comment:', error);
  }
};
  
const handleLike = async (postId) => {
  try {
    const response = await axios.post(
      'https://btobjob.com/like-post.php',
      { postId },
      { withCredentials: true }
    );

    // Update the like status and count based on the response
    setLikesStatus((prevStatus) => ({
      ...prevStatus,
      [postId]: response.data.liked, // Update liked status
    }));
    setLikeCounts((prevCounts) => ({
      ...prevCounts,
      [postId]: response.data.liked
        ? (prevCounts[postId] || 0) + 1 // Increment count if liked
        : (prevCounts[postId] || 0) - 1, // Decrement count if unliked
    }));
  } catch (error) {
    console.error('Error liking the post:', error);
  }
};
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsData = await api.fetchPosts();
        setPosts(postsData);
  
        // Fetch like statuses for each post
        const likesPromises = postsData.map((post) => checkLikeStatus(post.id));
        const likesResults = await Promise.all(likesPromises);
        const newLikeStatus = {};
        const newLikeCounts = {};
        postsData.forEach((post, index) => {
          newLikeStatus[post.id] = likesResults[index];
          newLikeCounts[post.id] = likesResults[index].totalLikes;
        });
        setLikesStatus(newLikeStatus);
        setLikeCounts(newLikeCounts);
  
        // Fetch comment counts for each post
        const commentCountsObj = {};
        await Promise.all(
          postsData.map(async (post) => {
            const totalComments = await fetchComments(post.id);
            commentCountsObj[post.id] = totalComments;
          })
        );
        setCommentCounts(commentCountsObj);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
  
    fetchPosts();
  }, []);
  const MapEvents = () => {
    useMapEvents({
      click: (e) => {
        if (!isMapLocked) { 
          const { lat, lng } = e.latlng;
          setPosition([lat, lng]);
        }
      },
    });
    return null;
  };
  
  // Navigation
  const navigate = useNavigate();
  const NavigateToeditprofile = () => {
    navigate("/profile/editprofile");
  };

  const NavigateTochat = () => {
    navigate("/profile/chat");
  };

  const NavigateToallphotos = () => {
    navigate("/profile/allphotos");
  };
  const NavigateToDocs = () => {
    navigate("/profile/profiledoc");
  };
  const NavigateToAddOffer = () => {
    navigate("/profile/addoffer");
  };

  const NavigateToViewRequests = () => {
    navigate("/profile/requests");
  };
  const navigateToEditPost = (postId) => {
    navigate(`/profile/editpost/id/${postId}`);
};
const handleUpdateLocation = () => {
  window.location.href = "https://btobjob.com/update-map.php";
};
  const AddBio = () => {
    window.location.href = "https://btobjob.com/bio.php";
  };

  // Open service drop down
  const [isOpenService, setIsOpenService] = useState(false);

  const toggleDropdownForService = () => {
    setIsOpenService(prevState => !prevState);
  };
  const navigateToPostDetails = (postId) => {
    navigate(`/profile/posts/${postId}`); // Navigate to the post details page with postId in the URL
  };
  const openPostShareModal = (postId) => {
    const postShareUrl = `https://btobjob.com/profile/posts/${postId}`;
    setShareUrl(postShareUrl);
    setSelectedPostId(postId);
    setIsShareModalOpen(true);
  };


  const closePostShareModal = () => {
    setIsShareModalOpen(false);
    setShareUrl("");
  };
  const defaultPosition = [51.505, -0.09]; // Default position [latitude, longitude]

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng); // Update position on map click
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>
          <button onClick={saveLocation}>Save Location</button>
        </Popup>
      </Marker>
    );
  };
  // useEffect(() => {
  //   const fetchLocation = async () => {
  //     try {
  //       const response = await axios.get("https://btobjob.com/get-user-location.php", {
  //         withCredentials: true,
  //       });

  //       if (response.data.latitude && response.data.longitude) {
  //         setPosition([response.data.latitude, response.data.longitude]);
  //       } else {
  //         console.error("Location data is missing in the response.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching location:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchLocation();
  // }, []);
  const saveLocation = async (latitude, longitude) => {
    try {
      const response = await axios.post("https://btobjob.com/update-location.php", {
        latitude,
        longitude
      }, { withCredentials: true });
  
      if (response.data.success) {
        alert("Location updated successfully!");
      } else {
        alert(response.data.error || "Failed to update location.");
      }
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!profileData) {
    return <div>Profile data not found.</div>;
  }
  const requestLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setPosition([latitude, longitude]); // Update map position

          // Send location to backend (optional)
          try {
            const response = await axios.post(
              "https://btobjob.com/save-location.php", // Backend API endpoint
              { latitude, longitude },
              { withCredentials: true }
            );
            if (response.data.success) {
              alert("Location saved successfully!");
            } else {
              alert("Failed to save location. Please try again.");
            }
          } catch (error) {
            console.error("Error saving location:", error);
          }
        },
        (error) => {
          console.error("Error getting location:", error);
          alert("Unable to fetch your location. Please allow location access.");
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };



  const calculateZoomLevel = (radius) => {
    const zoomLevels = 20; // Total number of zoom levels in Leaflet
    const maxZoomRadius = 20037508.34; // Max radius at zoom level 0 (entire world)
  
    // Calculate a zoom level between 0 and `zoomLevels`
    const zoom = Math.log2(maxZoomRadius / radius);
    return Math.min(Math.max(Math.round(zoom), 0), zoomLevels - 1);
  };
  
  // Component to update the map's center and zoom
  const MapViewUpdater = ({ center, radius }) => {
    const map = useMap();
  
    useEffect(() => {
      if (center && radius) {
        const zoom = calculateZoomLevel(radius);
        map.setView(center, zoom);
      }
    }, [center, radius, map]);
  
    return null;
  };
  return (
    <>
      <div className="  mx-auto md:max-w-3xl lg:max-w-4xl mt-10">
      <div className="relative w-full h-full overflow-hidden">
  <img
    src={coverImagePreview || 'default-cover.jpg'}
    className="w-full h-full object-contain"
    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
    alt="Cover"
  />
</div>

        <div className="  flex flex-col bg-white pb-6 relative">
          <div className="  flex justify-between py-1 px-1 bg-white relative mb-4">
            <div className="  flex relative ">
            <img
                src={profileImagePreview || 'default-profile.jpg'}
                className="  w-24 h-24 object-cover rounded-full border-white"
                alt="Profile"
              />
              {profileData.kbis_file && profileData.insurance && profileData.civil_liability ? (
    <MdVerified color="green" size="1.5em" title="Verified" />
  ) : (
    <p className="text-gray-500 text-sm">Non vérifié</p>
  )} {/* Explicit check for verified status */}
              <div className="  flex flex-col items-start max-w-96">
              <span className="  user-name">{profileData ? profileData.Name: 'Loading...'}</span>
              <span className="  user-email">@{profileData.username}</span>
              <span className="  user-email">{profileData.job_title}</span>
              </div>
            </div>
            <div className="  flex flex-col absolute right-1">
            <div className="flex flex-row items-center justify-center gap-2 mt-48 sm:mt-48 lg:mt-0 flex-wrap">

            <button
    onClick={toggleShare}
    className="flex items-center justify-center w-[40px] h-[40px] bg-white rounded-full border-2 border-[#65A406] text-[#65A406] sm:w-[50px] sm:h-[50px] transition-all duration-300"
  >
    <FaShareAlt className="text-[#65A406]" size={20} />
  </button>
  <button
    onClick={NavigateToeditprofile}
    className="flex items-center justify-center w-[40px] h-[40px] bg-white rounded-full border-2 border-[#65A406] text-[#65A406] sm:w-[50px] sm:h-[50px] transition-all duration-300"
  >
    <FaInfoCircle className="text-[#65A406]" size={20} />
  </button>
  {subscriptionExists && (
    <button
      onClick={AddBio}
      className="flex items-center justify-center w-[40px] h-[40px] bg-white rounded-full border-2 border-[#65A406] text-[#65A406] sm:w-[50px] sm:h-[50px] transition-all duration-300"
    >
      <FaBookOpen className="text-[#65A406]" size={20} />
    </button>
  )}
</div>


              
              <Toggler />
            </div>
          </div>

          <div className="  flex flex-col ml-">
            <div className="  flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <CiLocationOn className="  location-email-icon" />        <p>{profileData.Address}, {profileData.city}, {profileData.zip}</p>
            </div>
            <div className="  flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <AiOutlineShake className="  location-email-icon" />        <p>{profileData.Phone}</p>
            </div>
            <div className="  flex items-center text-xs text-[#004225] sm:text-[15px] font-normal leading-5">
              <MdOutlineEmail className="  location-email-icon" />
              <p>{profileData.Email}</p>
            </div> 
            {subscriptionExists && (
  <div className="flex flex-col items-start text-xs text-[#004225] sm:text-[15px] font-normal leading-5 ms-10 mt-32 sm:mt-0">
    <h6 className="mb-1">Biographie:</h6> {/* Added a margin-bottom for spacing */}
    <p className="font-bold">
      {profileData.bio}
    </p>
  </div>
)}


          </div>
        </div>

        {/* Conditional button rendering */}
        {/* <div className="  flex justify-center mt-4">
          {user ? (
            user.professional ? (
              <div className="  flex gap-2">
                <button onClick={NavigateToAddOffer} className="  bg-blue-500 text-white px-4 py-2 rounded">
                  Add Offer
                </button>
                <button onClick={NavigateToViewRequests} className="  bg-blue-500 text-white px-4 py-2 rounded">
                  View Requests
                </button>
              </div>
            ) : (
              <button onClick={NavigateToViewRequests} className="  bg-blue-500 text-white px-4 py-2 rounded">
                View Requests
              </button>
            )
          ) : (
            'Loading...'
          )}
        </div> */}

        {/* Zain req */}
        {/* <div className="  bg-white border-t-2">
        <ul className="  purple-list">
          <li className="  purple-listing">Aperçu</li>
          <li className="  purple-listing">Demandes</li>
          <li className="  purple-listing">Activité</li>
          <li className="  purple-listing">Avis</li>
          <li className="  purple-listing">Des photos</li>
        </ul>
      </div> */}
        {/* main div */}
 
        <div className="  flex flex-col-reverse sm:flex-row justify-between mt-5 ">
          
          <div className="  grid grid-cols-2 sm:flex sm:flex-col gap-2">
          {subscriptionExists && (   <div
              className="  flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer"
             
            >
               <p  ClassName="    flex items-center gap-1 p-4">
              <strong>Siret:</strong> {profileData.kbis}
              </p>

            </div>
          )}
            <div
              className="  flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer"
              onClick={navigateToReviews}
            >
               <p  ClassName="    flex items-center gap-1">
                {avgRating} <img src={Star} className="  w-3 h-3" alt="Star" />
              </p>
               <p  ClassName="    div1-para-reviews">{ratingData.total_reviews} Avis</p>
            </div>

            <div
              className="  flex flex-col items-center justify-center bg-white w-full sm:w-[150px] rounded-xl sm:mt-0 mt-3 cursor-pointer"
              onClick={navigateToOpinions}
            >
               <p  ClassName="flex items-center gap-1">
               
              </p>
               {/* <p  ClassName="div1-para-reviews"> View opinions</p> */}
            </div>
            {subscriptionExists && (
                <div className="  flex flex-col justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 px-2 py-2">
                    <div className="  flex justify-between">
                         <p  ClassName="    photos-para">Documents</p>
                         <p className="text-lime-500 cursor-pointer" onClick={NavigateToDocs}>Voir</p>

                    </div>
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                      <div className="grid grid-cols-2 gap-2">
                      {profileData.insurance ? (
    isImageFile(profileData.insurance) ? (
        <img
            src={`https://btobjob.com/${profileData.insurance}`}
            className="w-full max-w-[100px] h-auto object-contain cursor-pointer"
            alt="User Insurance"
            onClick={() => openLightbox(`https://btobjob.com/${profileData.insurance}`)}
        />
    ) : (
        <div className="flex items-center justify-center bg-gray-200 rounded-md p-4">
           <FaFilePdf className="w-4 h-4 sm:w-6 sm:h-6 lg:w-6 lg:h-6 text-red-500" />
        </div>
    )
) : (
    <div className="flex items-center justify-center bg-gray-200 rounded-md p-4">
       <p className="text-Black-500">N/A</p>
    </div>
)}
                  
                      {profileData.civil_liability ? (
                          isImageFile(profileData.civil_liability) ? (
                              <img
                                  src={`https://btobjob.com/${profileData.civil_liability}`}
                                  width={70}
                                  className="w-full max-w-[100px] h-auto object-contain cursor-pointer"
                                  alt="User Civil Liability"
                                  onClick={() => openLightbox(`https://btobjob.com/${profileData.civil_liability}`)}
                              />
                          ) : (
                              <div className="flex items-center justify-center bg-gray-200 rounded-md p-4">
                                <FaFilePdf className="w-4 h-4 sm:w-6 sm:h-6 lg:w-6 lg:h-6 text-red-500" />
                              </div>
                          )
                      ) : (
                          <p className="text-Black-500">N/A</p>
                      )}
                  </div>
                  
                    )}
                </div>
                 
            )}

            {lightboxOpen && (
                <div
                    className="  fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 overflow-auto pt-20"
                    onClick={closeLightbox}
                >
                    <div className="  relative max-w-[50vw] max-h-[50vh] flex items-center justify-center">
                        <img
                            src={selectedImage}
                            className="  w-auto h-auto max-w-full max-h-full object-contain"
                            alt="Document in Lightbox"
                        />
                        <button
                            onClick={closeLightbox}
                            className="  absolute top-2 right-2 text-white text-2xl"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
            
       
            {/* 2nd */}
            {/* <div className="  flex flex-col  justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 pl-4">
              <div className="  flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoMdContacts className="  w-5 h-5" />
                <div className="  flex flex-col text-black">
                   <p  ClassName="    font-semibold">38,47,154</p>
                  <p>Connexions</p>
                </div>
              </div>
              <div className="  flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <BiMessageRoundedDots className="  w-5 h-5" />
                <div className="  flex flex-col text-black">
                   <p  ClassName="    font-semibold">70%</p>
                  <p> Temps de réponse</p>
                </div>
              </div>
              <div className="  flex items-center text-[#65A406] text-[10px] sm:text-xs gap-2 py-1">
                <IoBriefcaseOutline className="  w-5 h-5" />
                <div className="  flex flex-col text-black">
                   <p  ClassName="    font-semibold">3324</p>
                  <p>Emploi en direct</p>
                </div>
              </div>
            </div> */}
            {/* 3rd */}
            
            {subscriptionExists&& (
            <div className="  flex flex-col justify-center bg-white w-full sm:w-[150px] rounded-xl mt-3 px-2 py-2">
            <div className="  flex justify-between">
                 <p  ClassName="    photos-para">Des photos</p>
             <p className="text-lime-500 cursor-pointer" onClick={NavigateToallphotos}>Voir</p>

            </div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="  grid grid-cols-2 gap-2">
                    {photos.map((photo, index) => (
                        <img
                            key={index}
                            src={`https://btobjob.com/${photo.photo_url}`}
                            width={70}
                            className="  w-full object-contain"
                            alt={`User Photo ${index + 1}`}
                        />
                    ))}
                </div>
            )}
            
        </div>
           )}
            {/* 4th */}
            <div className="  flex flex-col gap-2 justify-center items-start bg-white px-2 py-2 rounded-xl mt-3 w-full sm:w-[150px]">
              <div onClick={NavigateTochat} className=" bg-lime-500 p-1 rounded text-white flex items-center justify-center gap-2 text-md cursor-pointer">
                <FaEnvelope className="  " />
                 <p  ClassName="    div2-para">Envoyer le message</p>
              </div>
              {subscriptionExists && (
              <div className="  flex items-center justify-center gap-2 text-md">
               €
                 <p  ClassName="    div2-para">taux horaire: {rate.hourlyRate}</p>
              </div>

)}
     {subscriptionExists && (
              <div className="" onClick={toggleRate}>
                <div className=" bg-lime-500 p-1 rounded text-white flex items-center justify-center gap-2 text-md cursor-pointer">
                  <FaEdit className="  " />
                   <p  ClassName="    div2-para">Modifier le tarif</p>
                </div>
              </div>
                )}


              <div className="  flex items-center "></div>
              <div className="  flex items-center"></div>
            </div>
          </div>
          {/* right 👇🏻 */}
         <div className="  flex flex-col gap-2 w-full sm:ml-4">
         {subscriptionExists && ( 
      <div className="  flex items-center justify-center w-full bg-white py-2 rounded-xl">
      <input
  type="text"
  placeholder="Quoi de neuf ?"
  onClick={openModal}
  className="border-2 text-sm sm:text-base px-3 py-2 rounded-md mr-1 w-full md:w-2/3"
/>
<button
  className="text-sm sm:text-base px-3 py-2 bg-[#65A406] rounded-md text-white"
  onClick={openModal}
>
  Envoie
</button>
        
      </div>    )}

      <PostModal isOpen={isModalOpen} onClose={closeModal} />
    
      <div className="w-full bg-white py-2 px-2 rounded-xl">
      
      <MapContainer
        center={position}
        zoom={13} // Default zoom
        style={{ height: "400px", width: "100%" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href='https://osm.org/copyright'>OpenStreetMap</a> contributors"
        />
        <Marker position={position}>
          <Popup>Votre emplacement</Popup>
        </Marker>
        <Circle center={position} radius={radius} color="#007bff" fillOpacity={0.4} />
        
        {/* Dynamically update zoom and center based on position and radius */}
        <MapViewUpdater center={position} radius={radius} />
      </MapContainer>

      <div className="mt-4">
        <button
          onClick={requestLocation}
          className="bg-lime-500 text-white px-4 py-2 rounded text-sm ms-4"
        >
          Autoriser l'enregistrement automatique
        </button>
        <button
          onClick={handleUpdateLocation}
          className="ms-3 bg-lime-500 text-white px-4 py-2 rounded text-sm"
        >
          Mettre à jour manuellement
        </button>
        </div>
      </div>
    
          {/* Post */}
          <div className="  flex flex-col gap-1">
          {posts.map((post) => (
    <div
      key={post.id}
      className="bg-white p-8 shadow-md rounded-lg w-full mb-4 cursor-pointer"
      onClick={() => navigateToPostDetails(post.id)} // Navigate on post click
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <img src={post.profile_picture} alt="User Avatar" className="w-8 h-8 rounded-full" />
          <div>
            <p className="text-gray-800 font-semibold">{post.name}</p>
            <p className="text-gray-500 text-sm">{post.created_at}</p>
          </div>
        </div>

        {/* Three-dot menu for edit and delete */}
        <div className="relative">
          <button onClick={(e) => { e.stopPropagation(); toggleMenu(post.id); }} className="text-3xl font-bold text-gray-500 hover:text-gray-800">
            &#x22EE;
          </button>
          {isMenuOpen[post.id] && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-[9999]">
              <button
                onClick={(e) => { e.stopPropagation(); navigateToEditPost(post.id); }}
                className="w-full text-left px-4 py-2 text-gray-500 hover:text-gray-800 hover:bg-gray-100"
              >
                Modifier
              </button>
              <button
                onClick={(e) => { e.stopPropagation(); handleDeletePost(post.id); }}
                className="w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100"
              >
                Supprimer
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Post content */}
      <div className="mb-4">
        <p className="text-gray-800">{post.text}</p>
      </div>

      {/* Post media */}
      {post.media && post.media.length > 0 && (
        <div className={`mb-4 ${post.media.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
          {post.media.map((file, index) => (
            <div key={index} className="relative">
              {file.type.startsWith('image/') ? (
                <img
                  src={file.url}
                  alt={`Post Media ${index + 1}`}
                  className="w-full h-full object-cover rounded-md mb-2"
                />
              ) : file.type.startsWith('video/') ? (
                <video
                  controls
                      controlsList="nodownload"
                  className="w-full h-full object-contain rounded-md mb-2"
                >
                  <source src={file.url} type={file.type} />
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
          ))}
        </div>
      )}

                {/* Like and Comment Buttons */}
                <div className="flex items-center justify-between text-gray-500">
                <button
    className={`flex items-center gap-2 px-2 hover:bg-gray-50 rounded-full p-1 ${
      likesStatus[post.id] ? 'text-red-600' : 'text-gray-500'
    }`}
    onClick={(e) => {
      e.stopPropagation(); // Prevent click propagation
      handleLike(post.id); // Trigger like/unlike functionality
    }}
  >
  {likeCounts[post.id] || 0}  {likesStatus[post.id] ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-5 h-5 text-red-600"
      >
        <path d="M12 21.35l-1.45-1.32C6.11 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-4.11 6.86-8.55 11.54L12 21.35z" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        className="w-5 h-5 text-gray-500"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4.318 6.318a4.5 4.5 0 016.364 0L12 7.414l1.318-1.096a4.5 4.5 0 016.364 6.364L12 21l-7.682-7.682a4.5 4.5 0 010-6.364z"
        />
      </svg>
    )}
    <span> {likesStatus[post.id] ? "J'Aimé" : 'Aimer'}</span>
  </button>

  {/* Comment Button */}
  <button
    onClick={(e) => {
      e.stopPropagation();
      openCommentsModal(post.id, post.text);
    }}
    className="flex items-center gap-2 text-blue-500"
  >
   {commentCounts[post.id] || 0} <FaRegCommentDots className="w-5 h-5" />
    <span> Commentaires</span>
  </button>

  {/* Share Button */}
  <button
    className="text-gray-500 hover:text-blue-500 flex items-center gap-2"
    onClick={(e) => {
      e.stopPropagation(); // Prevent post detail navigation on button click
      openPostShareModal(post.id); // Open share modal with post URL
    }}
  >
    <FaShareAlt className="w-5 h-5" />
    <span>Partager</span>
  </button>


                </div>
                </div>
           
            ))}
          </div>
     
     

      {isCommentsModalOpen && (
        <div className="fixed inset-0 flex z-[999] items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg max-w-lg w-full relative">
            {/* Close Button (Cross) */}
            <button
              onClick={closeCommentsModal}
              className="absolute top-2 right-2 text-gray-500"
            >
              &times;
            </button>

            {/* <h2>Commentaires pour le poste: {selectedPostTitle}</h2> */}

            {/* Displaying Comments */}
            {selectedPostComments.length > 0 ? (
              selectedPostComments.map((comment, index) => (
                <div key={index} className="mt-2 border-b pb-2">
                  <p><strong>{comment.user_name}</strong>: {comment.comment_text}</p>
                  <p className="text-gray-500 text-sm">{new Date(comment.created_at).toLocaleString()}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">Pas encore de commentaires.</p>
            )}

            {/* Add New Comment */}
            <div className="mt-4">
              <input
                type="text"
                placeholder="Ajouter un commentaire..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="border p-2 rounded w-full"
              />
              <button
                onClick={handleAddComment}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Soumettre
              </button>
            </div>
          </div>
        </div>
      )}


     </div>     </div>     </div>
     {isShareModalOpen && (
        <PostShare
          isOpen={isShareModalOpen}
          onClose={closePostShareModal}
          shareUrl={shareUrl}
        />
      )}
      {/* Additional components for sharing and rating */}
      <ProfileShare boolean={openShare} />
      <Rate2 boolean={openRate} />
    </>
  );
};
export default MainProfile;