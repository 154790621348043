import './login.css'
import { useState, useEffect, useRef } from 'react';
import { MdOutlineNotifications } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import LOGO2 from '../assests/blog_logo.png'
import bloglogo from '../assests/blog_logo.png';
import { FaHome } from "react-icons/fa";
import { RiDiscountPercentFill } from "react-icons/ri";
import { MdAddComment } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";
import { MdForum } from "react-icons/md";
import UserProfile from '../assests/profileuser.png'
import { BiSolidMessageRoundedError } from "react-icons/bi";
import { HiSearchCircle } from "react-icons/hi";
import { CgWebsite } from "react-icons/cg";
import { FaInfoCircle } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoMdShare } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { BiSolidCategoryAlt } from "react-icons/bi";
import PublicOrPrivate from './PublicOrPrivate';
import ProfileShare from './ProfileView/ProfileShare';
import { BiSolidMessageSquare } from "react-icons/bi";
import Notifications from './Notifications';
import requests from './RequestPage/Request'
import api from '../services/api'
import { FaFlag } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import { FaPoll } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa";
import { FaTrello } from "react-icons/fa";
import ChatBot from './chatbot'; // Import your ChatBot component
import ChatBotIcon from './ChatBotIcon';
import axios from 'axios';

import LanguageDropdown from '../language/LanguageDropdown';
import { FaExclamationTriangle  } from 'react-icons/fa'; 
function NavbarProfile() {
    const [isOpen, setIsOpen] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [nav, setNav] = useState(false);
    const [clickTimeout, setClickTimeout] = useState(null);
    const [openDiv, setOpenDiv] = useState(false);
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [openShare, setOpenShare] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [openNotif, setopenNotif] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false); // State to toggle ChatBot visibility
    const [isChatVisible, setIsChatVisible] = useState(false); // State to toggle ChatBot visibility
    const chatContainerRef = useRef(null); // Reference for ChatBot container
    const dropdownMenuRef = useRef(null); // Reference for dropdown
    const [open, setOpen] = useState(false);
    const [isDropOpen, setIsDropOpen] = useState(false); 
    const closeMenu = () => setIsMenuOpen(false);
    const [unseenNotifications, setUnseenNotifications] = useState(false); 
    const [isProfessional, setIsProfessional] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const [newNotifications, setNewNotifications] = useState([]); 
    const [selectedLanguage, setSelectedLanguage] = useState('fr');  // French as the default language
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to toggle the dropdown
    const toggleDropdown = (dropdownName) => {
      setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };
    const handleIconClick = () => {
      setIsChatOpen(true); // Open the ChatBot when the icon is clicked
    };
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };
  
    const closeDropdown = () => {
      setOpenDropdown(null);
    };
  
    const handleCloseChat = () => {
      setIsChatOpen(false); // Close the ChatBot when 'X' is clicked
    };
   
    const dropdownRef = useRef(null); // Reference for dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
              closeMenu();
          }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
    const getDivState = (state) => {
        setOpenDiv(state);
    };

    const getNotifState = (state) => {
        setopenNotif(state);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
      if (
          dropdownRef.current && !dropdownRef.current.contains(event.target) // Main dropdown
      ) {
          if (openDropdown) {
              setOpenDropdown(null); // Close inner dropdowns like "historique" or "publier"
          } else {
              setIsOpen(false); // Close the main dropdown
          }
      }
  };
  useEffect(() => {
    const handleScroll = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
        if (isOpen) {
            setIsOpen(false);
        }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [isMenuOpen, isOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [openDropdown, isOpen]);
   // Function to check if a notification is within 10 seconds from now
   const isNotificationRecent = (timestamp) => {
    const notificationTime = new Date(timestamp); // Convert timestamp to Date object
    const currentTime = new Date(); // Get the current time
    const timeDifference = (currentTime - notificationTime) / 1000; // Difference in seconds

    return timeDifference <= 10; // Return true if it's within 10 seconds
};

// Function to trigger desktop notifications
const showDesktopNotification = (notification) => {
    if (Notification.permission === 'granted') {
        const options = {
            body: notification.message,
            icon: LOGO2, // Optional: add an icon for the notification
            timestamp: notification.timestamp, // Include timestamp if needed
        };
        new Notification('New Notification', options); // Create the notification
    }
};

// Request permission for desktop notifications
const requestNotificationPermission = () => {
    if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission().then(permission => {
            if (permission !== 'granted') {
                console.error('Permission for desktop notifications denied');
            }
        });
    }
};
const eventSource = new EventSource('https://btobjob.com/notification-stream.php', { withCredentials: true });

eventSource.onmessage = (event) => {
    try {
        const data = JSON.parse(event.data);
        const { unseen_count } = data;

        // Update state based on unseen_count
        setUnseenNotifications(unseen_count > 0);
    } catch (error) {
        console.error('Error parsing notification stream:', error);
    }
};

const fetchNotifications = async () => {
  try {
      const response = await axios.post('https://btobjob.com/check-notifications.php', {}, { withCredentials: true });
      const { unseen_count } = response.data;
      setUnseenNotifications(unseen_count > 0);
  } catch (error) {
      console.error('Error fetching notifications:', error);
      setUnseenNotifications(false);
  }
};


const clearNotifications = async () => {
  try {
      await axios.post('https://btobjob.com/clear-notifications.php', {}, { withCredentials: true });
      setUnseenNotifications(false);
  } catch (error) {
      console.error('Error clearing notifications:', error);
  }
};


// Polling for notifications every 10 seconds
useEffect(() => {
    requestNotificationPermission(); // Request permission on component mount
    const notificationInterval = setInterval(fetchNotifications, 1000);
    return () => clearInterval(notificationInterval); // Clean up on component unmount
}, []);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const profileData = await api.fetchProfileImage();
                setProfileImagePreview(profileData.imageUrl);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        fetchImages();
    }, []);
  
      useEffect(() => {
        fetchNotifications();
    }, []);
    
      const handleLogout = async () => {
        try {
            await api.logout(); // Call the logout API
            setIsLoggedIn(false); // Update the logged-in state
            setNav(false); // Close navigation
            navigate('/'); // Redirect to homepage
        } catch (error) {
            console.error('Logout error', error);
        }
    };
    const navigate = useNavigate();

    const NavigateToOffers = () => navigate("/profile/offers");
    const NavigateToHome = () => navigate("/profile/request");
    const NavigateTosubscription = () => navigate("/profile/subscription");
    const NavigateTochat = () => navigate("/profile/chat");
    const NavigateToViewRequests = () => navigate("/profile/viewrequests");
    const NavigateToViewAllRequests = () => navigate("/profile/ViewAllRequests");
    const NavigateToforum = () => navigate("/profile/forum");
    const NavigateTowebsite = () => navigate("/profile/website");
    const NavigateToviewprofile = () => navigate("/profile/viewprofile");
    const NavigateToAllservices = () => navigate("/profile/services");
    const NavigateToAllacategories = () => navigate("/profile/services_rentals");
    const NavigateToAllads = () => navigate("/profile/ads");
    const NavigateToeditprofile = () => navigate("/profile/editprofile");
    const NavigateToChangePicture = () => navigate("/profile/updatepicturepage");
    const NavigateTochangepassword = () => navigate("/profile/changepassword");
    const NavigateToAddOffer = () => navigate("/profile/addoffer");

    const NavigatetoAppliedJobs = () => navigate("/profile/AppliedJobs");
  
    const NavigateToBTBHome = () => navigate("/BlogPage");
    const Navigatetoprivate = () => navigate("/profile/PrivateRequest")
    const NavigatetoOfferHistory = () => navigate("/profile/OfferHistory")
    const NavigatetoContact = () => navigate("/contact")
    useEffect(() => {
      const fetchSession = async () => {
        try {
          const sessionData = await api.getSession();
          if (sessionData.user && sessionData.user.id) {
            setIsLoggedIn(true);
            setIsProfessional(sessionData.user.professional === '1'); // Check if the user is professional
            sessionStorage.setItem("professionalId", sessionData.user.id);
          } else {
            navigate('/professionaluser'); // Redirect to login if session is not valid
          }
        } catch (error) {
          navigate('/professionaluser'); // Redirect to login if there is an error
        }
      };
      fetchSession();
    }, [navigate]);
  
    const NavigateToCard = () => {
      const userId = sessionStorage.getItem("professionalId"); // Retrieve user ID from sessionStorage
      if (isProfessional && userId) {
          navigate(`/profile/businesscard?professional_id=${userId}`); // Navigate with query parameter
      } else {
          console.error("User is not a professional or user ID is missing");
      }
  };
  
 
    useEffect(() => {
      const fetchSubscription = async () => {
        try {
          // First ensure the user is a professional before checking subscription status
          if (isProfessional) {
            const { subscription_exists, status, time_left } = await api.getSubscription(); // Fetch subscription info from the backend
            
            // Set subscription based on the existence flag
            setSubscription(subscription_exists);
            
            // If no subscription exists or it has expired, redirect to the subscription page
            if (!subscription_exists || status === 'expired' || (!time_left && status === 'inactive')) {
              setTimeout(() => {
                navigate('/profile/subscription');
              }, 2000); // 2-second delay before navigating
            }
          }
        } catch (error) {
          console.error('Error fetching subscription:', error);
          setSubscription(false); // Set subscription to false in case of an error
        }
      };
    
      // Only fetch subscription if user is a professional
      if (isProfessional) {
        fetchSubscription();
      }
    }, [isProfessional, navigate]);
    
    const openChatBot = () => {
        setIsChatVisible(true); // Open the ChatBot when the icon is clicked
      };
    
      const closeChatBot = () => {
        setIsChatVisible(false); // Close the ChatBot when needed
      };
    
      // Function to close the ChatBot or Dropdown if clicked outside
      const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            // Close inner dropdowns first
            if (openDropdown) {
                setOpenDropdown(null); // Close inner dropdown
            } else {
                setIsOpen(false); // Close main dropdown
            }
        }
    };
    
    
      // Add event listener to detect outside clicks when ChatBot is visible
      useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick); // Attach event listener
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick); // Cleanup listener
        };
    }, [openDropdown, isOpen]); // Re-run effect when dropdown states change
      const handleLanguageChange = (languageCode) => {
        setSelectedLanguage(languageCode);
        setIsDropdownOpen(false); // Close the dropdown after selection
        // Handle additional logic for language change if needed
    };
    

    const handleLogoClick = () => {
      if (clickTimeout) {
          // If there's already a timeout, it's a double click
          clearTimeout(clickTimeout);
          setClickTimeout(null);
          navigate('/'); // Redirect to the home page
      } else {
          // If no timeout, it's the first click
          const timeout = setTimeout(() => {
              navigate('/BlogPage'); // Redirect to the blogs page on single click
              setClickTimeout(null); // Clear the timeout after execution
          }, 300); // Wait 300ms to determine if it's a single click
          setClickTimeout(timeout);
      }
  };

  
  
    
     
    return (
        <>
<nav className="flex items-center justify-between w-full bg-slate-50 px-4 py-5 fixed top-0 left-0 z-50">
    {/* Logo Section */}
    {/* Logo Section */}
    <button className="text-3xl md:hidden" onClick={toggleMenu}>
                    ☰
                </button>
    <div className="flex items-center">

        <img
            onClick={handleLogoClick}
            src={bloglogo}
            alt="Logo"
            className="h-16 burn cursor-pointer  object-contain ml-5 " 
        />
    </div>
   
            
    
            

            {/* Menu Items */}
            <div
        className={`${
            isMenuOpen ? "flex" : "hidden"
        } flex-col md:flex-row md:flex items-center w-full md:w-auto bg-slate-50 md:bg-transparent absolute md:static top-20 left-0 md:top-auto`}
    > 
    
     <div className="flex justify-center ms-40 w-full  md:hidden">
    <MdCancel
        size={30}
        className="cursor-pointer text-red-500"
        onClick={closeMenu}
    />
</div>

                {/* center */}
                <div className="flex gap-2 md:gap-3 flex-col md:flex-row items-center">
                {subscription && (
                    <div onClick={NavigateToHome} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <FaHome size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Actualité</p>
                    </div>
                  )}


{subscription ? (         
    <div onClick={NavigateToOffers} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
        <RiDiscountPercentFill size={20} />
         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Les pros</p>
    </div>
   ) : null}

                    {/* <div onClick={()=>(setOpenDiv(!openDiv))} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">

                        < MdAddComment  size={20}/>
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Demandes travaux</p>
                    </div> */}
                    {/* <div onClick={NavigateTosubscription} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <IoPersonAdd  />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Tarifs</p>
                    </div> */}
                    <div onClick={NavigateTochat} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <BiSolidMessageSquare  size={20}/>
                         <p  ClassName="    text-[7px]  sm:text-base font-normal text-black hover:text-[#65a406]">Messagerie</p>
                    </div>
                    {subscription ? (
                        <div onClick={NavigateToforum} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                            <MdForum size={20} />
                             <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Forum</p>
                        </div>
                    ) : null}

{!subscription ? (
    <a href="https://btobjob.com/look-for-job.php" className="flex flex-col items-center cursor-pointer hover:text-[#65a406]">
        <MdOutlineMiscellaneousServices size={20} />
        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">
        Déposer un CV
        </p>
    </a>
) : null}
{!subscription ? (
    <a href="https://btobjob.com/boost.php" className="flex flex-col items-center cursor-pointer hover:text-[#65a406]">
  <MdOutlineMiscellaneousServices size={20} />
        <p className="text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">
        Poster un annonce
        </p>
    </a>
) : null}

                    {/* <div onClick={NavigateTowebsite} className="  flex flex-col items-center  hover:cursor-pointer hover:text-[#65a406]">
                        <CgWebsite size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Websites</p>
                    </div>   */}
                    {subscription ? (
                    <div onClick={NavigateToAllads} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                    <FaTrello size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Annonce</p>
                    </div>
                     ) : null}
                    {subscription ? (
                    <div onClick={NavigateToAllacategories} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <BiSolidCategoryAlt size={20} />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Location/Vente</p>
                    </div>
                          ) : null}
                    <div onClick={NavigateToAllservices} className="  flex flex-col items-center hover:cursor-pointer hover:text-[#65a406]">
                        <MdOutlineMiscellaneousServices size={20}  />
                         <p  ClassName="    text-[7px] sm:text-base font-normal text-black hover:text-[#65a406]">Emplois</p>
                    </div>
                    </div>
                </div>
              
                {/* Right */}
                <div className="  flex">
          
                 
                {/* <LanguageDropdown className = 'absolute top-2 right-2'
                            selectedLanguage={selectedLanguage} 
                            onLanguageChange={setSelectedLanguage} // Pass the handler
                        /> */}

        
                   <div className="  flex justify-between border-2 rounded-lg p-1 w-20 mr-5">
                    
                   <div className="  relative">
  <MdOutlineNotifications size={25}
    onClick={() => setopenNotif(!openNotif)}
    className="  text-[#65A406] hover:cursor-pointer"
  />
  {unseenNotifications && (
    <span className="  absolute top-0 right-0 bg-red-500 h-2 w-2 rounded-full" />
  )}
</div>
                <div className='relative' ref={dropdownRef}>
                    <RiContactsLine size={25}
                        className="  text-[#65A406] hover:cursor-pointer"
                        onClick={handleToggle}
                    />
                           {isOpen && (
                        <div
                            className="  absolute right-0  mt-5 w-[180px] md:w-[240px] bg-white rounded-md shadow-md z-10"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                        >
                                   <ul className="  py-7">
                                {/* Close button and other options */}
                                <MdCancel onClick={() => setIsOpen(false)} size={25} color='#75f94c' className="  absolute right-1 top-1" />
                                <li onClick={NavigateToviewprofile}>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <img className="  mr-2 h-7 w-7" src={profileImagePreview || 'default-profile.jpg'} alt="profile" width={24} />
                                                Voir mon profil

                                            </a>
                                        </li>
                                        {/* <li onClick={NavigateToChangePicture}>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaRegImage className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mettre à jour l'image

                                            </a>
                                        </li> */}
                                        {subscription && (
                                        <li onClick={NavigateToCard}>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaRegIdCard className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                carte de visite/flyer

                                            </a>
                                        </li>
                                            )}
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <IoIosNotifications className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Notifications

                                            </a>
                                        </li> */}
                                         {subscription && (
                                            <li>
                                            {/* <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes demandes

                                            </a> */}
                                        </li>
                                        )}
                                        <li>
                                            {/* <a
                                                href="view-requests.php"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidMessageRoundedError className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                consulter votre demande

                                            </a> */}
                                        </li>
                                    
                                        <div className="relative inline-block text-left">
  <button
    onClick={() => toggleDropdown("historique")} // Toggle "historique" dropdown
    className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
  >
    <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
    Historique
    <span className="ml-auto text-gray-500 ms-3" style={{ transform: "rotate(90deg)" }}>
      {">"}
    </span>
  </button>
  {openDropdown === "historique" && (
    <ul className="absolute -left-56 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
      {subscription && (
        <li
          onClick={Navigatetoprivate}
          className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
        >
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
          Mes demandes privées
        </li>
      )}
        {subscription && (
      <li
        onClick={NavigateToViewAllRequests}
        className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
      >
        <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
        Mes demandes/annonces
      </li>
       )}
      {subscription && (
        <li
          onClick={NavigateToViewRequests}
          className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
        >
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
          Demandes reçues
        </li>
      )}
      {subscription && (
        <li
          onClick={NavigatetoOfferHistory}
          className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
        >
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
          Mes services
        </li>
      )}

        <li className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
          <a href="https://btobjob.com/view-ads.php">Mes Annonces</a>
        </li>
      
      {subscription && (
        <li className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
          <a href="https://btobjob.com/view-rental-ads.php">Location/vente</a>
        </li>
      )}
      {subscription && (
        <li className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
          <a href="https://btobjob.com/view-jobs.php">Offres d'emplois</a>
        </li>
      )}

<li className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
          <a href="https://btobjob.com/view-cv-history.php"> Recherche d'emploi</a>
        </li>

        {/* <li  onClick={NavigatetoAppliedJobs} className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <BiSolidMessageRoundedError className="mr-2" color="#75f94c" alt="notification" size={24} />
           Appliqué aux emplois
         </li> */}
     
    </ul>
  )}
</div>




<div className="relative inline-block text-left">
  <button
    onClick={() => toggleDropdown("publier")} // Toggle "publier" dropdown
    className="cursor-pointer ps-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row"
  >
    <FaArrowAltCircleUp className="mr-2" color="#75f94c" alt="notification" size={24} />
    Publier/booster
    <span className="ml-auto text-gray-500 ms-3" style={{ transform: "rotate(90deg)" }}>
      {">"}
    </span>
  </button>
  {openDropdown === "publier" && (
    <ul className="absolute -left-56 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
      <li className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
        <FaArrowAltCircleUp className="mr-2" color="#75f94c" alt="notification" size={24} />
        <a href="https://btobjob.com/boost.php">Déposer une annonce</a>
      </li>
      {subscription && (
        <li className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <FaArrowAltCircleUp className="mr-2" color="#75f94c" alt="notification" size={24} />
          <a href="https://btobjob.com/boost-rental.php">Location ou vente</a>
        </li>
      )}
      {subscription && (
        <li className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <FaArrowAltCircleUp className="mr-2" color="#75f94c" alt="notification" size={24} />
          <a href="https://btobjob.com/boost-job.php">Emplois</a>
        </li>
      )}
      {subscription && (
        <li onClick={NavigateToAddOffer} className="cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center">
          <HiSearchCircle className="mr-2" color="#75f94c" alt="notification" size={24} />
          <a href="#">Ajouter une offre</a>
        </li>
      )}
      
        <li>
          <a
            href="https://btobjob.com/look-for-job.php"
            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center"
          >
            <HiSearchCircle className="mr-2" color="#75f94c" alt="notification" size={24} />
            Poster un CV
          </a>
        </li>
   
    </ul>
  )}
</div>

                                      
                                                 
                                      
                                    
                                       
                        
  
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <PiCurrencyCircleDollarFill className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mon Solde

                                            </a>
                                        </li> */}
                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <BiSolidCoupon className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes Coupouns

                                            </a>
                                        </li> */}
                                        <li onClick={NavigateToeditprofile}>
                                            <a
                                            
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <FaInfoCircle className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Information personnelles

                                            </a>
                                        </li>

                                        <li onClick={()=>(setOpenShare(!openShare))}>
                                            <a
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <IoMdShare className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Partager

                                            </a>
                                        </li>
                                        <li >
                                <a 
                                    href="https://btobjob.com/report.php"
                                    className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaFlag
                                    className="  mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="  flex-grow">Signalement</span>
                                </a>
                                </li>
                                <li>
                                <a href='https://btobjob.com/delete-profile.php' 
                                   
                                    className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaExclamationTriangle 
                                    className="  mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="  flex-grow">Supprimer le profil</span>
                                </a>
                                </li>
                                <li>
                                <button onClick={handleLogout} 
                                   
                                    className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                    <FaSignOutAlt
                                    className="  mr-2 flex-shrink-0" 
                                    color='#75f94c' 
                                    alt="notification" 
                                    size={24} 
                                    />
                                    <span className="  flex-grow">Déconnexion</span>
                                </button>
                                </li>

                                        {/* <li>
                                            <a
                                                href="#"
                                                className="  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex flex-row items-center  "

                                            >
                                                <MdBarChart className="  mr-2" color='#75f94c' alt="notification" size={24} />
                                                Mes Reports

                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            )}
                            
                        </div>
                        
                    </div>
                    
                </div>

            </nav>
   {/* Render the ChatBotIcon at the bottom of the page */}
   <ChatBotIcon onClick={openChatBot} />

{/* Conditionally render the ChatBot based on isChatVisible */}
{isChatVisible && (
  <div
    ref={chatContainerRef} // Reference to ChatBot container
    className="  fixed bottom-0 right-0 w-full h-3/4 p-4 bg-white shadow-lg md:w-1/3 md:h-1/2 z-[9999]"
  >
    <ChatBot onClose={closeChatBot} /> {/* Pass the onClose handler */}
  </div>
)}
{openNotif && (
    <div className="absolute top-10 right-10 z-100">
        <Notifications
            boolean={openNotif}
            returnState={setopenNotif}
            onClearNotifications={clearNotifications} // Pass the handler
        />
    </div>
)}
            <PublicOrPrivate toggleboolean={openDiv} updateState={getDivState}/>
            <ProfileShare boolean={openShare}/>
          

        </>
    )
}


  

export default NavbarProfile; 