import React, { useEffect, useRef } from 'react';

const PrivRequestCompleted = ({ toggleboolean2, onClose }) => {
    const cardRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    if (!toggleboolean2) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black/50">
            <div ref={cardRef} className="max-w-sm mx-auto bg-white p-6 rounded-2xl shadow-lg relative">
                <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16 bg-[#65A406] rounded-full flex items-center justify-center">
                    <svg
                        className="w-8 h-8 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                </div>

                <div className="text-center mt-8">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Votre demande a été soumise avec succès</h2>
                    <p className="text-sm text-gray-600 mb-6">
                    Votre demande a été prise en compte et un professionnel vous contactera sous peu.   
                    </p>
                    <button
                        onClick={onClose}
                        className="w-full py-2 px-2 bg-gradient-to-r from-lime-400 to-[#65A406] text-white font-semibold rounded-md hover:bg-green-700"
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PrivRequestCompleted;
