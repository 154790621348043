import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://btobjob.com/'; // Adjust based on your actual API URL

const PostModal = ({ isOpen, onClose }) => {
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate(); // Hook for navigation

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Allowed MIME types for images and videos, including HEIC and HEVC
    const allowedMimeTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/webp',
      'image/tiff',
      'image/heic',
      'video/mp4',
      'video/webm',
      'video/mov',
      'video/avi',
      'video/mpeg',
      'video/ogg',
      'video/x-matroska',
      'video/hevc',
    ];

    // Filter files based on allowed MIME types
    const filteredFiles = selectedFiles.filter((file) =>
      allowedMimeTypes.includes(file.type)
    );

    setFiles(filteredFiles.slice(0, 5)); // Limit to 5 files
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('text', text);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    try {
      const response = await axios.post(`${API_URL}/create-post.php`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
          console.log(`Progression du téléchargement: ${percentCompleted}%`);
        },
      });

      console.log('Post créé:', response.data);
      alert('publication créé avec succès !');
      onClose();

      // Refresh the page or navigate to another page
      navigate(0); // Refresh the current page
      // Alternatively, navigate to the profile page:
      // navigate('/profile');
    } catch (error) {
      console.error('erreur lors de la création du message:', error);
      alert('Une erreur sest produite lors de la création du message.');
    } finally {
      setIsSubmitting(false);
      setUploadProgress(0); // Reset progress after upload
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
      <div className="bg-white p-4 rounded-xl w-full max-w-lg">
        <h2 className="text-lg mb-2">Créer une nouvelle publication</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            className="w-full border-2 p-2 rounded-md mb-2"
            rows="4"
            placeholder="Quoi de neuf ?"
            value={text}
            onChange={(e) => setText(e.target.value)}
            disabled={isSubmitting}
          />
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full mb-2"
            disabled={isSubmitting}
          />
          <div className="flex flex-wrap gap-2 mb-2">
            {files.map((file, index) => (
              <div key={index} className="relative">
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Fichier sélectionné"
                    className="w-20 h-20 object-cover rounded-md"
                  />
                ) : file.type.startsWith('video/') ? (
                  <video controls className="w-20 h-20 rounded-md">
                    <source src={URL.createObjectURL(file)} type={file.type} />
                    Votre navigateur ne supporte pas la balise vidéo.
                  </video>
                ) : null}
              </div>
            ))}
          </div>
          {uploadProgress > 0 && (
            <p className="text-sm text-gray-500 mb-2">
              Progression du téléchargement : {uploadProgress}%
            </p>
          )}
          <button
            type="submit"
            className="bg-[#65A406] text-white px-4 py-2 rounded-md"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Téléchargement en cours...' : 'Publier'}
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded-md ml-2"
            disabled={isSubmitting}
          >
            Annuler
          </button>
        </form>
      </div>
    </div>
  );
};

export default PostModal;
