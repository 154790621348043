import React from 'react';
import NavbarSection from "../components/Navbar";
import FaqsImg from '../assests/faqsimage (2).png';
import CustomizedInputBase from "../components/Input";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import FooterSection from "../components/Footer";
import Vector1 from '../assests/faqellipse.png';
import Vector2 from '../assests/faqellipse2.png';
import { IoIosSearch } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import './pages.css';

function FaqsPage() {
    return (
        <>
            <NavbarSection />
            <div className="container mx-auto px-4 lg:px-8 flex flex-col lg:flex-row items-center gap-6 mb-0 pb-0 relative">
                 <h3  ClassName="    ml-6 lg:ml-12 text-lg lg:text-3xl font-bold leading-9 lg:leading-10 font-poppins">
                    Besoin d'un coup de main?
                </h3>
                <div className="  relative mt-4 lg:mt-2">
                    <img className="  absolute z-10 left-2 w-20" src={Vector1} alt="Vector 1" />
                    <img className="  absolute z-10 left-16 top-28 w-48" src={Vector2} alt="Vector 2" />
                    <img className="  relative z-20 mt-5 lg:mt-6 ml-1 w-72" src={FaqsImg} alt="FAQs" />
                </div>
            </div>
            <div className="container mx-auto px-4 lg:px-8 my-6">
            <div className="  my-6 mr-7 sm:mr-0">
                {/* <CustomizedInputBase /> */}
                {/* <div className='flex items-center max-w-md justify-between border shadow-xl border-gray-400 ml-6 lg:ml-12 text-xs sm:text-lg bg-white'>
                    <FaSearch className='ml-2 text-gray-500' size={20} />
                    <input type="text" placeholder='Rechercher de l’aide' className='w-full outline-none ml-2 ' />
                    <button className='bg-lime-600 text-white p-2'>Recherche</button>

                </div> */}
            </div>
            <div>
                 <h3  ClassName="    ml-6 lg:ml-12 text-xl lg:text-2xl font-medium font-poppins mt-8">
                    Sujet Populaire
                </h3>
            </div>
            <div className="  mt-8 space-y-6">
                {faqItems.map((item, index) => (
                    <div key={index} className="  bg-[#f5fdee] border shadow-lg text-black mx-6 lg:mx-12 p-4 rounded-lg">
                         <h5  ClassName="    font-bold text-base font-poppins flex justify-between items-center">
                            {item.question}
                            <span className="  text-2xl cursor-pointer">
                                <IoArrowUpCircleOutline />
                            </span>
                        </h5>
                         <p  ClassName="    font-normal text-sm text-gray-600 font-poppins mt-2">
                            {item.answer}
                        </p>
                    </div>
                ))}
            </div>
            </div>
            
            <FooterSection />
        </>
    );
}

const faqItems = [
    {
        question: "Quelle est la mission de btobjob.com?",
        answer: "btobjob.com a pour mission de révolutionner la manière dont les professionnels interagissent et collaborent en connectant les experts de divers domaines pour favoriser une collaboration fructueuse et transparente."
    },
    {
        question: "Quel est le modèle économique de btobjob.com?",
        answer: "btobjob.com propose des abonnements premium pour accéder à des fonctionnalités avancées et à un réseau exclusif de partenaires qualifiés. Les abonnements gratuits permettent la dépose d'offres de sous-traitance et de location de matériel."
    },
    {
        question: "Quelles sont les fonctionnalités disponibles sur btobjob.com?",
        answer: "Sur btobjob.com vous trouverez des rubriques dédiées à l'emploi, à l'apprentissage, aux stages, à la sous-traitance, à la location de matériel et à l'immobilier d'entreprise. Les demandeurs et les fournisseurs de sous-traitance peuvent se connecter, et il est possible de louer des espaces ou des véhicules utilitaires inutilisés."
    },
    {
        question: "Quelles sont les exigences pour s'inscrire en tant que professionnel ou premium sur btobjob.com?",
        answer: "Pour s'inscrire en tant que professionnel ou en version premium, un numéro SIRET ainsi qu'une attestation d'assurance responsabilité civile et/ou décennale en cours de validité sont obligatoires pour garantir une relation de confiance entre les professionnels."
    },
    {
        question: "Quel est l'engagement de btobjob.com en termes de vérifications et de qualité des interactions professionnelles?",
        answer: "btobjob.com s'engage à fournir une plateforme polyvalente facilitant les échanges professionnels, la sous-traitance, la location de matériel et l'immobilier d'entreprise. La vérification des numéros SIRET est essentielle pour assurer le sérieux des entreprises s'inscrivant sur le site, ainsi que des professionnels, afin de garantir la qualité des interactions professionnelles."
    },
    {
        question: "Comment fonctionne la mise en relation des professionnels sur btobjob.com?",
        answer: "La mise en relation des professionnels sur btobjob.com se fait à travers la plateforme en permettant aux demandeurs de sous-traitance de se connecter avec les entreprises offrant des chantiers en sous-traitance, de mettre des offres de chantier en sous-traitance et de répondre à ces offres, ainsi que de louer des espaces ou des véhicules utilitaires inutilisés."
    },
    {
        question: "Quels sont les différents profils de clients et les offres associées sur btobjob.com?",
        answer: "btobjob.com propose différents profils de clients : PROFESSIONNEL PREMIUM: Offre et visibilité incluses à 20€ TTC PARTICULIER: 1 offre par an, visibilité offerte."
    },
    {
        question: "Quels avantages propose btobjob.com pour les plans d'accompagnement et les conseils aux professionnels?",
        answer: "btobjob.com offre des plans d'accompagnement personnalisés pour les professionnels, avec des conseils pratiques et des recommandations adaptés à leurs besoins spécifiques."
    },
    {
        question: "Quelles sont les offres de services disponibles pour la location et la vente sur btobjob.com?",
        answer: "Les offres de services incluent la location de machines, de matériel, de voitures, la vente de biens professionnels et la mise en avant d'offres de chantiers en sous-traitance."
    },
    {
        question: "Comment les utilisateurs peuvent-ils bénéficier des plans tarifaires et des fonctionnalités spéciales sur btobjob.com?",
        answer: "Les utilisateurs peuvent choisir entre les différents plans tarifaires proposés et profiter des fonctionnalités avancées selon leur profil, que ce soit pour la visibilité, les offres spéciales, ou les fonctionnalités personnalisées pour leur activité professionnelle."
    }
];
export default FaqsPage;
