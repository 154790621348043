import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft, FaMapMarkerAlt } from "react-icons/fa";
import { CiWallet, CiCalendar } from "react-icons/ci";
import Share from '../Share';
import MessageModal from '../OfferPage/MessageModal';
import api from '../../services/api';

const RentalAdsDetails = () => {
    const { rentalId } = useParams();
    const navigate = useNavigate();
    const [rentalData, setRentalData] = useState(null);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
 const [lightboxImage, setLightboxImage] = useState(null); 
    useEffect(() => {
        const fetchRentalDetails = async () => {
            try {
                const response = await axios.get('https://btobjob.com/get_rental_ad.php', {
                    params: { id: rentalId },
                    withCredentials: true,
                });
                setRentalData(response.data);
            } catch (error) {
                console.error("Error fetching rental details:", error);
            }
        };
        fetchRentalDetails();
    }, [rentalId]);

    const handleContactClick = () => {
        if (rentalData && rentalData.user_id) {
            navigate(`/profile/specificUser?professional_id=${rentalData.user_id}`);
        }
    };

    const handleSendMessage = async (message) => {
        if (!rentalData?.user_id) {
            console.error("User ID not found for the rental ad.");
            return;
        }

        if (!message || message.trim() === "") {
            console.error("Message content is empty. Please provide a valid message.");
            return;
        }

        try {
            const fullMessage = `(Titre de location/Vente: ${rentalData.title})\n ${message}`; // Add rental title to the message

            const response = await api.sendMessagee({
                receiverId: rentalData.user_id,
                message: fullMessage,
            });

            console.log("Message sent response:", response);

            if (response.success) {
                alert("Message envoyé avec succès !");
                setIsMessageModalOpen(false);
            } else {
                console.error("En cas d'échec, envoyez un message:", response.message);
                alert("En cas d'échec, envoyez un message. s'il te plaît, taray encore.");
            }
        } catch (error) {
            console.error("erreur lors de l'envoi du message:", error);
        }
    };
    const decodeHtml = (html) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };
    const currentUrl = window.location.href;

    if (!rentalData) return <p>Loading...</p>;

    return (
        <div className="max-w-7xl mx-auto p-6 mt-10 bg-gray-100">
            {/* Back Button */}
            <button
                onClick={() => navigate(-1)}
                className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
            >
                <FaArrowLeft className="inline mr-2" /> Retour
            </button>
            {rentalData?.id && (
                <a
                    href={`https://btobjob.com/rental_report.php?rental_id=${rentalData.id}`}
                    className="float-right mb-6 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    Signaler cette annonce
                </a>
            )}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                {/* Title and Share */}
                <div className="p-6 flex justify-between items-center">
                    <h2 className="text-3xl font-bold text-gray-800">{rentalData.title}</h2>
                    <button
                        onClick={() => setOpenShareModal(true)}
                        className="px-4 py-2 bg-lime-500 text-white rounded-full hover:bg-gray-300 transition"
                    >
                        Partager
                    </button>

                    
                </div>

                {/* Details Section */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6">
                    <div className="lg:col-span-2">
                        <h3 className="text-xl font-semibold mb-4">Description de la location</h3>
                        <p className="text-gray-700 mb-6">
    {rentalData.description ? rentalData.description.replace(/\//g, 'non') : 'Aucune description disponible.'}
</p>
                        <div className="flex flex-col gap-y-4">
                            <div className="flex items-center">
                                <CiWallet size={24} color="#65A406" />
                                <span className="ml-2 text-lg font-semibold text-gray-800">{rentalData.display_price || "Demander un devis"} </span>
                            </div>
                            <div className="flex items-center">
                                <CiCalendar size={24} color="#65A406" />
                                <span className="ml-2 text-lg text-gray-500">Créé le : {new Date(rentalData.created_at).toLocaleDateString()}</span>
                            </div>
                            <div className="flex items-center">
                                <FaMapMarkerAlt size={24} color="#65A406" />
                                <span className="ml-2 text-lg font-semibold text-gray-800">{rentalData.address || "Lieu non spécifié"}, {rentalData.city }</span>
                            </div>
                            <div className="p-6 text-center">
                                <button 
                                    onClick={handleContactClick}
                                    className="px-6 py-2 bg-lime-500 text-white font-semibold rounded-lg hover:bg-lime-700 transition"
                                >
                                    Profil
                                </button>
                                <button 
                                    onClick={() => setIsMessageModalOpen(true)}
                                    className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-700 transition ml-4"
                                >
                                    Envoyer un message
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Image Section */}
                    <div className="lg:col-span-1 cursor-pointer">
                        {rentalData.images && rentalData.images.length > 0 ? (
                            rentalData.images.map((image, index) => (
                                <img 
                                    key={index} onClick={() => setLightboxImage(image)}
                                    src={image}
                                    alt={`Image de ${rentalData.title} ${index + 1}`}
                                    className="w-full h-80 object-cover rounded-lg shadow-sm mb-4"
                                />
                            ))
                        ) : (
                            <img 
                                src="fallback-image-url" 
                                alt="Image par défaut" 
                                className="w-full h-80 object-cover rounded-lg shadow-sm"
                            />
                        )}
                    </div>
                </div>
            </div>
            {lightboxImage && (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[99999999]">
        <div className="relative max-w-full max-h-full p-4">
            {/* Close Button */}
            <button
                onClick={() => setLightboxImage(null)}
                className="absolute top-2 right-2 text-white text-xl bg-red-500 rounded-full p-2 hover:bg-red-700"
                aria-label="Close Lightbox"
            >
                ✕
            </button>
            {/* Lightbox Image */}
            <img
                src={lightboxImage}
                alt="Lightbox"
                className="max-w-[90vw] max-h-[90vh] rounded-lg object-contain"
            />
        </div>
    </div>
)}
            {/* Share Modal */}
            {openShareModal && (
                <Share
                    profileUrl={currentUrl}
                    closeModal={() => setOpenShareModal(false)}
                />
            )}

            {/* Message Modal */}
            {isMessageModalOpen && (
                <MessageModal
                    isOpen={isMessageModalOpen}
                    onClose={() => setIsMessageModalOpen(false)}
                    onSend={handleSendMessage}
                />
            )}
        </div>
    );
};

export default RentalAdsDetails;