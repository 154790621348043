import React from 'react';
import User1 from '../assests/people1.png';
import User2 from '../assests/people2.png';
import User3 from '../assests/people3.png';
import User4 from '../assests/people4.png';
import User5 from '../assests/people5.png';
import User6 from '../assests/people6.png';
import User7 from '../assests/people7.png';
import User8 from '../assests/people8.png';
import User9 from '../assests/people9.png';
import User10 from '../assests/people10.png';
import User11 from '../assests/people11.png';
import User12 from '../assests/people12.png';

const Users = () => {
  const names = ['ent immobillier', 'Carrossier Peintre', 'Community Manager', 'Dave', 'Eve', 'Frank', 'Grace', 'Helen', 'Isaac', 'Judy', 'Kyle', 'Lila'];
  
  return (
    <div className="ml-10 my-32 bg-[#FFF]">
        <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12">
          <div className=''><img src={User1} width={50}  height={50} className='ml-4'/><div className='text-xs'>Agent immobilier</div></div>
          <div><img className="users-image-setting ml-4 " src={User2} width={50}  /><div className='text-xs'>Carrossier Peintre</div></div>
          <div><img src={User3} width={50}  height={50} className='ml-4'/><div className='text-xs'>Community Manager</div></div>
          <div><img className="users-image-setting ml-4 " src={User4} width={50}  /><div className='text-xs'>Conciergerie</div></div>
          <div><img src={User5} width={50}  height={50} className='ml-4' /><div className='text-xs'>Architecte d'intérieur</div></div>
          <div><img className="users-image-setting ml-4 " src={User6} width={50}  /><div className='text-xs'>Entreprise de sécurité</div></div>
          <div><img src={User7} width={50}  height={50}  className='ml-4'/><div className='text-xs'>Secrétaire polyvalent</div></div>
          <div><img className="users-image-setting ml-4 " src={User8} width={50}  /><div className='text-xs'>Nettoyeur automobile</div></div>
          <div><img src={User9} width={50}  height={50} className='ml-4'/><div className='text-xs'>Décoratice d'intérieur</div></div>
          <div><img className="users-image-setting ml-4 " src={User10} width={50}  /><div  className='text-xs'>Rénovation tout corps d'État</div></div>
          <div><img src={User11} width={50}  height={50} className='ml-4' /><div className='text-xs'>Chauffer VTC</div></div>
          <div><img className="users-image-setting ml-4 " src={User12} width={50}  /><div className='text-xs'>Traiteur</div></div>
        </div>
    </div>
  );
}

export default Users;
