import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import api from "../../services/api";
import MessageModal from "../OfferPage/MessageModal";

const AppliedJobs = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState(null);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      setLoading(true);
      try {
        const response = await api.getAppliedJobs(page, limit);
        if (response.AppliedJobs) {
          setAppliedJobs(response.AppliedJobs);
          setTotal(response.total);
        } else {
          throw new Error(response.error || "Impossible de récupérer les demandes.");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAppliedJobs();
  }, [page, limit]);

  const totalPages = Math.ceil(total / limit);

  const handleSendMessage = async (message) => {
    if (!selectedProfessionalId) {
      console.error("Identifiant professionnel non trouvé pour la demande.");
      return;
    }

    if (!message.trim()) {
      console.error("Le message est vide.");
      return;
    }

    try {
      const response = await api.sendMessage({
        receiverId: selectedProfessionalId,
        message,
      });
      console.log("Réponse de l'envoi du message :", response);
      navigate(`/profile/chat`);
    } catch (error) {
      console.error("Erreur lors de l'envoi du message :", error);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const openMessageModal = (professionalId) => {
    setSelectedProfessionalId(professionalId);
    setIsMessageModalOpen(true);
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 flex items-center"
      >
        <FaArrowLeft className="mr-2" /> Retour
      </button>
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
        Emplois Appliqués
      </h2>

      {error && <p className="text-center text-red-500">{error}</p>}

      {loading ? (
        <p className="text-center">Chargement...</p>
      ) : (
        <div className="space-y-6">
          {appliedJobs.length === 0 ? (
            <p className="text-center">Aucune demande trouvée.</p>
          ) : (
            appliedJobs.map((job) => (
              <div key={job.id} className="p-4 border border-gray-300 rounded-lg">
                <h3 className="text-lg mb-2"><b>Description :</b> {job.description}</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div><b>Type :</b> {job.job_type}</div>
                  <div><b>Lieu de travail :</b> {job.work_place}</div>
                  <div><b>Salaire :</b> €{job.salary}</div>
                  <div><b>Posté le :</b> {new Date(job.job_posted_date).toLocaleDateString()}</div>
                  <div><b>Expire le :</b> {new Date(job.job_expires_on).toLocaleDateString()}</div>
                  <div><b>Catégorie :</b> {job.category}</div>
                  <div><b>Expérience/Éducation :</b> {job.experience_education}</div>
                  <div><b>Adresse :</b> {job.address}</div>
                  <div><b>Ville :</b> {job.city}</div>
                  <div><b>Genre :</b> {job.gender}</div>
                </div>
                <div className="mt-4 flex gap-4">
                
                </div>
              </div>
            ))
          )}

          <div className="flex justify-center mt-6 space-x-2">
            <button
              onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
              disabled={page === 1}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
            >
              Précédent
            </button>
            <button
              onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages))}
              disabled={page === totalPages}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
            >
              Suivant
            </button>
          </div>
        </div>
      )}

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <img
              src={selectedImage}
              alt="Vue complète"
              className="max-w-screen-sm max-h-screen-md object-contain"
            />
            <button
              onClick={closeImageModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
            >
              Fermer
            </button>
          </div>
        </div>
      )}

      {isMessageModalOpen && (
        <MessageModal
          isOpen={isMessageModalOpen}
          onClose={() => setIsMessageModalOpen(false)}
          onSend={handleSendMessage}
        />
      )}
    </div>
  );
};

export default AppliedJobs;
