import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoShareSocialOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";

import api from '../../services/api';
import CommentList from './CommentList';
import Share from '../Share';

const ForumDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [topic, setTopic] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [openShareModal, setOpenShareModal] = useState(false);

  useEffect(() => {
    if (!id) return;

    const fetchTopic = async () => {
      try {
        const topicResponse = await api.fetchForumTopicById(id);
        setTopic(topicResponse);
      } catch (error) {
        console.error('Erreur lors de la récupération du sujet :', error);
      }
    };

    fetchTopic();
  }, [id]);

  useEffect(() => {
    if (!id) return;

    const fetchComments = async () => {
      try {
        const commentsResponse = await api.fetchComments(id);
        setComments(commentsResponse || []);
      } catch (error) {
        console.error('Erreur lors de la récupération des commentaires :', error);
      }
    };

    fetchComments();

    const interval = setInterval(fetchComments, 2000); // Fetch comments every 2 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [id]);

  const handleCommentSubmit = () => {
    const commentData = { topic_id: id, comment: newComment };

    api.submitComment(commentData)
      .then(response => {
        if (response.success) {
          const newCommentObject = {
            id: response.id,
            comment: newComment,
            professional_name: response.professional_name,
            profile_picture: response.profile_picture,
          };
          setComments(prevComments => [...prevComments, newCommentObject]);
          setNewComment('');
        } else {
          console.error('Erreur lors de l\'ajout du commentaire :', response.message);
        }
      })
      .catch(error => {
        console.error('Erreur lors de l\'ajout du commentaire :', error);
      });
  };

  const handleViewProfile = () => {
    if (topic && topic.professional_id) {
      navigate(`/profile/specificUser?professional_id=${topic.professional_id}`);
    }
  };

  if (!topic) {
    return <p>Chargement...</p>;
  }

  const imagesArray = typeof topic.images === 'string' ? topic.images.split(',') : [];

  const renderImages = imagesArray.length > 0 ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      {imagesArray.map((image, index) => (
        <img
          key={index}
          src={`https://btobjob.com/${image.trim()}`}
          alt={`Image ${index}`}
          className="h-100 w-full object-contain rounded-md"
          onError={(e) => (e.target.src = 'https://btobjob.com/default-placeholder.png')}
        />
      ))}
    </div>
  ) : (
    <p>Aucune image disponible</p>
  );

  const currentUrl = window.location.href;

  return (
    <div className="md:mx-auto m-2 max-w-6xl md:mt-12 rounded-lg">
          <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>
      <div className="bg-white rounded-md p-4">
        <div className="flex items-center mb-6">
          {topic.poster_profile_picture ? (
            <img
              src={topic.poster_profile_picture.trim()}
              alt={`Photo de profil de ${topic.poster_name}`}
              className="w-12 h-12 rounded-full mr-3"
              onError={(e) => (e.target.src = 'https://btobjob.com/profile/default-profile.png')}
            />
          ) : (
            <div className="w-12 h-12 bg-gray-300 rounded-full mr-3" />
          )}
          <div>
            <p className="text-lg font-semibold">{topic.poster_name}</p>
            <p className="text-sm text-gray-500">Publié par</p>
          </div>
          <button
            className="ml-auto px-4 py-2 bg-lime-500 text-white rounded-md"
            onClick={handleViewProfile}
          >
            Voir le profil
          </button>
          <button
            onClick={() => setOpenShareModal(true)}
            className="ml-4 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition"
          >
            <IoShareSocialOutline className="inline mr-2" /> Partager
          </button>
        </div>

        <p className="text-2xl font-bold mb-4">{topic.heading}</p>
        <p className="mb-4">{topic.description}</p>
        {renderImages}

        <div className="mt-6">
          <h3 className="text-xl font-bold mb-2">Commentaires</h3>
          <CommentList comments={comments} />
        </div>

        <div className="mt-4">
          <h4 className="text-lg font-bold mb-2">Ajouter un commentaire</h4>
          <textarea
            className="w-full p-2 border rounded-md"
            rows="4"
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
          />
          <button
            className="mt-2 px-4 py-2 bg-lime-500 text-white rounded-md"
            onClick={handleCommentSubmit}
          >
            Envoyer
          </button>
        </div>
      </div>

      {openShareModal && (
        <Share
          profileUrl={currentUrl}
          closeModal={() => setOpenShareModal(false)}
        />
      )}
    </div>
  );
};

export default ForumDetail;
