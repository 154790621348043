import React, { useState } from 'react';
import './user.css';
import LOGO from '../../assests/websitelogo.png';
import Privacy from './privacy-policy'; // Import your Privacy modal component
import { useNavigate, Link } from "react-router-dom";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import sub1 from '../../assests/sub1.jpg'
import sub2 from '../../assests/sub2.jpg'
import axios from 'axios';

function IndividualSignIn() {
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        kbis: null,
        insurance: null,
        civilLiability: null
    });
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password
    const [isVerifying, setIsVerifying] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null); 
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        setIsVerifying(true); // Set verifying state to true

        const form = new FormData();
        form.append('name', formData.name);
        form.append('email', formData.email);
        form.append('phone', formData.phone);
        form.append('password', formData.password);

        try {
            const response = await axios.post('https://btobjob.com/create-customer.php', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });

            if (response.data.status === "success") {
                alert('Signup successful!');
                localStorage.setItem('email', formData.email);
                window.location.href = 'https://btobjob.com/verify_email.php';
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('There was an error! ' + error.message);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="container max-w-5xl mx-auto bg-white p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
       
            <div className="md:w-1/2 order-2 md:order-1 mr-4">
            {/* <p>Tous les utilisateurs doivent valider et être tenus informés dès leur première inscription, c'est la loi de l'Union européenne.<span className='text-blue-500 cursor-pointer' onClick={() => setPrivacyModalOpen(true)}>Politique de confidentialité</span></p>  */}
                <h4 className='text-2xl font-bold text-gray-800 mb-4'>PARTICULIER - Accès illimité Gratuit:</h4>
                <ul className="list-disc space-y-2 text-gray-600 pl-5">
                <li>Déposer des demandes de travaux et deServices</li>
                <li>Déposer son annonce.</li>
    <li>Déposer son CV.</li>
    <li>Consultation des annonces dans notre rubrique « emplois ».</li>
    <li>Accéder à votre profil.</li>
    <li>Échanger sur notre messagerie.</li>
    <li>Comprendre et s'informer avec notre rubrique « Astuces & Conseils ».</li>
    <li>Se renseigner et poser des questions avec notre assistant IA JOBBY.</li>
                </ul>
                      <div className="flex justify-center items-center gap-4 mt-4">
                                                <img
                                                            src={sub1}
                                                            alt="Subscription 1"
                                                            className="w-1/2 sm:w-1/3 lg:w-1/4 rounded-lg cursor-pointer"
                                                            onClick={() => setLightboxImage(sub1)}
                                                        />
                                                        <img
                                                            src={sub2}
                                                            alt="Subscription 2"
                                                            className="w-1/2 sm:w-1/3 lg:w-1/4 rounded-lg cursor-pointer"
                                                            onClick={() => setLightboxImage(sub2)}
                                                        />
                                    </div>
            </div>
  
         
            <div className="md:w-1/2 bg-[#E1F5C4] p-6 rounded-lg shadow-lg order-1 md:order-2">
                <h4 className='individual-heading'>Créer un compte Particulier</h4>
                <p className='individual-para'>Nous sommes heureux de vous voir</p>
                <form onSubmit={handleSubmit}>
                    <div>
                        <p className='individual-para-input'>Nom et prénom</p>
                        <div className='individual-input-center'>
                            <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='text' name='name' placeholder='Entrez votre nom complet' onChange={handleChange} required />
                        </div>
                    </div>
                    <div>
                        <p className='individual-para-input'>E-mail</p>
                        <div className='individual-input-center'>
                            <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='email' name='email' placeholder='E-mail' onChange={handleChange} required />
                        </div>
                    </div>
                    <div>
                        <p className='individual-para-input'>Numéro de téléphone</p>
                        <div className='individual-input-center'>
                            <input className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none' type='text' name='phone' placeholder='Entrez votre numéro' onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="  relative">
                        <p className='individual-para-input'>Créer un mot de passe</p>
                        <div className='individual-input-center'>
                            <input
                                className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none'
                                type={showPassword ? 'text' : 'password'} // Toggle between text and password
                                name='password'
                                placeholder='Entrez un nouveau mot de passe'
                                onChange={handleChange}
                                required
                            />
                            <span
                                className="  absolute right-4 top-8 cursor-pointer"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FiEyeOff /> : <FiEye />} {/* Toggle eye icon */}
                            </span>
                        </div>
                    </div>
                    <div className="  relative">
                        <p className='individual-para-input'>Confirmez le mot de passe</p>
                        <div className='individual-input-center'>
                            <input
                                className='w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none'
                                type={showConfirmPassword ? 'text' : 'password'} // Toggle confirm password visibility
                                name='confirmPassword'
                                placeholder='Confirmez le mot de passe'
                                onChange={handleChange}
                                required
                            />
                            <span
                                className="  absolute right-4 top-8 cursor-pointer"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <FiEyeOff /> : <FiEye />} {/* Toggle eye icon */}
                            </span>
                        </div>
                    </div>
                    <div className='flex px-2 items-center my-2'>
                        <input type="checkbox" required />
                        <p className='ml-2 text-xs text-[#75C00A] font-semibold flex items-center'>J'accepte toutes les déclarations incluses dans les conditions d'utilisation</p>
                    
                    </div>
                    <div className='flex px-2 items-center my-2'>
                    <p className='text-blue-500 text-sm cursor-pointer' onClick={() => setPrivacyModalOpen(true)}>Politique de confidentialité</p></div>
                    <div className='individual-btn-center'>
                        <button className='sinscrire-individual-btn' type='submit'>{isVerifying ? 'Verifying...' : 'S\'inscrire'}</button>
                    </div>
                </form>
                <p className='individual-or'>ou</p>
                <p className='individual-Vous-paragraph-center'>Vous avez déjà un compte</p>
                <div className='individual-btn-center'>
                    <button className='seconnector-individual-btn border-2 border-lime-700'>
                        <Link className='login-link' to="/login">Se connecter</Link>
                    </button>
                </div>
            </div>
            </div>
            {lightboxImage && (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="relative max-w-full max-h-full p-4">
            {/* Close Button */}
            <button
                onClick={() => setLightboxImage(null)}
                className="absolute top-2 right-2 text-white text-xl bg-red-500 rounded-full p-2 hover:bg-red-700"
                aria-label="Close Lightbox"
            >
                ✕
            </button>
            {/* Lightbox Image */}
            <img
                src={lightboxImage}
                alt="Lightbox"
                className="max-w-[90vw] max-h-[90vh] rounded-lg object-contain"
            />
        </div>
    </div>
)}

            {privacyModalOpen && (
                <Privacy onClose={() => setPrivacyModalOpen(false)} />
            )}
            </div>
            
        
    );
}

export default IndividualSignIn;
