import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoShareSocialOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import Share from '../Share';

const PostDetails = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [likesStatus, setLikesStatus] = useState(false);
    const [totalLikes, setTotalLikes] = useState(0);
    const [comments, setComments] = useState([]);
    const [totalComments, setTotalComments] = useState(0);
    const [newComment, setNewComment] = useState('');
    const [isSubmittingComment, setIsSubmittingComment] = useState(false); 
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState('');
    const [newReply, setNewReply] = useState({});
    const [openShareModal, setOpenShareModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPostDetails = async () => {
            try {
                const response = await axios.get(`https://btobjob.com/fetch-post-details.php?id=${postId}`, { withCredentials: true });
                setPost(response.data);
                setLoading(false);
                fetchComments();
                checkLikeStatus();
            } catch (error) {
                console.error('Error fetching post details:', error);
                setLoading(false);
            }
        };

        fetchPostDetails();
    }, [postId]);
  
    const handleLike = async () => {
        try {
            const response = await axios.post('https://btobjob.com/like-post.php', { postId }, { withCredentials: true });
            setLikesStatus(response.data.liked);
            setTotalLikes(response.data.totalLikes);
        } catch (error) {
            console.error('Error liking the post:', error);
        }
    };

    const handleAddReply = async (commentId) => {
        if (!newReply[commentId]?.trim()) return;
        try {
            const response = await axios.post('https://btobjob.com/add-comment-reply.php', {
                commentId,
                replyText: newReply[commentId]
            }, { withCredentials: true });

            if (response.data.success) {
                setNewReply(prev => ({ ...prev, [commentId]: '' }));
                fetchComments();
            } else {
                console.error('Error adding reply:', response.data.error);
            }
        } catch (error) {
            console.error('Error adding reply:', error);
        }
    };

    const handleAddComment = async () => {
        if (!newComment.trim()) return;
        try {
            const response = await axios.post('https://btobjob.com/add-post-comment.php', {
                postId,
                commentText: newComment
            }, { withCredentials: true });

            if (response.data.success) {
                setNewComment('');
                fetchComments();
            } else {
                console.error('Error adding comment:', response.data.error);
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const fetchComments = async () => {
        try {
            const response = await axios.post('https://btobjob.com/fetch-post-comments.php', {
                postId
            }, { withCredentials: true });

            setComments(response.data.comments || []);
            setTotalComments(response.data.totalComments || 0);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const checkLikeStatus = async () => {
        try {
            const response = await axios.post('https://btobjob.com/check-like.php', { postId }, { withCredentials: true });
            setLikesStatus(response.data.liked);
            setTotalLikes(response.data.totalLikes);
        } catch (error) {
            console.error('Error checking like status:', error);
        }
    };
    const handleDeleteComment = async (commentId) => {
        try {
            const response = await axios.post('https://btobjob.com/delete-comment-post.php', { commentId }, { withCredentials: true });
            if (response.data.success) {
                fetchComments(); // Refresh comments list
                alert('Comment deleted successfully!');
            } else {
                console.error('Error deleting comment:', response.data.error);
                alert('Failed to delete comment: ' + response.data.error);
            }
        } catch (error) {
            console.error('Error deleting comment:', error);
            alert('Error deleting comment: ' + error.message);
        }
    };

    const openLightbox = (imageUrl) => {
        setLightboxImage(imageUrl);
        setIsLightboxOpen(true);
    };

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        setLightboxImage('');
    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (!post) {
        return <div>Publication non trouvée.</div>;
    }

    const currentUrl = window.location.href;

    return (
        <div className="max-w-4xl mx-auto mt-10 px-4">
            {isLightboxOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative max-w-lg max-h-lg">
                        <img 
                            src={lightboxImage} 
                            alt="Lightbox" 
                            className="w-full h-full object-contain"
                        />
                        <button
                            className="absolute top-2 right-2 text-white bg-gray-800 rounded-full p-1"
                            onClick={closeLightbox}
                        >
                            ✕
                        </button>
                    </div>
                </div>
            )}
            <button
                onClick={() => navigate(-1)}
                className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
            >
                <FaArrowLeft className="inline mr-2" /> Retour
            </button>
            <div className="bg-white shadow-lg rounded-lg p-6">
                <div className="flex justify-end mt-4">
                    <button
                        onClick={() => setOpenShareModal(true)}
                        className="flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition"
                    >
                        <IoShareSocialOutline className="mr-2" /> Partager
                    </button>
                </div>
                <div className="flex items-center mb-4">
                    {post.profile_picture && (
                        <img
                            src={post.profile_picture}
                            alt={post.professional_name}
                            className="w-16 h-16 rounded-full object-cover mr-4"
                        />
                    )}
                    <h2 
                        className="text-xl font-bold text-gray-900 cursor-pointer"
                        onClick={() => navigate(`/profile/specificUser?professional_id=${post.user_id}`)}
                    >
                        {post.professional_name || "Professionnel inconnu"}
                    </h2>
                </div>
                <p className="text-gray-600 mb-4">Publié le : {post.created_at}</p>
                <p className="text-gray-800 mb-8">{post.text || "Aucune description fournie."}</p>
                {post.media.length > 0 && (
                    <div className={`mb-4 ${post.media.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
                        {post.media.map((file, index) => (
                            <div key={index} className="relative">
                                {file.type.startsWith('image/') ? (
                                    <img
                                        src={file.url}
                                        alt={`Média de la publication ${index + 1}`}
                                        className="w-full h-full object-cover rounded-md mb-2 cursor-pointer"
                                        onClick={() => openLightbox(file.url)}
                                    />
                                ) : file.type.startsWith('video/') ? (
                                    <video
                                        controlsList="nodownload"
                                        controls disablePictureInPicture
                                        className="w-full h-full object-contain rounded-md mb-2"
                                    >
                                        <source src={file.url} type={file.type} />
                                        Votre navigateur ne prend pas en charge la balise vidéo.
                                    </video>
                                ) : null}
                            </div>
                        ))}
                    </div>
                )}
                <button
                    className={`flex justify-center items-center gap-2 mt-5 px-2 hover:bg-gray-50 rounded-full p-1 ${likesStatus ? 'text-red-600' : 'text-gray-500'}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleLike();
                    }}
                >
                    <svg
                        className={`w-5 h-5 ${likesStatus ? 'fill-current text-red-600' : 'fill-gray-400'}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path d="M12 21.35l-1.45-1.32C6.11 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-4.11 6.86-8.55 11.54L12 21.35z" />
                    </svg>
                    {likesStatus ? 'Aimé' : 'Aimer'}
                    <span>({totalLikes})</span>
                </button>
            </div>

            <div className="mt-6">
                <h3 className="text-lg font-semibold">Commentaires</h3>
                <div className="mt-4 space-y-4">
                    {comments.map((comment) => (
                        <div key={comment.id} className="p-4 bg-gray-100 rounded-lg border">
                            <p 
                                className="text-md font-semibold text-blue-500 cursor-pointer"
                                onClick={() => navigate(`/profile/specificUser?professional_id=${comment.user_id}`)}
                            >
                                {comment.user_name}
                            </p>
                            <p className="text-gray-700">{comment.comment_text}</p>
                            <p className="text-xs text-gray-500 mt-1">{comment.created_at}</p>
                            <a href={`https://btobjob.com/report-comment.php?comment_id=${comment.id}`} className="text-red-500 text-sm mt-2">Signaler</a>
                            {post.is_post_owner && (
                                <button
                                    onClick={() => handleDeleteComment(comment.id)}
                                    className="text-red-500 text-sm mt-2 ms-3"
                                >
                                    Supprimer
                                </button>
                            )}
                            <div className="ml-4 mt-4 space-y-3">
                                {comment.replies && comment.replies.length > 0 && (
                                    comment.replies.map(reply => (
                                        <div key={reply.id} className="p-3 bg-white rounded-lg border border-gray-200">
                                            <p 
                                                className="text-sm font-semibold text-blue-500 cursor-pointer"
                                                onClick={() => navigate(`/profile/specificUser?professional_id=${reply.user_id}`)}
                                            >
                                                {reply.user_name}
                                            </p>
                                            <p className="text-gray-600">{reply.reply_text}</p>
                                            <p className="text-xs text-gray-400 mt-1">{reply.created_at}</p>
                                        </div>
                                    ))
                                )}
                                <div className="mt-2">
                                    <textarea
                                        placeholder="Écrire une réponse..."
                                        value={newReply[comment.id] || ''}
                                        onChange={(e) => setNewReply(prev => ({ ...prev, [comment.id]: e.target.value }))}
                                        className="w-full p-2 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-lime-500"
                                    />
                                    <button onClick={() => handleAddReply(comment.id)} className="mt-2 bg-lime-600 text-white px-4 py-2 rounded-lg">
                                        Répondre
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-6">
                    <textarea
                        placeholder="Ajouter un commentaire..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className="w-full p-2 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-lime-500"
                    />
                    <button onClick={handleAddComment} className="bg-lime-600 text-white px-4 py-2 rounded-lg mt-2">Commenter</button>
                </div>
            </div>

            {openShareModal && (
                <Share
                    profileUrl={currentUrl}
                    closeModal={() => setOpenShareModal(false)}
                />
            )}
        </div>
    );
};

export default PostDetails;
