import React, { useState, useEffect } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BiWorld } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { FaLock } from "react-icons/fa";
import PublicRequest from './RequestPage/PublicRequest';
import api from '../services/api';

function PublicOrPrivate({ toggleboolean, updateState }) {
    const [localToggle, setLocalToggle] = useState(toggleboolean);
    const [openForm, setOpenForm] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const getFormState = (state) => {
        setOpenForm(state);
    };

    useEffect(() => {
        setLocalToggle(toggleboolean);
    }, [toggleboolean]);

    useEffect(() => {
        if (localToggle) {
            console.log('Form has been toggled. Fetching recommendations...');
        }
    }, [localToggle]);

    const navigate = useNavigate();

    const handlePrivateReq = () => {
        setLocalToggle(!localToggle);
        updateState(!localToggle);
        navigate("/profile/offers");
    };

    const handlePublicReq = () => {
        // setLocalToggle(!localToggle);
        // updateState(!localToggle);
        // setOpenForm(!openForm);
        window.location.href = 'https://btobjob.com/boost.php'; // Redirect using plain JavaScript
    };
    
    const handleCancel = () => {
        setLocalToggle(!localToggle);
        updateState(!localToggle);
    };

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const { subscription_exists } = await api.getSubscription();
                setSubscription(subscription_exists);
            } catch (error) {
                console.error('Error fetching subscription:', error);
                setSubscription(false); 
            }
        };

        fetchSubscription();
    }, []);

    return (
        <>
            <div>
                <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-[5000] bg-black/50`}>
                    <div className="  max-w-md bg-white shadow-md rounded-lg p-6 mx-2 sm:mx-auto">
                        <div className='flex items-center justify-between mb-2'>
                            <div className="  text-2xl font-bold">Choisissez une demande</div>
                            <MdOutlineCancel onClick={handleCancel} className="  w-6 h-6" />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div onClick={handlePublicReq} className='flex items-center justify-between border-2 rounded-xl p-2 gap-3'>
                                <BiWorld size={34} />
                                <div className='flex flex-col '>
                                    <p className='text-base font-bold'>Publier une demande publique</p>
                                    <p className='text-sm'>Tous les prestataires pourront répondre à votre demande.</p>
                                </div>
                                <IoIosArrowForward onClick={handlePublicReq} size={34} />
                            </div>
                            {subscription ? (
                                <div onClick={handlePrivateReq} className='flex items-center justify-between border-2 rounded-xl p-2 gap-3'>
                                    <FaLock size={30} className='mr-1' />
                                    <div className='flex flex-col '>
                                        <p className='text-base font-bold'>Trouvez votre professionnel</p>
                                        <p className='text-sm'>Seul le prestataire que vous sélectionnerez pourra vous répondre</p>
                                    </div>
                                    <IoIosArrowForward onClick={handlePrivateReq} size={34} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <PublicRequest toggleboolean={openForm} updateFormState={getFormState} />
        </>
    );
}

export default PublicOrPrivate;
