import React, { useState } from 'react';
import './user.css';
import LOGO from '../../assests/websitelogo.png';
import { Link } from 'react-router-dom';
import Privacy from './privacy-policy'; // Import your Privacy modal component
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons
import sub1 from '../../assests/sub1.jpg'
import sub2 from '../../assests/sub2.jpg'
import Terms from './Terms'; // Import Terms component

function ProfessionalSignup() {
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        siret: '', // Siret Number (Kbis)
        insurance: null,
        civilLiability: null
    });
    const [isVerifying, setIsVerifying] = useState(false);
    const [noInsurance, setNoInsurance] = useState(false);
    const [noCivilLiability, setNoCivilLiability] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
     const [lightboxImage, setLightboxImage] = useState(null); 
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'noInsurance') {
            setNoInsurance(checked);
            if (checked) {
                setFormData({ ...formData, insurance: null });
            }
        } else if (name === 'noCivilLiability') {
            setNoCivilLiability(checked);
            if (checked) {
                setFormData({ ...formData, civilLiability: null });
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        setIsVerifying(true);

        const form = new FormData();
        form.append('name', formData.name);
        form.append('email', formData.email);
        form.append('phone', formData.phone);
        form.append('password', formData.password);
        form.append('siret', formData.siret);
        if (!noInsurance && formData.insurance) form.append('insurance', formData.insurance);
        if (!noCivilLiability && formData.civilLiability) form.append('civil_liability', formData.civilLiability);

        try {
            const response = await axios.post('https://btobjob.com/professional-signup.php', form, {
                withCredentials: true
            });

            if (response.data.status === "success") {
                alert('Inscription réussie !');
                localStorage.setItem('email', formData.email);
                window.location.href = 'https://btobjob.com/verify_email.php';
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert('Il y a eu une erreur ! ' + (error.response ? error.response.data.message : error.message));
        } finally {
            setIsVerifying(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="container max-w-5xl mx-auto bg-white p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-6 md:mb-0 mr-4">
            {/* <p>Tous les utilisateurs doivent valider et être tenus informés dès leur première inscription, c'est la loi de l'Union européenne.<span className='text-blue-500 cursor-pointer' onClick={() => setPrivacyModalOpen(true)}>Politique de confidentialité</span></p>  */}
                <h4 className='text-2xl font-bold text-gray-800 mb-4'>Accès illimité pour les
professionnels avec
abonnement:</h4>
                <ul className="list-disc space-y-2 text-gray-600 pl-5">
                    <li>Déposer ses annonces</li>
                    <li>Consulter les annonces</li>
                    <li>Déposer son CV dans notre rubrique emplois</li>
                    <li>Rechercher un stagiaire/apprentis/salarié</li>
                    <li>Faire sa publicité avec notre rubrique « Les pros »</li>
                    <li>Faire sa communication avec notre carte de visite</li>
                    <li>Apprendre aux autres pros son activité avec notre fil d'actualité</li>
                    <li>Échanger via notre messagerie</li>
                    <li>S'entraider entre pros avec notre forum</li>
                    <li>Comprendre et s'informer avec notre rubrique « Astuces & Conseils »</li>
                    <li>Se renseigner et poser des questions avec notre assistant IA JOBBY</li>
                </ul>
                  <div className="flex justify-center items-center gap-4 mt-4">
                                <img
                                            src={sub1}
                                            alt="Subscription 1"
                                            className="w-1/2 sm:w-1/3 lg:w-1/4 rounded-lg cursor-pointer"
                                            onClick={() => setLightboxImage(sub1)}
                                        />
                                        <img
                                            src={sub2}
                                            alt="Subscription 2"
                                            className="w-1/2 sm:w-1/3 lg:w-1/4 rounded-lg cursor-pointer"
                                            onClick={() => setLightboxImage(sub2)}
                                        />
                    </div>
            </div>
            <div className="md:w-1/2 bg-[#E1F5C4] p-6 rounded-lg shadow-lg">
                <h4 className='text-2xl font-bold text-gray-800 text-center mb-4'>Créer un compte Professionnel</h4>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Nom et prénom / Nom de l'enterprise</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='text' 
                                name='name' 
                                placeholder='Entrez votre nom complet' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>E-mail</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='email' 
                                name='email' 
                                placeholder='E-mail' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Numéro de téléphone</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='text' 
                                name='phone' 
                                placeholder='Entrez votre numéro' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Siret Number (Kbis)</p>
                        <div className='mt-1'>
                            <input className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type='text' 
                                name='siret' 
                                placeholder='Entrez votre numéro de Siret' 
                                onChange={handleChange} 
                                required 
                            />
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Téléchargez votre Assurance Decennal</p>
                        <div className='mt-1'>
                            <div className="  flex items-center space-x-3">
                                
                                <input className='block w-full text-sm text-[#495057] border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:ring-2 focus:ring-[#75C00A]' 
                                    type='file' 
                                    name='insurance' 
                                    onChange={handleChange} 
                                    required={!noInsurance} 
                                    disabled={noInsurance} 
                                />
                            </div>
                            <label className="  ml-2 text-xs text-[#495057] font-semibold mt-2">
                                <input type="checkbox" name="noInsurance" onChange={handleCheckboxChange} /> Je n'ai pas d'assurance
                            </label>
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Téléchargez votre Responsabilité Civile</p>
                        <div className='mt-1'>
                            <div className="  flex items-center space-x-3">
                           
                                <input className='block w-full text-sm text-[#495057] border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:ring-2 focus:ring-[#75C00A]' 
                                    type='file' 
                                    name='civilLiability' 
                                    onChange={handleChange} 
                                    required={!noCivilLiability} 
                                    disabled={noCivilLiability} 
                                />
                            </div>
                            <label className="  ml-2 text-xs text-[#495057] font-semibold mt-2">
                                <input type="checkbox" name="noCivilLiability" onChange={handleCheckboxChange} /> Je n'ai pas de responsabilité civile
                            </label>
                        </div>
                    </div>
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Mot de passe</p>
                        <div className='mt-1 relative'>
                            <input 
                                className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none'
                                type={showPassword ? 'text' : 'password'} 
                                name='password' 
                                placeholder='Entrez votre mot de passe' 
                                onChange={handleChange} 
                                required 
                            />
                            <button type="button" className="  absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5" onClick={togglePasswordVisibility}>
                            {showConfirmPassword ? <FiEyeOff /> : <FiEye />} {/* Toggle eye icon */}
                            </button>
                        </div>
                    </div>

                    {/* Confirm password field */}
                    <div>
                        <p className='text-sm font-medium text-[#495057]'>Confirmer le mot de passe</p>
                        <div className='mt-1 relative'>
                            <input 
                                className='w-full rounded-full p-3 border border-gray-300 text-[#495057] placeholder-[#495057] focus:ring-2 focus:ring-[#75C00A] focus:outline-none' 
                                type={showConfirmPassword ? 'text' : 'password'} 
                                name='confirmPassword' 
                                placeholder='Confirmez votre mot de passe' 
                                onChange={handleChange} 
                                required 
                            />
                            <button type="button" className="  absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5" onClick={toggleConfirmPasswordVisibility}>
                                {showConfirmPassword ? <FiEyeOff /> : <FiEye />} 
                            </button>
                        </div>
                    </div>
                         
                    <div className='flex items-center space-x-2 my-2'>
                        <input type="checkbox" required onClick={() => setShowTermsModal(true)}/>
                        <p className='text-xs text-[#495057] font-semibold'>J'accepte toutes les déclarations incluses dans les <button type="button" className="underline text-red-500" onClick={() => setShowTermsModal(true)}>conditions d'utilisation</button></p>
                    </div>
                    <div className='flex px-2 items-center my-2'>
                    <p className='text-blue-500 text-sm cursor-pointer' onClick={() => setPrivacyModalOpen(true)}>Politique de confidentialité</p></div>
                    <div className='text-center'>
                        <button className='px-6 py-2 rounded-full bg-[#75C00A] text-white text-lg font-semibold hover:bg-[#66A309] transition duration-300' type='submit'>
                            {isVerifying ? 'Verifying...' : "S'inscrire"}
                        </button>
                    </div>
                </form>
                <p className='text-center text-[#495057] mt-4'>ou</p>
                <p className='text-center text-[#495057]'>Vous avez déjà un compte</p>
                <div className='text-center'>
                    <button className='px-6 py-2 mt-4 rounded-full border-2 border-[#75C00A] text-[#75C00A] text-lg font-semibold hover:bg-[#75C00A] hover:text-white transition duration-300'>
                        <Link className='login-link' to="/login-pro">Se connecter</Link>
                    </button>
                </div>

                {showTermsModal && <Terms onClose={() => setShowTermsModal(false)} />} {/* Render Terms modal */}
            </div>
            {lightboxImage && (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="relative max-w-full max-h-full p-4">
            {/* Close Button */}
            <button
                onClick={() => setLightboxImage(null)}
                className="absolute top-2 right-2 text-white text-xl bg-red-500 rounded-full p-2 hover:bg-red-700"
                aria-label="Close Lightbox"
            >
                ✕
            </button>
            {/* Lightbox Image */}
            <img
                src={lightboxImage}
                alt="Lightbox"
                className="max-w-[90vw] max-h-[90vh] rounded-lg object-contain"
            />
        </div>
    </div>
)}

            </div>
            {privacyModalOpen && (
                <Privacy onClose={() => setPrivacyModalOpen(false)} />
            )}
        </div>
        
    );
}

export default ProfessionalSignup;
