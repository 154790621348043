import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://btobjob.com'; // Replace with your actual API URL

const RequestDetails = () => {
  const [request, setRequest] = useState(null); // State to hold request details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();
  const location = useLocation();

  // Get request_id from query params
  const query = new URLSearchParams(location.search);
  const requestId = query.get('request_id');

  useEffect(() => {
    const fetchRequestDetails = async () => {
      if (!requestId) {
        setError('No request ID provided');
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/fetch-request-details.php`, {
          params: { request_id: requestId },
          withCredentials: true,
        });

        if (response.data && response.data.request) {
          setRequest(response.data.request);
        } else {
          setError('Request not found');
        }
      } catch (error) {
        console.error('Error fetching request details:', error);
        setError('Error fetching request details');
      } finally {
        setLoading(false);
      }
    };

    fetchRequestDetails();
  }, [requestId]);

  // Handle redirect to profile
  const viewProfile = () => {
    if (request && request.user_id) {
      navigate(`/profile/specificUser?professional_id=${request.user_id}`);
    }
  };

  return (
    <div className="  max-w-4xl mx-auto p-4">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="  mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="  inline mr-2" /> Back
      </button>

      {/* Loading and Error States */}
      {loading &&  <p  ClassName="    text-center text-gray-500">Loading...</p>}
      {error &&  <p  ClassName="    text-center text-red-500">{error}</p>}
      
      {/* Request Details */}
      {request && (
        <div className="  bg-white shadow-md rounded-lg p-4">

          {/* Professional's Profile Picture and Name at the Top */}
          <div className="  flex items-center justify-center mb-6">
            {request.ProfilePicture && (
              <img
                src={request.ProfilePicture}
                alt="Profile Picture"
                className="  w-24 h-24 object-cover rounded-full mr-4"
              />
            )}
          </div>
        
           <h2  ClassName="    text-xl font-bold mb-2">Posted by:</h2>
           <h1  ClassName="    text-3xl font-bold ">{request.Name}</h1>
          <button
            onClick={viewProfile}
            className="  mt-2 px-2 py-1 bg-lime-500 text-white rounded hover:bg-lime-600 mb-3"
          >
            View Profile
          </button>

          {/* Display multiple images in a grid layout */}
          <div className="  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
            {request.file1 && (
              <img
                src={request.file1}
                alt="File 1"
                className="  w-full h-60 object-cover rounded-md"
              />
            )}
            {request.file2 && (
              <img
                src={request.file2}
                alt="File 2"
                className="  w-full h-60 object-cover rounded-md"
              />
            )}
            {request.file3 && (
              <img
                src={request.file3}
                alt="File 3"
                className="  w-full h-60 object-cover rounded-md"
              />
            )}
          </div>

           <h2  ClassName="    text-2xl font-bold mb-2">{request.request}</h2>
           <p  ClassName="    text-lg text-gray-700 mb-4">Category: {request.category}</p>
           <p  ClassName="    text-lg text-gray-700 mb-4">Price: {request.price} €</p>
           <p  ClassName="    text-sm text-gray-500">Address: {request.address}</p> {/* Show address */}
           <p  ClassName="    text-sm text-gray-500">Time: {request.time}</p> {/* Show time */}
        </div>
      )}
    </div>
  );
};

export default RequestDetails;
    