import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api"; // Adjust the path as necessary
import axios from "axios";

const AddOffer = () => {
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState(""); // State for selected category
  const [city, setCity] = useState(""); // State for selected city
  const [latitude, setLatitude] = useState(null); // Latitude state
  const [longitude, setLongitude] = useState(null); // Longitude state
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // State for loading
  const [cities, setCities] = useState([]); // State for cities

  const navigate = useNavigate();

  // Fetch cities from `fr.json` on component mount
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get("/fr.json"); // Ensure this path matches the file location
        const cityNames = response.data
          .map((city) => city.city)
          .sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" })); // Sort alphabetically
        setCities(cityNames); // Update the cities state
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    fetchCities();
  }, []);

  const fetchCoordinates = async (city) => {
    try {
      const response = await axios.get("https://btobjob.com/geocode.php", {
        params: { q: city },
      });
      if (response.data.lat && response.data.lon) {
        setLatitude(parseFloat(response.data.lat));
        setLongitude(parseFloat(response.data.lon));
        console.log("Coordinates fetched:", response.data);
      } else {
        throw new Error("Coordinates not found for the selected city.");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      alert("Unable to fetch coordinates for the selected city. Try another.");
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleCityChange = async (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);
    if (selectedCity) {
      await fetchCoordinates(selectedCity); // Fetch coordinates for the selected city
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    if (!latitude || !longitude) {
      setError("Coordinates for the selected city are required.");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    const formData = new FormData();
    formData.append("description", description);
    formData.append("image", image);
    formData.append("category", category);
    formData.append("city", city); // Append selected city
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);

    try {
      const response = await api.addOffer(formData);
      setSuccess("Offer added successfully!");
      setDescription("");
      setImage(null);
      setCategory("");
      setCity(""); // Reset city dropdown
      setLatitude(null);
      setLongitude(null);
    } catch (error) {
      setError("Error adding offer. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/profile"); // Navigate back to the profile page or another suitable page
  };

  const categoryOptions = [
    { value: "real_estate_agent", label: "Agent immobilier" },
    { value: "architect_decorator", label: "Architecte/décorateur" },
    { value: "other_mason", label: "Autre Maçon" },
    { value: "auto_body_painter", label: "Carrossier/Peintre" },
    { value: "carpenter_roofer", label: "Charpentier/Couvreur" },
    { value: "heating_refrigeration", label: "Chauffagiste/frigoriste" },
    { value: "driver_delivery", label: "Chauffeur/Livreur" },
    { value: "sales", label: "Commercial" },
    { value: "community_manager", label: "Community manager" },
    { value: "concierge", label: "Conciergerie" },
    { value: "kitchen_specialist", label: "Cuisiniste" },
    { value: "vtc_driver", label: "Chauffeur VTC" }, // New category
    { value: "decoration", label: "Décoration" },
    { value: "moving_services", label: "Déménagement" },
    { value: "electrician", label: "Électricien" },
    { value: "cleaning_company", label: "Entreprise de ménage" },
    { value: "security_company", label: "Entreprise de sécurité" },
    { value: "facade_specialist", label: "Facadier" },
    { value: "trainer", label: "Formateur/formatrice" },
    { value: "pet_sitter", label: "Garde d’animaux" },
    { value: "it_developer", label: "Informaticien/développeur" },
    { value: "gardener", label: "Jardinier" },

    { value: "cleaning", label: "Ménage" }, // New category
    { value: "renovation", label: "Rénovation" }, // New category
    { value: "tiler", label: "Carreleur" }, // New category
    { value: "catering", label: "Traiteur" }, // New category
  
    { value: "maintenance_handyman", label: "Maintenance/Bricolage" },
    { value: "masonry", label: "Maçonnerie" },
    { value: "handling_clearance", label: "Manutention/débarras" },
    { value: "mechanic", label: "Mécanicien" },
    { value: "carpenter", label: "Menuisier" },
    { value: "secretarial", label: "Métier de secrétariat" },
    { value: "car_cleaning", label: "Nettoyage automobile" },
    { value: "window_cleaning", label: "Nettoyage de vitres" },
    { value: "event_organizer", label: "Organisateur d’évènement" },
    { value: "landscaper_gardener", label: "Paysagiste/jardinier" },
    { value: "painter", label: "Peintre" },
    
    { value: "painter_decorator", label: "Peintre/décorateur" },
    { value: "plasterer", label: "Plaquiste/plâtrier" },
    { value: "plumber", label: "Plomberie" },
    { value: "pool_installer", label: "Poseur de piscine" },
    { value: "electronics_repair", label: "Réparateur électronique" },
    { value: "restaurant", label: "Restauration" },
    { value: "locksmith", label: "Serrurier/dépannage" },
    { value: "personal_services", label: "Services à la personne" },
    { value: "earthwork", label: "Terrassement" },
  ];
  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
        Ajouter une offre
      </h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex flex-col">
          <label
            htmlFor="city"
            className="mb-2 text-lg font-medium text-gray-600"
          >
            Ville
          </label>
          <select
            id="city"
            value={city}
            onChange={handleCityChange}
            className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Sélectionnez une ville</option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="description"
            className="mb-2 text-lg font-medium text-gray-600"
          >
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="5"
            required
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="image"
            className="mb-2 text-lg font-medium text-gray-600"
          >
            Photos
          </label>
          <input
  type="file"
  id="media"
  onChange={handleImageChange} // Reuse the same handler for simplicity
  accept="image/*,video/*"
  className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
  required
/>

        </div>
        <div>
  {image && (
    <div className="mt-4">
      <p>Preview:</p>
      {image.type.startsWith("image/") ? (
        <img
          src={URL.createObjectURL(image)}
          alt="Preview"
          className="max-h-64"
        />
      ) : (
        <video
          src={URL.createObjectURL(image)}
          controls
          className="max-h-64"
        />
      )}
    </div>
  )}
</div>

        <div className="flex flex-col">
        <label
          htmlFor="category"
          className="mb-2 text-lg font-medium text-gray-600"
        >
          Catégorie
        </label>
        <select
          id="category"
          value={category}
          onChange={handleCategoryChange}
          className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        >
          <option value="">Sélectionnez une catégorie</option>
          {categoryOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        </div>
       
        <div className="flex gap-4">
          <button
            type="submit"
            className={`w-full py-2 rounded-lg shadow-md text-white transition duration-300 ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-lime-500 hover:bg-blue-600"
            }`}
            disabled={loading}
          >
            {loading ? "En cours..." : "Soumettre"}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="w-full bg-gray-500 text-white py-2 rounded-lg shadow-md hover:bg-gray-600 transition duration-300"
          >
            Annuler
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddOffer;
