import React, { useEffect, useState } from 'react';
import User2 from "../../assests/samuels.png";
import User from "../../assests/people12 - Copy.png";
import User3 from "../../assests/people2.png";
import { FaArrowLeft } from "react-icons/fa";
import room from "../../assests/roomimage.jpg";
import { useNavigate, useLocation } from 'react-router-dom';
import { RxCrossCircled } from "react-icons/rx";
import PrivateRequestForm from './PrivateRequestForm';
import axios from 'axios';
import SearchableDropdown from '../SearchableDropdown';

const API_URL = 'https://btobjob.com';
const categoryMap = [
    { value: "real_estate_agent", label: "Agent immobilier" },
    { value: "architect_decorator", label: "Architecte/décorateur" },
    { value: "other_mason", label: "Autre Maçon" },
    { value: "auto_body_painter", label: "Carrossier/Peintre" },
    { value: "carpenter_roofer", label: "Charpentier/Couvreur" },
    { value: "heating_refrigeration", label: "Chauffagiste/frigoriste" },
    { value: "driver_delivery", label: "Chauffeur/Livreur" },
    { value: "sales", label: "Commercial" },
    { value: "community_manager", label: "Community manager" },
    { value: "concierge", label: "Conciergerie" },
    { value: "kitchen_specialist", label: "Cuisiniste" },
    { value: "decoration", label: "Décoration" },
    { value: "moving_services", label: "Déménagement" },
    { value: "electrician", label: "Électricien" },
    { value: "cleaning_company", label: "Entreprise de ménage" },
    { value: "security_company", label: "Entreprise de sécurité" },
    { value: "facade_specialist", label: "Facadier" },
    { value: "trainer", label: "Formateur/formatrice" },
    { value: "pet_sitter", label: "Garde d’animaux" },
    { value: "it_developer", label: "Informaticien/développeur" },
    { value: "gardener", label: "Jardinier" },
    { value: "maintenance_handyman", label: "Maintenance/Bricolage" },
    { value: "masonry", label: "Maçonnerie" },
    { value: "handling_clearance", label: "Manutention/débarras" },
    { value: "mechanic", label: "Mécanicien" },
    { value: "carpenter", label: "Menuisier" },
    { value: "secretarial", label: "Métier de secrétariat" },
    { value: "car_cleaning", label: "Nettoyage automobile" },
    { value: "window_cleaning", label: "Nettoyage de vitres" },
    { value: "event_organizer", label: "Organisateur d’évènement" },
    { value: "landscaper_gardener", label: "Paysagiste/jardinier" },
    { value: "painter", label: "Peintre" },
    { value: "painter_decorator", label: "Peintre/décorateur" },
    { value: "plasterer", label: "Plaquiste/plâtrier" },
    { value: "plumber", label: "Plomberie" },
    { value: "pool_installer", label: "Poseur de piscine" },
    { value: "electronics_repair", label: "Réparateur électronique" },
    { value: "restaurant", label: "Restauration" },
    { value: "locksmith", label: "Serrurier/dépannage" },
    { value: "personal_services", label: "Services à la personne" },
    { value: "earthwork", label: "Terrassement" },
    { value: "cleaning", label: "Ménage" }, // New category
    { value: "renovation", label: "Rénovation" }, // New category
    { value: "vtc_driver", label: "Chauffeur VTC" }, // New category
    { value: "tiler", label: "Carreleur" }, // New category
    { value: "catering", label: "Traiteur" }, // New category
];

const getFrenchCategory = (value) => {
    const match = categoryMap.find((category) => category.value === value);
    return match ? match.label : "Toutes les catégories";
};
const Allthemes2 = () => {
    const [ads, setAds] = useState([]); // Default to an empty array to avoid undefined errors
    const [suggestions, setSuggestions] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [clearInput, setClearInput] = useState(false);

    const [radius, setRadius] = useState('10');
    const [cities, setCities] = useState([]);
    const [searchTheme, setSearchTheme] = useState('');
    const [loading, setLoading] = useState(false);
    const [isCityLoading, setIsCityLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [noResultsMessage, setNoResultsMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const itemsPerPage = 12;

    // Extract category from the URL
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get('category');

    useEffect(() => {
        const fetchInitialCities = async () => {
            try {
                setIsCityLoading(true);
                const response = await axios.get('https://geo.api.gouv.fr/communes', {
                    params: {
                        fields: 'nom',
                        format: 'json',
                        geometry: 'centre',
                        limit: 100,
                    },
                });
                const cityNames = response.data.map((region) => region.nom).sort();
                setCities(cityNames);
            } catch (error) {
                console.error('Error fetching cities:', error);
            } finally {
                setIsCityLoading(false);
            }
        };
        fetchInitialCities();
    }, []);
    
    const fetchCitySuggestions = async (query) => {
        try {
            if (query.length < 3) return; // Fetch only if query is 3+ characters
            setIsCityLoading(true);
            const response = await axios.get('https://geo.api.gouv.fr/communes', {
                params: {
                    fields: 'nom',
                    format: 'json',
                    geometry: 'centre',
                    nom: query,
                    limit: 100,
                },
            });
            const citySuggestions = response.data.map((region) => region.nom).sort();
            setCities(citySuggestions);
        } catch (error) {
            console.error('Error fetching city suggestions:', error);
        } finally {
            setIsCityLoading(false);
        }
    };

    const fetchCoordinates = async (city) => {
        try {
            const response = await axios.get(`${API_URL}/geocode.php`, {
                params: { q: city, format: 'json', addressdetails: 1 },
            });
            if (response.data.lat && response.data.lon) {
                return { lat: parseFloat(response.data.lat), lon: parseFloat(response.data.lon) };
            }
            throw new Error('City not found');
        } catch (error) {
            console.error('Error fetching coordinates:', error);
            alert('Unable to fetch coordinates for the city.');
            return null;
        }
    };
    const frenchCategory = getFrenchCategory(category); 
    const fetchOffers = async () => {
        setLoading(true);
        try {
            const params = {
                page: currentPage,
                itemsPerPage,
                search: searchTheme || undefined,
                radius: radius || undefined,
                location: selectedCity || undefined,
                latitude: latitude || undefined,
                longitude: longitude || undefined,
                category: category || undefined, // Pass category from the URL
            };

            const { data } = await axios.get(`${API_URL}/get-offers-by-cat.php`, {
                params,
                withCredentials: true,
            });

            if (data && data.results && Array.isArray(data.results.ads)) {
                setAds((prev) =>
                    currentPage === 1 ? data.results.ads : [...prev, ...data.results.ads]
                );
                setHasMore(data.results.ads.length > 0);
                setNoResultsMessage(
                    currentPage === 1 && data.results.ads.length === 0
                        ? data.results.message || `Aucun résultat disponible pour ${selectedCity}`
                        : ''
                );
            } else {
                setHasMore(false);
                setNoResultsMessage(`Aucun résultat disponible pour ${selectedCity}`);
            }
        } catch (error) {
            console.error('Error fetching ads:', error);
            setHasMore(false);
            setNoResultsMessage(`Error fetching data for ${selectedCity}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOffers();
    }, [currentPage, radius, searchTheme, latitude, longitude, category]);

    const handleItemClick = (id) => {
        navigate(`/profile/AdsDetails?ad_id=${id}`);
    };

    const handleUseCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    setSelectedCity('');
                    setCurrentPage(1);
                    fetchOffers();
                },
                (error) => {
                    console.error('Error fetching location:', error);
                    alert('Unable to fetch your current location.');
                }
            );
        } else {
            alert('jolocation nest pas supporté par votre navigateur.');
        }
    };

    const handleSearchByCity = async () => {
        if (selectedCity) {
            setIsCityLoading(true);
            const coordinates = await fetchCoordinates(selectedCity);
            if (coordinates) {
                setLatitude(coordinates.lat);
                setLongitude(coordinates.lon);
                fetchOffers();
            }
            setIsCityLoading(false);
        } else {
            alert('Veuillez sélectionner la ville avant de rechercher.');
        }
    };
    const clearFilters = () => {
        setSearchTheme('');
        setSelectedCity('');
        setLatitude(null);
        setLongitude(null);
        setRadius('10'); // Default radius
        setCurrentPage(1); // Reset to the first page
        setClearInput(true); // Trigger input clearing for SearchableDropdown
    
        // Reset clearInput state after clearing
        setTimeout(() => setClearInput(false), 0);
    
        fetchOffers(); // Reload data with default filters
    };
    
    return (
        <div className="container mx-auto px-4 py-6">
              <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>
            <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">Offres dans {frenchCategory}</h1>
                <button
                    className="bg-gray-800 text-white px-3 py-2 rounded-md"
                    onClick={() => setShowFilters((prev) => !prev)}
                >
                        ☰ Filtres
                </button>
            </div>

            {showFilters && (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
                    <div className="flex flex-col">
                        <label className="text-sm font-semibold mb-1"> Rayon (km): {radius || 'Tous'}</label>
                        <input
                            type="number"
                            min="0"
                            max="100"
                            value={radius}
                            onChange={(e) => setRadius(e.target.value)}
                            className="w-full px-2 py-1 border rounded"
                            onWheel={(e) => e.target.blur()} // Disable scroll increment/decrement
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="text-sm font-semibold mb-1">Recherche par mots-clés:</label>
                        <input
                            type="text"
                            value={searchTheme}
                            onChange={(e) => setSearchTheme(e.target.value)}
                            className="w-full px-2 py-1 border rounded"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="text-sm font-semibold mb-1">Sélectionnez une ville:</label>
                        <SearchableDropdown
                            options={cities}
                            selectedOption={selectedCity}
                            clearInput={clearInput} 
                            setSelectedOption={setSelectedCity}
                            fetchSuggestions={fetchCitySuggestions}
                            placeholder="Sélectionnez la ville"
                        />
                    </div>
                    <div className="flex flex-col">
                     
                        <button
                            className={`bg-green-500 text-white px-2 py-6 rounded-md  ${
                                isCityLoading ? 'opacity-70' : ''
                            }`}
                            onClick={handleSearchByCity}
                            disabled={isCityLoading}
                        >
                            {isCityLoading ? 'Chargement...' : 'Rechercher'}
                        </button>
                        </div>
                        <div className="flex flex-col">
                <button
                    className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
                    onClick={clearFilters}
                >
                    Réinitialiser les filtres
                </button>
                <button
                            className="bg-blue-500 mt-2 text-white px-2 py-1 rounded-md"
                            onClick={handleUseCurrentLocation}
                        >
                            Géolocalisation de ma position
                        </button>
            </div>
                    </div>
                
            )}

            {noResultsMessage && (
                <div className="text-center text-lg font-semibold text-red-500 mt-6">
                    {noResultsMessage}
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Array.isArray(ads) && ads.length > 0 ? (
                    ads.map((ad) => (
                        <Card
                            key={ad.id} // Use a unique key, e.g., ad.id
                            ad={ad} // Pass the ad as ad for the Card component
                        />
                    ))
                ) : (
                    <p className="text-center text-gray-500">Aucune annonce disponible.</p>
                )}
            </div>
        </div>
    );
};

const Card = ({ ad }) => {
    const navigate = useNavigate();
    const [openForm, setOpenForm] = useState(false);

    const handleNavigateToProfile = () => {
        navigate(`/profile/specificUser?professional_id=${ad.professional_id}`);
    };

    return (
        <div className="w-96 bg-white rounded-2xl overflow-hidden shadow-2xl border-2">
            <div className="p-4">
                <div className="flex items-center">
                    <img
                        className="w-12 h-12 rounded-full"
                        src={ad.profile_picture || 'https://via.placeholder.com/150'}
                        alt="Professional"
                    />
                    <div className="ml-4">
                  
                        <div className="text-gray-600">{ad.professional_phone}</div>
                        <div className="text-xl semibold">{ad.professional_name}</div>
                        <div className="text-gray-500"> {ad.city}</div>
                    </div>
                    <a
                        href={`https://btobjob.com/report_offer.php?offer_id=${ad.id}&category=${ad.category}`}
                        className="bg-red-500 flex-right justify-end text-white px-2 py-1 text-sm rounded-md hover:bg-red-600"
                    >
                        Signaler
                    </a>
                </div>
                <div className="mt-4">
                    <img
                        className="w-full h-48 object-cover"
                        src={ad.image || 'https://via.placeholder.com/300'}
                        alt="Ad"
                    />
                </div>
                <div className="text-gray-600">{ad.created_at}</div>
                <div className="mt-4 text-gray-700">{ad.description}</div>
                <div className="mt-4 flex justify-between">
                    <button
                        onClick={() => setOpenForm(true)}
                        className="bg-[#65A406] text-white px-4 py-2 rounded-md"
                    >
                        Demande privée
                    </button>
                    <button
                        onClick={handleNavigateToProfile}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                    >
                        Voir le profil
                    </button>
                </div>
           
            </div>
            {openForm && <PrivateRequestForm boolean={true} professionalId={ad.professional_id} />}
        </div>
    );
};

export default Allthemes2;
