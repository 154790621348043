import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import api from "../../services/api";
import MessageModal from "../OfferPage/MessageModal";

const PrivateRequest = () => {
  const [privateRequests, setPrivateRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState(null);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrivateRequests = async () => {
      setLoading(true);
      try {
        const response = await api.getPrivateRequests(page, limit);
        if (response.privateRequests) {
          setPrivateRequests(response.privateRequests);
          setTotal(response.total);
        } else {
          throw new Error(response.error || "Impossible de récupérer les demandes.");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPrivateRequests();
  }, [page, limit]);

  const totalPages = Math.ceil(total / limit);

  const handleSendMessage = async (message) => {
    if (!selectedProfessionalId) {
      console.error("Professional ID not found for the request");
      return;
    }

    if (!message.trim()) {
      console.error("Message is empty");
      return;
    }

    try {
      const response = await api.sendMessagee({
        receiverId: selectedProfessionalId,
        message,
      });
      console.log("Message sent response:", response);
      navigate(`/profile/chat`);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const openMessageModal = (professionalId) => {
    setSelectedProfessionalId(professionalId);
    setIsMessageModalOpen(true);
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
      <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-700">
        Mes demandes privées
      </h2>

      {error && <p className="text-center text-red-500">{error}</p>}

      {loading ? (
        <p className="text-center">Chargement...</p>
      ) : (
        <div className="space-y-6">
          {privateRequests.length === 0 ? (
            <p className="text-center">Aucune demande privée trouvée.</p>
          ) : (
            privateRequests.map((request) => (
              <div key={request.id} className="p-4 border border-gray-300 rounded-lg">
                <h3 className="text-lg mb-2">
                  <b>Description : </b>
                  {request.description}
                </h3>
                <div className="flex justify-between mb-4">
                  <div className="flex flex-col">
                    <span className="font-semibold">Catégorie :</span>
                    <span>{request.category}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Prix :</span>
                    <span>{request.price} €</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Adresse :</span>
                    <span>{request.address}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">Temps :</span>
                    <span>{request.created_at}</span>
                  </div>
                </div>
                <div className="flex gap-4">
                  {request.file1 && (
                    <img
                      src={`https://btobjob.com/requests/${request.file1}`}
                      alt="Fichier 1"
                      className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                      onClick={() =>
                        handleImageClick(`https://btobjob.com/requests/${request.file1}`)
                      }
                    />
                  )}
                  {request.file2 && (
                    <img
                      src={`https://btobjob.com/requests/${request.file2}`}
                      alt="Fichier 2"
                      className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                      onClick={() =>
                        handleImageClick(`https://btobjob.com/requests/${request.file2}`)
                      }
                    />
                  )}
                  {request.file3 && (
                    <img
                      src={`https://btobjob.com/requests/${request.file3}`}
                      alt="Fichier 3"
                      className="w-20 h-20 object-cover rounded-lg cursor-pointer"
                      onClick={() =>
                        handleImageClick(`https://btobjob.com/requests/${request.file3}`)
                      }
                    />
                  )}
                </div>

                <div className="mt-4 flex gap-4">
                  <button
                    onClick={() => openMessageModal(request.professional_id)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Envoyer un message
                  </button>
                  <button
                    onClick={() => navigate(`/profile/specificUser?professional_id=${request.professional_id}`)}
                    className="px-4 py-2 bg-lime-500 text-white rounded hover:bg-blue-600 transition duration-300"
                  >
                    Voir le profil
                  </button>
                </div>
              </div>
            ))
          )}

          <div className="flex justify-center mt-6 space-x-2">
            <button
              onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
              disabled={page === 1}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
            >
              Précédent
            </button>
            <button
              onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages))}
              disabled={page === totalPages}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
            >
              Suivant
            </button>
          </div>
        </div>
      )}
{selectedImage && (
  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[99999999]">
    <div className="bg-white p-4 rounded-lg shadow-lg max-w-full max-h-full">
      <img
        src={selectedImage}
        alt="Full View"
        className="w-auto h-auto max-w-[88vw] max-h-[88vh] object-contain"
      />
      <button
        onClick={closeImageModal}
        className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
      >
        Fermer
      </button>
    </div>
  </div>
)}

      {isMessageModalOpen && (
        <MessageModal
          isOpen={isMessageModalOpen}
          onClose={() => setIsMessageModalOpen(false)}
          onSend={handleSendMessage}
        />
      )}
    </div>
  );
};

export default PrivateRequest;
