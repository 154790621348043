import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";
import NavbarSection from "../components/Navbar";
import FooterSection from "../components/Footer";
import LOGO2 from '../assests/blog_logo.png'
const BlogDetail = () => {
    const { postId } = useParams(); // Extract the post ID from the URL
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchPost = async () => {
            try {
                // Fetch post data from the backend
                const response = await axios.get(`https://btobjob.com/get_blog_details.php?id=${postId}`);
                setPost(response.data.post); // Set the retrieved post data
                setLoading(false); // Stop loading state
            } catch (err) {
                // Log the error to the console for debugging
                console.error("Error fetching blog post:", err);

                // Update the error state to display a message
                setError("Error fetching the blog post.");
                setLoading(false);
            }
        };
        fetchPost();
    }, [postId]); // Dependency array includes postId to re-run on ID change

    // Loading state
    if (loading) {
        return <p className="text-center mt-10">Loading...</p>;
    }

    // Error state
    if (error) {
        return <p className="text-center mt-10 text-red-500">{error}</p>;
    }

    // Render blog post
    return (
        <>
            <NavbarSection />
            <div className="container mx-auto px-4 py-8">
            <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>
      
      <div className="flex flex-col justify-center items-center mb-8 p-4 rounded-3xl shadow-lg">
        <img
            src={LOGO2}
            alt="Logo"
            className="h-48 w-auto object-contain mb-4" // Increased size and centered
        />
        </div>
                <div className="bg-white p-6 rounded-lg shadow-md">
                    {/* Blog Title */}
                    <h1 className="text-4xl font-bold mb-4">{post.title}</h1>

                    {/* Blog Date */}
                    <p className="text-gray-600 mb-4">
                        {new Date(post.created_at).toLocaleDateString()}
                    </p>

                    {/* Blog Featured Image */}
                    {post.featured_image && (
                        <img
                            src={post.featured_image}
                            alt={post.title}
                            className="w-full h-auto rounded-lg mb-6"
                        />
                    )}

                    {/* Blog Content */}
                    <div
                        className="text-gray-800 leading-relaxed"
                        dangerouslySetInnerHTML={{ __html: post.description }}
                    />
                </div>
            </div>
            <FooterSection />
        </>
    );
};

export default BlogDetail;
