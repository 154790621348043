import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import photo from "../assests/hero-image.png";
import vd from "../assests/hero_video.mp4";

const CenteredLogoWithParagraphs = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
        try {
            const response = await api.getSession();
            if (response.user) {
                setUser(response.user);
            } else {
                setUser(null);
            }
        } catch (error) {
            console.error('Error fetching session:', error);
            setUser(null);
        }
    };

    fetchSession();
  }, []);

  return (
    <div className="relative w-full lg:h-screen flex flex-col justify-start items-center bg-[#fff]">
      <div className='flex flex-row justify-between items-center w-[80%]'>
        {user ? (
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-700">Bonjour, {user.name}!</span>
            {/* Optional: Add a logout button or additional user links here */}
          </div>
        ) : (
          <>
            <button className="hidden md:flex professional-navbar">
              <Link className="professional-navbar-link text-sm translatable-text" to="/login">Se Connecter Particulier</Link>
            </button>
            <button className="hidden md:flex professional-navbar">
              <Link className="professional-navbar-link text-sm translatable-text" to="/login-pro">Se Connecter Professionnel</Link>
            </button>
          </>
        )}
      </div>

      <div className='media-container flex items-center justify-center'>
        <video className='video w-[50%] mb-20' autoPlay loop muted>
          <source src={vd} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img className='image w-[100%] mb-10' src={photo} alt="Alternative Image" />
      </div>
    </div>
  );
};

export default CenteredLogoWithParagraphs;
