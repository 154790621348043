import React, { useState, useEffect } from 'react';
import joby from '../assests/joby.gif'; // Ensure the correct path

const ChatBotIcon = ({ onClick }) => {
  const [showMessage, setShowMessage] = useState(false); // Default state is false, checked on mount

  // Function to check if the message should be shown
  const checkMessageVisibility = () => {
    const lastHiddenTimestamp = localStorage.getItem('chatbotMessageHiddenTime');
    if (lastHiddenTimestamp) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(lastHiddenTimestamp, 10);
      const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds

      // If less than an hour has passed, keep it hidden
      if (elapsedTime < oneHour) {
        return false;
      }
    }
    // Otherwise, show the message
    return true;
  };

  // On component mount, determine if the message should be shown
  useEffect(() => {
    setShowMessage(checkMessageVisibility());
  }, []);

  const handleCloseMessage = () => {
    // Hide the message
    setShowMessage(false);

    // Store the timestamp when the message was hidden
    localStorage.setItem('chatbotMessageHiddenTime', new Date().getTime().toString());
  };

  return (
    <>
      {/* Icon for larger screens */}
      <div className="fixed bottom-5 right-5 flex items-center space-x-2 z-[9999] sm:flex hidden">
        {/* Conditional Rendering for French Message */}
        {showMessage && (
          <div className="relative bg-blue-100 text-blue-900 px-4 py-2 rounded-lg shadow-lg font-xl flex items-center">
            <p className="mt-2">Cliquez pour ouvrir le chatbot!</p>
            {/* Close Button */}
            <button
              onClick={handleCloseMessage}
              className="absolute top-0 right-0 bg-transparent text-blue-900 hover:text-blue-600 text-lg font-bold px-2"
            >
              ×
            </button>
          </div>
        )}

        {/* ChatBot Icon */}
        <div
          onClick={onClick}
          className="bg-white p-4 rounded-full shadow-lg cursor-pointer"
        >
          <img
            src={joby}
            alt="ChatBot Icon"
            className="w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20"
          />
        </div>
      </div>

      {/* Icon for small screens */}
      <div className="fixed top-10 right-72 z-[9999] sm:hidden flex">
        <div
          onClick={onClick}
          className="cursor-pointer"
        >
          <img
            src={joby}
            alt="ChatBot Icon Small"
            className="w-10 h-10" // Smaller size for small screens
          />
        </div>
      </div>
    </>
  );
};

export default ChatBotIcon;