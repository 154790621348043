import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import icons from React Icons
import api from '../../services/api';
import './user.css';
import Privacy from './privacy-policy'; // Import your Privacy modal component
import LOGO2 from '../../assests/b2bjob logo 1.png';
import LoginVector from '../../assests/loginVector.png';
import sub1 from '../../assests/sub1.jpg'
import sub2 from '../../assests/sub2.jpg'
function LoginPro() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [lightboxImage, setLightboxImage] = useState(null); 
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const navigate = useNavigate();

    const handleForgotPasswordClick = () => {
        window.location.href = 'https://btobjob.com/forgot_password_pro.php';
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await api.LoginPro(email, password);
            if (response.redirect) {
                window.location.href = response.redirect; // Redirect to the activation or verification page
            } else if (response.user) {
                localStorage.setItem('userId', response.user.id);
                navigate('/profile/viewprofile'); // Redirect to the profile page after successful login
            }
        } catch (error) {
            console.error('Login failed', error);
            setError(error.response?.data?.error || 'Login failed. Please check your credentials.');
        }
    };
    
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="container max-w-5xl mx-auto bg-white p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">

            <div className="md:w-1/2 order-2 md:order-1 mr-4">
            {/* <p>Tous les utilisateurs doivent valider et être tenus informés dès leur première inscription, c'est la loi de l'Union européenne.<span className='text-blue-500 cursor-pointer' onClick={() => setPrivacyModalOpen(true)}>Politique de confidentialité</span></p>  */}
                <h4 className='text-2xl font-bold text-gray-800 mb-4'>Accès illimité pour les
professionnels avec
abonnement:</h4>
                <ul className="list-disc space-y-2 text-gray-600 pl-5">
                <li>Déposer ses annonces</li>
                    <li>Consulter les annonces</li>
                    <li>Déposer son CV dans notre rubrique emplois</li>
                    <li>Rechercher un stagiaire/apprentis/salarié</li>
                    <li>Faire sa publicité avec notre rubrique « Les pros »</li>
                    <li>Faire sa communication avec notre carte de visite</li>
                    <li>Apprendre aux autres pros son activité avec notre fil d'actualité</li>
                    <li>Échanger via notre messagerie</li>
                    <li>S'entraider entre pros avec notre forum</li>
                    <li>Comprendre et s'informer avec notre rubrique « Astuces & Conseils »</li>
                    <li>Se renseigner et poser des questions avec notre assistant IA JOBBY</li>
                </ul>

                <div className="flex justify-center items-center gap-4 mt-4">
                <img
                            src={sub1}
                            alt="Subscription 1"
                            className="w-1/2 sm:w-1/3 lg:w-1/4 rounded-lg cursor-pointer"
                            onClick={() => setLightboxImage(sub1)}
                        />
                        <img
                            src={sub2}
                            alt="Subscription 2"
                            className="w-1/2 sm:w-1/3 lg:w-1/4 rounded-lg cursor-pointer"
                            onClick={() => setLightboxImage(sub2)}
                        />
    </div>
            </div>
  
            <div className="md:w-1/2 bg-[#E1F5C4] p-6 rounded-lg shadow-lg order-1 md:order-2">
            <div className="  text-center">
                    <img src={LOGO2} alt="Logo" className="  mx-auto mb-4" />
                </div>
                    <h4 className='text-2xl font-bold text-[#495057] text-center mb-4'>Créer un compte Professionnel</h4>
                    <p className='text-center text-[#495057] mb-6'>Nous sommes heureux de vous voir</p>
                    <form onSubmit={handleLogin} className="space-y-4">
                    <div className="  mt-4">
                        <label className="  block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="email">E-mail</label>
                        <input
                            className="  w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
                            type="email"
                            id="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="  mt-4 relative">
                        <label className="  block text-[#75C00A] text-xs font-semibold mb-1" htmlFor="password">Passe</label>
                        <input
                            className="  w-full rounded-full p-2 border border-gray-300 text-[#495057] placeholder-[#495057] focus:outline-none"
                            type={showPassword ? 'text' : 'password'} // Toggle between text and password
                            id="password"
                            placeholder="Tapez votre mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span
                            className="  absolute right-4 top-8 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                        >
                            {showPassword ? <FiEyeOff /> : <FiEye />} {/* Show eye icon */}
                        </span>
                    </div>
                    {error && <div className="  text-red-500 text-xs mt-2">{error}</div>}
                    <div className="  flex items-center mt-4">
                        <input type="checkbox" id="remember" className="  form-checkbox" />
                        <label htmlFor="remember" className="  ml-2 text-xs text-[#75C00A] font-semibold">Souviens-toi de moi</label>
                    </div>
                    <div className='flex px-2 items-center my-2'>
                    <p className='text-blue-500 text-sm cursor-pointer' onClick={() => setPrivacyModalOpen(true)}>Politique de confidentialité</p></div>
                    <div className="  mt-4">
                        <button type="submit" className="  w-full py-2.5 rounded-full bg-gradient-to-r from-[#75C00A] to-[#375A05] text-white font-semibold">
                            Se connecter
                        </button>
                    </div>
                </form>
                <div className="  mt-4 text-center">
                    <button
                        className="  text-xs text-[#75C00A] font-semibold cursor-pointer"
                        onClick={handleForgotPasswordClick}
                    >
                        Mot de passe oublié?
                    </button>
                     <p  ClassName="    text-xs text-[#75C00A] font-normal mt-1">ou</p>
                     <p  ClassName="    text-xs text-[#65A406] font-normal mt-1">Vous n'avez pas de compte ?</p>
                    <button
                        className="  w-full py-2.5 rounded-full border-2 border-[#75C00A] text-[#65A406] font-semibold mt-2"
                        onClick={() => navigate("/professionaluser")}
                    >
                        <Link className="  no-underline" to="/individualsignup">S'inscrire</Link>
                    </button>
                </div>
                <div className="  mt-4 text-center">
                     <p  ClassName="    text-xs text-[#65A406] font-semibold">As-tu besoin d'aide? Contactez le support technique.</p>
                    <div className="  flex justify-center items-center mt-2">
                        <img src={LoginVector} width={12} alt="Support" />
                         <p  ClassName="    text-xs text-lime-500 ml-2">support@btobjob.com</p>
                    </div>
                </div>
            </div>
        </div>
        {lightboxImage && (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="relative max-w-full max-h-full p-4">
            {/* Close Button */}
            <button
                onClick={() => setLightboxImage(null)}
                className="absolute top-2 right-2 text-white text-xl bg-red-500 rounded-full p-2 hover:bg-red-700"
                aria-label="Close Lightbox"
            >
                ✕
            </button>
            {/* Lightbox Image */}
            <img
                src={lightboxImage}
                alt="Lightbox"
                className="max-w-[90vw] max-h-[90vh] rounded-lg object-contain"
            />
        </div>
    </div>
)}

        {privacyModalOpen && (
                <Privacy onClose={() => setPrivacyModalOpen(false)} />
            )}
        </div>
    );
}

export default LoginPro;
