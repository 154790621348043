import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const EditOffer = () => {
  const { id } = useParams();
  const [offer, setOffer] = useState({ description: "", category: "", image: "" });
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [success, setSuccess] = useState(""); // Success message
  const navigate = useNavigate();

  useEffect(() => {
    fetchOffer();
  }, []);

  const fetchOffer = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://btobjob.com/fetch_offer.php?id=${id}`, {
        withCredentials: true,
      });
      setOffer(response.data.offer);
    } catch (error) {
      console.error("Error fetching offer:", error);
      setError("Failed to load offer details.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOffer((prevOffer) => ({ ...prevOffer, [name]: value }));
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess("");
    setLoading(true);

    const formData = new FormData();
    formData.append("id", id);
    formData.append("description", offer.description);
    formData.append("category", offer.category);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    try {
      await axios.post("https://btobjob.com/edit_offer.php", formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSuccess("Offer updated successfully.");
      setTimeout(() => navigate("/profile/OfferHistory"), 2000); // Redirect after success
    } catch (error) {
      console.error("Error updating offer:", error);
      setError("Failed to update offer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-2xl font-bold mb-4">Modifier l'offre</h1>
      {loading && <p className="text-gray-500">Chargement...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Catégorie</label>
          <input
            type="text"
            name="category"
            value={offer.category}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            name="description"
            value={offer.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
            rows="4"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Image actuelle</label>
          {offer.image ? (
            <img
              src={`https://btobjob.com/${offer.image}`}
              alt="Current Offer"
              className="w-32 h-32 object-cover"
            />
          ) : (
            <p className="text-gray-500">Aucune image disponible</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Modifier l'image</label>
          <input type="file" onChange={handleImageChange} accept="image/*" className="w-full px-3 py-2 border rounded-md" />
        </div>
        <button
          type="submit"
          className={`w-full py-2 px-4 rounded-md text-white ${
            loading ? "bg-gray-400 cursor-not-allowed" : "bg-lime-500 hover:bg-blue-600"
          }`}
          disabled={loading}
        >
          {loading ? "Mise à jour..." : "Mettre à jour l'offre"}
        </button>
      </form>
    </div>
  );
};

export default EditOffer;
