import React, { useState, useEffect } from 'react';
import { CiTwitter } from "react-icons/ci";
import { PiTelegramLogoLight, PiWhatsappLogoLight } from "react-icons/pi";
import { MdAlternateEmail } from "react-icons/md";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaXTwitter} from "react-icons/fa6";
import { GoCopy } from "react-icons/go"; // Copy icon
import api from '../../services/api';

const ProfileShare = ({ boolean }) => {
    const [localToggle, setLocalToggle] = useState(boolean);
    const [profileData, setProfileData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setLocalToggle(boolean);
    }, [boolean]);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await api.fetchProfile();
                setProfileData(response);
            } catch (error) {
                console.error("Erreur lors de la récupération des données du profil :", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    // Function to generate slug based on name and job title
    const generateSlug = () => {
        if (profileData && profileData.Name && profileData.job_title) {
            const nameSlug = profileData.Name.toLowerCase().replace(/\s+/g, '-');
            const jobTitleSlug = profileData.job_title.toLowerCase().replace(/\s+/g, '-');
            return `${nameSlug}-${jobTitleSlug}`;
        }
        return ''; // Return an empty string if name or job title is not available
    };

    // The slug URL to be displayed
    const slugUrl = `https://btobjob.com/profile/${generateSlug()}`;

    // Actual URL with ID (for navigation)
    const actualUrl = `https://btobjob.com/profile/specificUser?professional_id=${profileData?.ID}`;

    // Function to handle link click - redirects to the actual URL with ID
    const handleLinkClick = () => {
        window.location.href = actualUrl;
    };

    // Function to copy the slug URL to the clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(slugUrl);
        alert('Lien copié dans le presse-papier !');
    };

    if (isLoading) {
        return <div>Chargement...</div>;
    }

    if (!profileData) {
        return <div>Données de profil introuvables.</div>;
    }

    return (
        <div className={`${localToggle ? "flex" : "hidden"} items-center justify-center fixed inset-0 z-[9999] bg-black/50`}>
            <div className="  bg-white p-6 rounded-lg shadow-lg w-[500px] relative">
                <div className="  absolute top-4 right-4 text-gray-500 cursor-pointer" onClick={() => setLocalToggle(!localToggle)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="  h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                 <h2  className="    text-lg font-semibold mb-4">Partager avec</h2>
                <div className="  flex justify-around mb-6">
                    <div className="  flex flex-col items-center cursor-pointer" onClick={() => window.open(`mailto:?subject=Check%20this%20out&body=${actualUrl}`)}>
                        <div className="  bg-gray-200 rounded-full p-4">
                            <MdAlternateEmail className="  h-6 w-6" />
                        </div>
                        <span className="  mt-2 text-sm">E-mail</span>
                    </div>
                    <div className="  flex flex-col items-center cursor-pointer" onClick={() => window.open(`https://wa.me/?text=${actualUrl}`)}>
                        <div className="  bg-gray-200 rounded-full p-4">
                        <PiWhatsappLogoLight className="h-6 w-6" />
                        </div>
                        <span className="  mt-2 text-sm">WhatsApp</span>
                    </div>
                    <div className="  flex flex-col items-center cursor-pointer" onClick={() => window.open(`https://t.me/share/url?url=${actualUrl}`)}>
                        <div className="  bg-gray-200 rounded-full p-4">
                            <PiTelegramLogoLight className="  h-6 w-6" />
                        </div>
                        <span className="  mt-2 text-sm">Telegram</span>
                    </div>
                    <div className="  flex flex-col items-center cursor-pointer" onClick={() => window.open(`https://twitter.com/intent/tweet?url=${actualUrl}`)}>
                        <div className="  bg-gray-200 rounded-full p-4">
                            <FaXTwitter className="  h-6 w-6" />
                        </div>
                        <span className="  mt-2 text-sm">X</span>
                    </div>
                    <div className="  flex flex-col items-center cursor-pointer" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${actualUrl}`)}>
                        <div className="  bg-gray-200 rounded-full p-4">
                            <FaFacebook className="  h-6 w-6" />
                        </div>
                        <span className="  mt-2 text-sm">Facebook</span>
                    </div>
                    <div className="  flex flex-col items-center cursor-pointer" onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${actualUrl}`)}>
                        <div className="  bg-gray-200 rounded-full p-4">
                            <FaLinkedin className="  h-6 w-6" />
                        </div>
                        <span className="  mt-2 text-sm">LinkedIn</span>
                    </div>
                </div>
                <div className="  text-center mb-4 text-gray-500">Copier le lien</div>
                <div className="  flex items-center justify-between bg-gray-100 p-2 rounded-lg">
                    <input 
                        type="text" 
                        value={slugUrl} 
                        className="  bg-gray-100 text-gray-700 text-sm w-full focus:outline-none" 
                        readOnly 
                        onClick={handleLinkClick} // Redirect to actual URL on click
                    />
                    <button className="  bg-red-100 text-red-500 p-2 rounded-full ml-2" onClick={copyToClipboard}>
                        <GoCopy className="  h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileShare;
