import React, { useState, useEffect } from 'react';
import OpenAI from 'openai';
import api from '../services/api';

const ChatBot = ({ userId, onClose }) => {
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([
    { sender: 'bot', text: 'Bonjour, je suis Jooby, votre assistant personnel AI. Comment puis-je vous aider ?' },
  ]); // Default message from the bot
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const fetchSession = async () => {
    try {
      const sessionData = await api.getSession();
      setUser(sessionData.user);
    } catch (error) {
      console.error('Error fetching session data:', error);
      setError('Could not fetch session. Please try again later.');
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const sendMessage = () => {
    if (!message.trim()) {
      setError('Message is empty');
      return;
    }
    setError('');

    const updatedChat = [...chat, { sender: 'user', text: message }];
    setChat(updatedChat);

    const sendToGPT = async () => {
      try {
        const response = await openai.chat.completions.create({
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: message },
          ],
        });

        const botMessage = response.choices[0].message.content;
        setChat((prevChat) => [...prevChat, { sender: 'bot', text: botMessage }]);
      } catch (error) {
        console.error('Error communicating with OpenAI:', error);
        setError('Error sending message. Please try again.');
      }
    };

    sendToGPT();
    setMessage('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Jooby</h2>
        <button onClick={onClose} className="text-lime-500 bg-gray-200 px-3 py-1 rounded font-bold">X</button>
      </div>

      <div className="chat-box flex-grow mb-2 overflow-y-auto p-2 border border-gray-300 rounded">
        {chat.map((msg, index) => (
          <div
            key={index}
            className={`p-2 my-1 rounded ${msg.sender === 'user' ? 'bg-lime-100 self-end' : 'bg-gray-100'}`}
          >
            {msg.text}
          </div>
        ))}
        {error && <div className="text-red-500">{error}</div>}
      </div>

      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="posez votre question"
        className="border p-2 w-full rounded mb-2"
      />
      <button
        onClick={sendMessage}
        className="bg-lime-600 text-white py-2 rounded w-full"
      >
        Envoyer
      </button>
    </div>
  );
};

export default ChatBot;
