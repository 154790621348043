import './style.css';
import LOGO from '../assests/b2bjob logo 1.png';
import { FaInstagram, FaFacebookF, FaLinkedin, FaTiktok, FaYoutube, FaPinterest, FaGoogle } from "react-icons/fa";
import api from '../services/api';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Privacy from '../signup/login/privacy-policy';
function FooterSection() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);
  const navigate = useNavigate(); 
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await api.getSession();
        if (response.user) {
          setIsLoggedIn(true);
          setIsProfessional(response.user.professional === "1");
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching session:', error);
        setIsLoggedIn(false);
      }
    };

    fetchSession();
  }, []);


  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        // First ensure the user is a professional before checking subscription status
        if (isProfessional) {
          const { subscription_exists, status, time_left } = await api.getSubscription(); // Fetch subscription info from the backend
          
          // Set subscription based on the existence flag
          setSubscription(subscription_exists);
          
          // If no subscription exists or it has expired, redirect to the subscription page
          if (!subscription_exists || status === 'expired' || (!time_left && status === 'inactive')) {
            setTimeout(() => {
              navigate('/profile/subscription');
            }, 2000); // 2-second delay before navigating
          }
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
        setSubscription(false); // Set subscription to false in case of an error
      }
    };
  
    // Only fetch subscription if user is a professional
    if (isProfessional) {
      fetchSubscription();
    }
  }, [isProfessional, navigate]);

  const handleLogout = async () => {
    try {
      await api.logout();
      setIsLoggedIn(false);
      window.location.reload();
    } catch (error) {
      console.error('Logout error', error);
    }
  };

  const handleNavigateToContact = () => {
    navigate('/contact');
  };

  return (
    <div className='mx-auto mt-24 max-w-7xl px-6 sm:mt-40 lg:px-8 mb-9 bg-[#FFF] py-5'>
      <div className='flex flex-col md:flex-row justify-between items-center'>
        <div>
          <img src={LOGO} width={120} alt="B2BJob Logo" />
        </div>
        <div className="mt-4 md:mt-0">
          <h4 className='text-lime-600 md:text-2xl font-semibold'>Contact</h4>
          <button
            onClick={handleNavigateToContact}
            className="md:text-xl font-medium text-Black-600 hover:underline cursor-pointer bg-transparent border-none p-0"
          >
            info@btobjob.com
          </button>
        </div>
      </div>

      <div className="mt-8">
        <ul className="flex flex-wrap items-center justify-center space-x-6 mb-6">
          <li>
            <Link className="navbar-link" to="/">Accueil</Link>
          </li>
          {isProfessional && (
            <li>
              <Link className="navbar-link" to="/profile/subscription">Abonnements</Link>
            </li>
          )}
          <li>
            <Link className="navbar-link" to="/about">Nous</Link>
          </li>
          <li>
            <Link className="navbar-link" to="/contact">Contact</Link>
          </li>
          <li>
            <Link className="navbar-link" to="/faqs">FAQ</Link>
          </li>
          <li>
            <Link className="navbar-link" to="/Cgv">CGV / CGU</Link>
          </li>
          {subscription ? (   
          <li>
            <Link className="navbar-link" to="/BlogPage">Astuces et conseils</Link>
          </li>
          ) : null}
          {!isLoggedIn && (
            <li>
              <Link className="navbar-link" to="/professionaluser">S'inscrire</Link>
            </li>
          )}
        </ul>
      </div>

      <div className="footer-icons flex items-center justify-center space-x-6 mb-10">
        <a href="https://www.facebook.com/btobjob.fr" target="_blank" rel="noopener noreferrer"><FaFacebookF size={24} /></a>
        <a href="https://www.instagram.com/btob.job?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer"><FaInstagram size={24} /></a>
        <a href="https://www.linkedin.com/company/b-to-b-job/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><FaLinkedin size={24} /></a>
        <a href="https://www.tiktok.com/@btobjob" target="_blank" rel="noopener noreferrer"><FaTiktok size={24} /></a>
        <a href="https://www.youtube.com/@BtoBJOB" target="_blank" rel="noopener noreferrer"><FaYoutube size={24} /></a>
        <a href="https://pin.it/29iaLpYIk" target="_blank" rel="noopener noreferrer"><FaPinterest size={24} /></a>
        <a href="https://g.co/kgs/YxPBTir" target="_blank" rel="noopener noreferrer"><FaGoogle size={24} /></a>
      </div>
      <div className="text-center text-sm mt-4 mb-6">
      <Link className='text-gray-600 hover:underline me-1' onClick={() => setPrivacyModalOpen(true)}>politique de confidentialité</Link>|
        <Link to="/CookiePolicy" className="ms-1 text-gray-600 hover:underline">Politique de cookies</Link>
        <span> | © 2024 btobjob.com</span>
      </div>
      {privacyModalOpen && (
                <Privacy onClose={() => setPrivacyModalOpen(false)} />
            )}
    </div>
  );
}

export default FooterSection;
