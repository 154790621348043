import NavbarSection from "../components/Navbar";
import AboutMan from '../assests/aboutman.png';
import Polygon1 from '../assests/aboutpolygon.png';
import Polygon2 from '../assests/Polygon2.png';
import Polygon3 from '../assests/Polygon3.png';
import Polygon4 from '../assests/Polygon4.png';
import FooterSection from "../components/Footer";
import AboutVector from '../assests/aboutvector.png';
import LOGO from '../assests/b2bjob logo 1.png';

function AboutPage() {
  return (
    <>
      <NavbarSection />
      <div className="  container flex flex-col mx-auto px-4 py-8">
        <div className="  flex justify-between items-center max-w-5xl mb-8 p-4 rounded-3xl">
          <div>
            <img src={LOGO} alt="BtoBJOB Logo" className="  w-52" />
          </div>
          <div>
            <img src={AboutMan} alt="About Man" className="  w-64" />
          </div>
        </div>

        <div className="  grid md:grid-cols-2 mx-auto max-w-5xl p-4 my-4 md:border-2 border-lime-700 rounded-2xl shadow-2xl sm:mb-10">
          <div className="  mb-4 translatable-text">
             <h2  ClassName="     text-4xl font-bold underline underline-offset-4 decoration-lime-700 mb-4">Notre<span className="  text-lime-600">Mission</span></h2>
             <p ClassName=" translatable-text    md:text-xl text-sm leading-relaxed">
             Sur btobjob.com ,notre mission est de révolutionner la manière dont les professionnels interagissent et collaborent. Nous croyons fermement à l'importance de connecter les experts de tous les domaines, que ce soit dans le bâtiment, la mécanique, l’administratif, le secrétariat ou d'autres secteurs, pour favoriser une collaboration fructueuse et transparente.

            </p>
          </div>
          <div className="  relative p-1 hidden md:block">
            <img src={AboutVector} alt="About Vector" className="  w-full h-40 md:h-72 absolute z-0 object-contain animate-pulse" />
            <img src={Polygon1} alt="Polygon" className="  w-full h-36 md:h-64 absolute z-0 object-contain" />
          </div>

        </div>

        <div className="  grid md:grid-cols-2 mx-auto max-w-5xl p-4 my-4 md:border-2 border-lime-700 rounded-2xl shadow-2xl sm:mb-10">

          <div className="  mt-4 md:order-2 mb-5 translatable-text">
             <h2  ClassName=" translatable-text    text-4xl font-semibold underline underline-offset-4 decoration-lime-700 mb-4">Notre <span className="  text-lime-600">Modèle Économique</span></h2>
             <p ClassName=" translatable-text    md:text-xl text-sm leading-relaxed">
             btobjob.com propose des abonnements premium pour les professionnels souhaitant accéder à des fonctionnalités avancées et à un réseau exclusif de partenaires qualifiés. Les abonnements gratuits permettent la dépose d'offres de sous-traitance de location de matériel. Pour s'inscrire en tant que professionnel ou en premium, le numéro SIRET ainsi qu'une attestation d'assurance responsabilité civile et/ou décennale en cours de validité sont obligatoires pour garantir une relation de confiance entre professionnels.


            </p>
          </div>
          <div className="  relative p-1 hidden md:block">
            <img src={AboutVector} alt="About Vector" className="  w-full h-40 md:h-72 absolute z-0 object-contain animate-pulse" />
            <img src={Polygon1} alt="Polygon" className="  w-full h-36 md:h-64 absolute z-0 object-contain" />
          </div>

        </div>


        <div className="  grid md:grid-cols-2 mx-auto max-w-5xl p-4 my-4 md:border-2 border-lime-700 rounded-2xl shadow-2xl sm:mb-10">
          <div className="  mb-4 translatable-text">
             <h2  ClassName=" translatable-text    text-4xl font-bold underline underline-offset-4 decoration-lime-700 mb-4">Nos <span className="  text-lime-600">Fonctionnalités</span></h2>
             <p ClassName=" translatable-text    md:text-xl text-sm leading-relaxed">
             Sur notre site btobjob.com, vous trouverez une rubrique dédiée à l'emploi, à l’apprentissage et aux stages. Les demandeurs pour la sous-traitance ainsi que les entreprises offrant des chantiers en sous-traitance peuvent se connecter. Vous pouvez louer des espaces non utilisés dans vos locaux ou des véhicules utilitaires inutilisés. Les agences immobilières ont la possibilité de mettre en avant leurs biens à la location pour les professionnels ainsi que les biens immobiliers professionnels à vendre.


            </p>
          </div>
          <div className="  relative p-1 hidden md:block">
            <img src={AboutVector} alt="About Vector" className="  w-full h-40 md:h-72 absolute z-0 object-contain animate-pulse" />
            <img src={Polygon1} alt="Polygon" className="  w-full h-36 md:h-64 absolute z-0 object-contain" />
          </div>

        </div>

        <div className="  grid md:grid-cols-2 mx-auto max-w-5xl p-4 my-4 md:border-2 border-lime-700 rounded-2xl shadow-2xl">

          <div className="  mt-4 md:order-2 mb-5 translatable-text">
             <h2  ClassName=" translatable-text    text-4xl font-semibold underline underline-offset-4 decoration-lime-700 mb-4">Notre <span className="  text-lime-600">Engagement</span></h2>
             <p ClassName=" translatable-text    md:text-xl text-sm leading-relaxed">
             Chez btobjob.com,nous nous engageons à fournir une plateforme polyvalente qui facilite les échanges professionnels, la sous-traitance, la location de matériel et l'immobilier d'entreprise. La vérification des numéros SIRET et des attestations d'assurance en cours de validité est essentielle pour assurer la qualité et la fiabilité des interactions  professionnelles sur notre plateforme.
            </p>
          </div>
          <div className="  relative p-1 hidden md:block">
            <img src={AboutVector} alt="About Vector" className="  w-full h-40 md:h-72 absolute z-0 object-contain animate-pulse" />
            <img src={Polygon1} alt="Polygon" className="  w-full h-36 md:h-64 absolute z-0 object-contain" />
          </div>

        </div>


      </div>
      <FooterSection />
    </>
  );
}

export default AboutPage;
