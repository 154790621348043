import React from 'react';

function Privacy({ onClose }) {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full mx-4 overflow-y-auto h-[80vh]">
                <h2 className="text-xl font-bold mb-4">Politique de confidentialité de btobjob.com</h2>
                <p>
                    Chez btobjob.com, nous accordons une grande importance à la protection de la
                    confidentialité des données de nos utilisateurs. Cette politique de confidentialité vise à
                    expliquer quelles données sont collectées, comment elles sont utilisées et comment elles
                    sont protégées.
                </p>
                
                <h3 className="font-semibold mt-4">Collecte de données:</h3>
                <ul className="list-disc list-inside">
                    <li>Nous collectons des informations personnelles telles que le nom, l'adresse e-mail, le numéro de téléphone, l'adresse professionnelle, et d'autres données pertinentes pour faciliter la mise en relation entre professionnels.</li>
                    <li>Les données de paiement sont traitées de manière sécurisée par des partenaires de confiance pour les transactions.</li>
                </ul>
                
                <h3 className="font-semibold mt-4">Utilisation des données:</h3>
                <ul className="list-disc list-inside">
                    <li>Les informations collectées sont utilisées pour créer des profils professionnels, faciliter les mises en relation et fournir un service personnalisé aux utilisateurs.</li>
                    <li>Les données sont également utilisées pour améliorer l'expérience utilisateur, analyser les tendances du marché et personnaliser les offres en fonction des besoins des utilisateurs.</li>
                </ul>
                
                <h3 className="font-semibold mt-4">Protection des données:</h3>
                <ul className="list-disc list-inside">
                    <li>Nous mettons en place des mesures de sécurité avancées pour protéger les données des utilisateurs contre tout accès non autorisé, la perte ou la divulgation accidentelle.</li>
                    <li>Les données sont stockées de manière sécurisée et confidentielle, conformément aux normes de sécurité en vigueur.</li>
                </ul>
                
                <h3 className="font-semibold mt-4">Partage des données:</h3>
                <ul className="list-disc list-inside">
                    <li>Les données des utilisateurs ne sont pas vendues, louées ou partagées avec des tiers à des fins de marketing sans le consentement explicite de l'utilisateur.</li>
                    <li>Les informations peuvent être divulguées à des partenaires de confiance uniquement pour fournir des services essentiels à btobjob.com.</li>
                </ul>
                
                <h3 className="font-semibold mt-4">Conservation des données:</h3>
                <ul className="list-disc list-inside">
                    <li>Les données des utilisateurs sont conservées aussi longtemps que nécessaire pour fournir les services demandés ou conformément aux obligations légales en vigueur.</li>
                </ul>
                
                <p className="mt-4">
                    En utilisant btobjob.com, les utilisateurs acceptent implicitement les termes de cette politique de confidentialité. Pour toute question ou demande concernant la confidentialité des données, veuillez nous contacter à l'adresse indiquée sur le site.
                </p>
                
                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-[#75C00A] text-white rounded hover:bg-[#66A309] transition duration-300 mt-4"
                >
                    Fermer
                </button>
            </div>
        </div>
    );
}

export default Privacy;
