import React, { useState, useEffect } from "react";
import userProfile from "../assests/people2.png";
import { useNavigate } from "react-router-dom";
import api from "../services/api";

const ChatApp = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const fetchContacts = async () => {
    try {
      const data = await api.fetchContacts();
      if (data.success) {
        setContacts(data.contacts);
        setError(null);
      } else {
        setError(data.message || "Failed to fetch contacts");
      }
    } catch (error) {
      setError("Failed to fetch contacts");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
    const interval = setInterval(fetchContacts, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const soundex = (s) => {
    const a = s.toUpperCase().split('');
    const f = a.shift();
    const r = a
      .map((char) =>
        ({
          B: 1, F: 1, P: 1, V: 1,
          C: 2, G: 2, J: 2, K: 2, Q: 2, S: 2, X: 2, Z: 2,
          D: 3, T: 3,
          L: 4,
          M: 5, N: 5,
          R: 6,
        }[char] || 0)
      )
      .filter((num, i, arr) => i === 0 || num !== arr[i - 1])
      .join('');
    return (f + r + '000').slice(0, 4);
  };

  const trimmedSearchTerm = searchTerm.trim();

  const filteredContacts = trimmedSearchTerm
    ? contacts.filter((contact) => {
        const contactSoundex = soundex(contact.name);
        const searchSoundex = soundex(trimmedSearchTerm);
        const nameIncludesSearchTerm = contact.name.toLowerCase().includes(trimmedSearchTerm.toLowerCase());
        return contactSoundex === searchSoundex || nameIncludesSearchTerm;
      })
    : contacts; // If searchTerm is empty, show all contacts
  

  const handleContactClick = async (contactId) => {
    try {
      const response = await fetch("https://btobjob.com/mark-read.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ contact_id: contactId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success) {
        navigate(`/profile/conversation?id=${contactId}`);
      } else {
        console.error(data.message || "Failed to mark messages as read");
      }
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  return (
    <div className="flex overflow-hidden max-w-6xl md:mt-14 md:mx-auto m-2 h-[700px] rounded-xl">
      <div className="w-full bg-white border-r border-gray-300 h-[700px]">
        <div className="relative p-2">
          <input
            className="appearance-none border-2 pl-10 border-gray-300 hover:border-gray-400 transition-colors w-full py-2 px-3 text-gray-800 leading-tight bg-gray-100 focus:outline-none focus:ring-lime-600 focus:border-lime-600 focus:shadow-outline"
            type="text"
            placeholder="Recherche"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="absolute left-0 inset-y-0 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-3 text-gray-400 hover:text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>

        <div className="overflow-y-auto h-[700px] p-3">
  {loading ? (
    <p>Loading contacts...</p>
  ) : error ? (
    <p className="text-red-500">{error}</p>
  ) : filteredContacts.length === 0 ? (
    <p>No contacts found</p>
  ) : (
    filteredContacts.map((contact) => (
      <div
        key={contact.id}
        onClick={() => handleContactClick(contact.id)}
        className="flex items-center mb-4 cursor-pointer hover:bg-gray-100 p-2 rounded-md"
      >
        <div className="w-12 h-12 bg-gray-300 rounded-full mr-3">
          <img
            src={contact.profile_picture || userProfile}
            alt="User Avatar"
            className="w-12 h-12 rounded-full"
          />
        </div>
        <div className="flex-1">
          <h4 className="font-semibold">{contact.name}</h4>
          <p className="text-sm text-gray-600">
            {contact.last_message || "No recent messages"}
          </p>
          <p className="text-xs text-gray-500">
            {contact.last_message_time
              ? new Date(contact.last_message_time).toLocaleString()
              : ""}
          </p>
          {contact.unread_count > 0 && (
            <span className="text-xs text-white bg-red-500 px-2 py-1 rounded-full">
              {contact.unread_count} new
            </span>
          )}
        </div>
      </div>
    ))
  )}
</div>
      </div>
    </div>
  );
};

export default ChatApp;
