import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
import NavbarSection from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/Footer";
import LOGO2 from '../assests/ASTUCES.png'

const BlogPage = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const postsPerPage = 5; 
     const navigate = useNavigate();

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`https://btobjob.com/get_blog_posts.php?page=${currentPage}&limit=${postsPerPage}`);
                setPosts(response.data.posts || []);
                setTotalPages(response.data.totalPages || 1);
            } catch (error) {
                console.error("Error fetching blog posts:", error);
            }
        };
        fetchPosts();
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <>
            <NavbarSection />
            <div className="container mx-auto px-4 py-8 mt-5">
            <button
        onClick={() => navigate(-1)}
        className="mb-6 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
      >
        <FaArrowLeft className="inline mr-2" /> Retour
      </button>
                <div className="flex justify-between items-center mb-8 p-4 rounded-3xl shadow-lg">
                    <header className="text-center flex-1">
                    <div className="flex flex-col justify-center items-center mb-8 p-4 rounded-3xl ">
        <img
            src={LOGO2}
            alt="Logo"
            className="h-64 w-auto object-contain mb-4" // Increased size and centered
        />
        </div>
                        {/* <h1 className="text-4xl font-bold underline underline-offset-4 decoration-lime-700">Astuces et conseils</h1> */}
                        {/* <p className="text-gray-600 mt-2">Aperçus et histoires de notre équipe</p> */}
                    </header>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                    {/* Main Blog Content */}
                    <section className="col-span-2">
                        {posts.map(post => (
                            <BlogPost key={post.id} post={post} />
                        ))}

                        {/* Pagination */}
                        <div className="flex justify-center mt-10">
                            <button
                                className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Précédent
                            </button>
                            <span className="px-4 py-2 text-gray-700">Page {currentPage} sur {totalPages}</span>
                            <button
                                className="px-4 py-2 mx-1 bg-gray-200 text-gray-700 rounded"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Suivant
                            </button>
                        </div>
                    </section>

                    {/* Sidebar */}
                    <aside className="bg-gray-100 p-6 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-4">Catégories</h2>
                        <ul>
                            <li><Link to="/BlogPage?category=tech" className="text-lime-600 hover:underline">Technologie</Link></li>
                            <li><Link to="/BlogPage?category=business" className="text-lime-600 hover:underline">Business</Link></li>
                            <li><Link to="/BlogPage?category=lifestyle" className="text-lime-600 hover:underline">Style de vie</Link></li>
                            <li><Link to="/BlogPage?category=productivity" className="text-lime-600 hover:underline">Productivité</Link></li>
                        </ul>

                        <h2 className="text-xl font-semibold mt-8 mb-4">Articles récents</h2>
                        <ul>
                            {posts.slice(0, 50).map((post) => (
                                <li key={post.id}><Link to={`/BlogDetail/${post.id}`} className="text-lime-600 hover:underline">{post.title}</Link></li>
                            ))}
                        </ul>
                    </aside>
                </div>
            </div>
            <FooterSection />
        </>
    );
};

const BlogPost = ({ post }) => {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <h2 className="text-2xl font-semibold mb-2">{post.title}</h2>
            <p className="text-gray-600 mb-4">{new Date(post.created_at).toLocaleDateString()}</p>
            <p className="text-gray-800">{truncateText(post.description, 150)}</p>
            <Link to={`/BlogDetail/${post.id}`} className="text-lime-600 hover:underline mt-4 inline-block">Lire la suite</Link>
        </div>
    );
};

export default BlogPage;
