import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { IoLayersOutline } from "react-icons/io5";
import { TfiAnnouncement } from "react-icons/tfi";
import { CiVideoOn, CiLocationOn } from "react-icons/ci";
import { MdWorkOutline } from "react-icons/md";
import { FaCircle } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SearchableDropdown from '../SearchableDropdown';
import api from '../../services/api';
const API_URL = 'https://btobjob.com';

const PostServices = () => {
    const [jobs, setJobs] = useState([]);
    const [jobSeekers, setJobSeekers] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [filteredJobSeekers, setFilteredJobSeekers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedJobType, setSelectedJobType] = useState('');
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [radius, setRadius] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isCityLoading, setIsCityLoading] = useState(false);
    const [viewType, setViewType] = useState(localStorage.getItem('viewType') || 'jobs');

    const [currentPage, setCurrentPage] = useState(1);
    const [subscriptionExists, setSubscriptionExists] = useState(false); // Subscription state
    const [error, setError] = useState(null);
    const [cities, setCities] = useState([]);
    const [clearInput, setClearInput] = useState(false);

    const navigate = useNavigate();

    const jobTypes = [
        { type: "Stage", icon: MdWorkOutline },
        { type: "Emploi", icon: IoLayersOutline },
        { type: "Formation", icon: TfiAnnouncement },
        { type: "Apprentissage", icon: CiVideoOn },
    ];

    useEffect(() => {
        localStorage.setItem('viewType', viewType);
    }, [viewType]);
    
  // Fetch subscription details
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
        try {
            const response = await api.getSubscription(); // Call the API service for subscription
            setSubscriptionExists(response.subscription_exists); // Update subscription state
        } catch (error) {
            console.error('Error fetching subscription status:', error);
        }
    };

    fetchSubscriptionStatus();
}, []);
useEffect(() => {
    const fetchInitialCities = async () => {
        try {
            setIsCityLoading(true);
            const response = await axios.get('https://geo.api.gouv.fr/communes', {
                params: {
                    fields: 'nom',
                    format: 'json',
                    geometry: 'centre',
                    limit: 100,
                },
            });
            const cityNames = response.data.map((region) => region.nom).sort();
            setCities(cityNames);
        } catch (error) {
            console.error('Error fetching cities:', error);
        } finally {
            setIsCityLoading(false);
        }
    };
    fetchInitialCities();
}, []);

const fetchCitySuggestions = async (query) => {
    try {
        if (query.length < 3) return; // Fetch only if query is 3+ characters
        setIsCityLoading(true);
        const response = await axios.get('https://geo.api.gouv.fr/communes', {
            params: {
                fields: 'nom',
                format: 'json',
                geometry: 'centre',
                nom: query,
                limit: 100,
            },
        });
        const citySuggestions = response.data.map((region) => region.nom).sort();
        setCities(citySuggestions);
    } catch (error) {
        console.error('Error fetching city suggestions:', error);
    } finally {
        setIsCityLoading(false);
    }
};
const fetchData = async () => {
    setLoading(true);
    setError(null);

    const endpoint = viewType === 'jobs' ? `${API_URL}/fetch-jobs.php` : `${API_URL}/job_seekers.php`;
    const params = {
        search: searchQuery,
        latitude,
        longitude,
        radius,
        page: currentPage,
        itemsPerPage: 10,
        jobType: selectedJobType || undefined, // Include the jobType filter
    };

    try {
        const { data } = await axios.get(endpoint, { params });
        
        // Log the fetched data to the console
        console.log("Fetched data:", data);

        if (viewType === 'jobs') {
            setJobs(data.results?.ads_job || []);
            setFilteredJobs(data.results?.ads_job || []);
        } else if (viewType === 'job_seekers') {
            setJobSeekers(data.results?.job_seekers || []);
            setFilteredJobSeekers(data.results?.job_seekers || []);
        }
    } catch (err) {
        console.error('Error fetching data:', err);
        setError('Unable to fetch data. Please try again later.');
    } finally {
        setLoading(false);
    }
};
    useEffect(() => {
        fetchData();
    }, [viewType, searchQuery, latitude, longitude, radius, currentPage, selectedJobType]); // Ensure selectedJobType is a dependency
    

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const handleCitySelect = async (city) => {
        setSelectedCity(city);
        setLatitude(null);
        setLongitude(null);
    };

    const handleSearchByCity = async () => {
        if (selectedCity) {
            setIsCityLoading(true);
            const coordinates = await fetchCoordinates(selectedCity);
            if (coordinates) {
                setLatitude(coordinates.lat);
                setLongitude(coordinates.lon);
            }
            setIsCityLoading(false);
        }
    };

    const fetchCoordinates = async (city) => {
        try {
            const response = await axios.get(`${API_URL}/geocode.php`, {
                params: { q: city, format: 'json', addressdetails: 1 },
            });
            if (response.data.lat && response.data.lon) {
                return { lat: parseFloat(response.data.lat), lon: parseFloat(response.data.lon) };
            }
            throw new Error('City not found');
        } catch (error) {
            console.error('Error fetching coordinates:', error);
            alert('Unable to fetch coordinates for the city.');
            return null;
        }
    };

    const handleUseCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    setSelectedCity('');
                },
                (error) => alert('Unable to fetch current location.')
            );
        } else {
            alert('Geolocation not supported.');
        }
    };

    const handleJobTypeClick = (type) => {
        setSelectedJobType((prevType) => (prevType === type ? '' : type));
    };

    const navigateToJobDescription = (job) => navigate(`/profile/adsdescription/${job.id}`);
    const navigateToJobSeekerDescription = (seeker) => navigate(`/profile/jobdescription/${seeker.id}`);
    const clearFilters = () => {
        setSearchQuery('');
        setSelectedCity('');
        setLatitude(null);
        setLongitude(null);
        setRadius(0);
        setSelectedJobType('');
        setViewType('jobs');
        setJobs([]);
        setJobSeekers([]);
        setFilteredJobs([]);
        setFilteredJobSeekers([]);
        setCurrentPage(1); // Reset to the first page
        setClearInput(true); // Trigger clearing of the dropdown input
    
        // Reset the clearInput state after clearing
        setTimeout(() => setClearInput(false), 0);
    
        fetchData(); // Reload data with default filters
    };
    
    
    return (
        <div className="flex flex-col max-w-8xl items-start gap-10 mx-auto p-12">
            {/* Search, Radius, and City Row */}
            <div className="w-full flex flex-wrap gap-4 items-center bg-white px-4 py-4 rounded-lg shadow">
                <div className="flex items-center flex-1">
                    <FaSearch className="text-blue-400 mr-2" />
                    <input
                        type="text"
                        placeholder="Recherche par nom, description, etc."
                        className="outline-none px-2 py-2 w-full text-base border border-gray-300 rounded"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="flex items-center flex-1">
                    <CiLocationOn className="text-blue-400 mr-2" />
                    <SearchableDropdown
                        options={cities}
                        selectedOption={selectedCity}
                        setSelectedOption={handleCitySelect}
                        fetchSuggestions={fetchCitySuggestions}
                        clearInput={clearInput}
                        placeholder="Rechercher par ville"
                    />
                </div>
                <div className="flex items-center flex-1">
                    <FaCircle className="text-blue-400 mr-2" />
                    <input
    type="number"
    min="0"
    value={radius}
    onChange={(e) => setRadius(e.target.value || '')}
    className="outline-none px-2 py-2 w-full text-base border border-gray-300 rounded"
    placeholder="Rayon (km)"
    onWheel={(e) => e.target.blur()} // Disable scroll increment/decrement
/>

                </div>
                <div className="flex items-center">
                    <select
                        className="text-sm font-semibold bg-[#65A406] text-white px-4 py-2 rounded-md"
                        value={viewType}
                        onChange={(e) => setViewType(e.target.value)}
                    >
                        <option value="jobs">Emploi</option>
                        {subscriptionExists && <option value="job_seekers">Demandeurs</option>}
                    </select>
                </div>
            </div>
            <div className="mt-4 flex gap-4 col-span-full justify-start">
                <button
                    className="bg-blue-500 text-white px-3 py-2 rounded-md w-full"
                    onClick={handleUseCurrentLocation}
                >
                 Géolocalisation de ma position
                </button>
                <button
                    className={`bg-green-500 text-white px-3 py-2 rounded-md w-full ${
                        isCityLoading ? 'opacity-70' : ''
                    }`}
                    onClick={handleSearchByCity}
                    disabled={isCityLoading}
                >
                    {isCityLoading ? 'Loading...' : 'Rechercher'}
                </button>
                <button
        className="bg-red-500 text-white px-3 py-2 rounded-md w-full"
        onClick={clearFilters}
    >
        Réinitialiser les filtres
    </button>
            </div>

            {/* Job Types */}
            <div className="w-full">
                <p className="sm:text-3xl text-xl font-bold mb-4">
                Explorer par <span className="text-[#65A406]">Type d'emploi</span>
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {jobTypes.map((jobType, index) => {
                        const IconComponent = jobType.icon;
                        return (
                            <div
                            key={index}
                            className={`flex items-center gap-2 bg-white rounded-lg p-4 cursor-pointer shadow ${
                                selectedJobType === jobType.type ? 'bg-blue-100' : ''
                            }`}
                            onClick={() => handleJobTypeClick(jobType.type)}
                        >
                            <IconComponent size={36} color="#65A406" />
                            <div>
                                <p className="font-bold text-xl">{jobType.type}</p>
                            </div>
                        </div>
                        );
                    })}
                </div>
            </div>

         {/* Job Listings */}
<div className="mt-6">
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
        {viewType === 'jobs' && filteredJobs.map((job) => (
            <div
                onClick={() => navigateToJobDescription(job)}
                key={job.ad_id} // Use a unique identifier for the key
                className="bg-white shadow-lg rounded-lg overflow-hidden p-4 relative hover:shadow-xl transition-shadow duration-300"
            >
                <div className="px-4 py-4">
                    <h2 className="text-lg font-bold text-gray-800">{job.title}</h2>
                    <p className="text-gray-600 text-sm">{job.category}</p>
                    <div className="flex items-center mt-2">
                        <span className="px-2 py-1 text-xs font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
                            {job.category ? job.category.toUpperCase() : 'JOB'}
                        </span>
                        {job.salary && (
                            <span className="ml-2 text-sm text-gray-600"> Salaire: {job.salary}€</span>
                        )}
                    </div>
                    {job.boosted === 1 && (
                        <div className="absolute top-0 right-0 bg-red-600 text-white text-xs px-2 py-1 rounded-md">
                            Urgent
                        </div>
                    )}
                    <div className="flex items-center mt-4">
                        {job.ProfilePicture && (
                            <img
                                className="w-10 h-10 object-cover rounded-full"
                                src={job.ProfilePicture}
                                alt="Company logo"
                            />
                        )}
                        <div className="ml-4">
                            <CiLocationOn />
                            <p className="text-sm text-gray-800 font-semibold">
                                {job.city || 'Emplacement inconnu'}
                            </p>
                        </div>
                    </div>
                    <p className="text-xs text-gray-500 mt-2">{job.created_at || 'No time available'}</p>
                </div>
            </div>
        ))}
        {viewType === 'job_seekers' && filteredJobSeekers.map((seeker) => (
            <div
                onClick={() => navigateToJobSeekerDescription(seeker)}
                key={seeker.id} // Use a unique identifier for the key
                className="bg-white shadow-lg rounded-lg overflow-hidden p-4 relative hover:shadow-xl transition-shadow duration-300"
            >
                <div className="px-4 py-4">
                    <h2 className="text-lg font-bold text-gray-800">{seeker.name}</h2>
                    <p className="text-gray-600 text-sm">{seeker.expertise}</p>
                    <div className="flex items-center mt-2">
                        <span className="px-2 py-1 text-xs font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
                            {seeker.category ? seeker.category.toUpperCase() : 'Compétence'}
                        </span>
                        {seeker.expected_salary && (
                            <span className="ml-2 text-sm text-gray-600">
                                Prétentions: {seeker.expected_salary}
                            </span>
                        )}
                    </div>
                    <div className="flex items-center mt-4">
                        {seeker.ProfilePicture && (
                            <img
                                className="w-10 h-10 object-cover rounded-full"
                                src={seeker.ProfilePicture}
                                alt="Profile"
                            />
                        )}
                        <div className="ml-4">
                            <CiLocationOn />
                            <p className="text-sm text-gray-800 font-semibold">
                                {seeker.city || 'Emplacement inconnu'}
                            </p>
                        </div>
                    </div>
                    {/* <p className="text-xs text-gray-500 mt-2">{seeker.created_at || 'No time available'}</p> */}
                </div>
            </div>
        ))}
    </div>
</div>
</div>
    );
};

export default PostServices;
