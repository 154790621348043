import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaMapMarkerAlt } from "react-icons/fa";
import { CiWallet, CiCalendar } from "react-icons/ci";
import axios from 'axios';
import MessageModal from '../OfferPage/MessageModal';

const RentalAdsDetails = () => {
    const { rentalId } = useParams();
    const navigate = useNavigate();
    const [rentalData, setRentalData] = useState(null);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false); // Modal visibility state

    useEffect(() => {
        const fetchRentalDetails = async () => {
            try {
                const response = await axios.get('https://btobjob.com/get_rental_ad.php', {
                    params: { id: rentalId }
                });
                setRentalData(response.data);
            } catch (error) {
                console.error("Error fetching rental details:", error);
            }
        };

        fetchRentalDetails();
    }, [rentalId]);

    const handleContactClick = () => {
        if (rentalData && rentalData.user_id) {
            navigate(`/profile/specificUser?professional_id=${rentalData.user_id}`);
        }
    };

    const handleSendMessage = async (message) => {
        if (!rentalData?.user_id) {
            console.error("User ID not found for the ad");
            return;
        }

        try {
            const response = await axios.post('https://btobjob.com/send_message.php', {
                receiverId: rentalData.user_id,
                message: message
            });
            console.log("Message sent response:", response);
            navigate(`/profile/chat`);
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const openMessageModal = () => {
        setIsMessageModalOpen(true);
    };

    if (!rentalData) return <p>Loading...</p>;

    return (
        <div className="max-w-7xl mx-auto p-6 mt-10 bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                
                {/* Header Section */}
                <div className="p-6 flex justify-between items-center">
                    <h2 className="text-3xl font-bold text-gray-800">{rentalData.title}</h2>
                </div>

                {/* Grid layout for main content */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6">
                    
                    {/* Description and Details Section */}
                    <div className="lg:col-span-2">
                        <h3 className="text-xl font-semibold mb-4">Rental Description</h3>
                        <p className="text-gray-700 mb-6">{rentalData.description || 'No description available.'}</p>

                        {/* Rental Details */}
                        <div className="flex flex-col gap-y-4">
                            <div className="flex items-center">
                                <CiWallet size={24} color="#65A406" />
                                <span className="ml-2 text-lg font-semibold text-gray-800">€{rentalData.price}</span>
                            </div>
                            <div className="flex items-center">
                                <CiCalendar size={24} color="#65A406" />
                                <span className="ml-2 text-lg text-gray-500">Created At: {new Date(rentalData.created_at).toLocaleDateString()}</span>
                            </div>
                            <div className="flex items-center">
                                <FaMapMarkerAlt size={24} color="#65A406" />
                                <span className="ml-2 text-lg font-semibold text-gray-800">{rentalData.location || "Location not specified"}</span>
                            </div>
                        </div>
                    </div>

                    {/* Image Section */}
                    <div className="lg:col-span-1">
                        <img 
                            src={rentalData.image} 
                            alt={rentalData.title} 
                            className="w-full h-80 object-cover rounded-lg shadow-sm"
                        />
                    </div>
                </div>

                {/* Contact Button and Message Modal */}
                <div className="p-6 text-center">
                    <button 
                        onClick={handleContactClick}
                        className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition"
                    >
                        Contact
                    </button>
                    <button 
                        onClick={openMessageModal}
                        className="px-6 py-2 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition ml-4"
                    >
                        Send Message
                    </button>
                    <button 
    onClick={openMessageModal}
    className="px-6 py-2 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-700 transition ml-4 border border-red-500"
>
    Send Message
</button>
                </div>
            </div>
        </div>
    );
};

export default RentalAdsDetails;
